<style lang="scss">
.OpeningDetailsMudul {
    &.el-dialog__wrapper[size=huge] .el-dialog {
        width:68rem; min-width:42rem; max-width: 96%;
    }
}
</style>
<template>
    <el-dialog class="OpeningDetailsMudul" :title="title" :visible.sync="view" size="huge" top="5vh" :modal-append-to-body="false">
        <div v-if="!mode" class="o-pb">
            <el-radio-group v-model="Filter.transfer" size="small" @change="Reload()">
                <el-radio-button :label="0">注单列表</el-radio-button>
                <el-radio-button :label="1">走飞列表</el-radio-button>
                <el-radio-button :label="2">收飞列表</el-radio-button>
            </el-radio-group>
        </div>
        <div ref="table" class="o-pb" style="min-height:300px;">
            <table v-loading="main._loading" class="table u-bl u-br u-bb" border="0" cellspacing="1" cellpadding="0">
                <thead>
                    <tr>
                        <td>日期</td>
                        <td>期数</td>
                        <td>注单号</td>
                        <td>账号</td>
                        <td>下注时间</td>
                        <td>盘类</td>
                        <td>玩法</td>
                        <td>下注内容</td>
                        <td v-if="Filter.transfer >= 1">走飞类型</td>
                        <td>
                            <span v-if="Filter.transfer == 0">下注金额</span>
                            <span v-if="Filter.transfer == 1">走飞金额</span>
                            <span v-if="Filter.transfer == 2">收飞金额</span>
                        </td>
                        <td>赔率</td>
                        <td>占成</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in main._list" class="c-text-c">
                        <td>{{ item.lottery_date }}</td>
                        <td>{{ item.lottery_number }}</td>
                        <td>{{ item.sequence }}</td>
                        <td>{{ item.username }}</td>
                        <td>{{ item.create_at }}</td>
                        <td>{{ item.side }}</td>
                        <td>{{ item.plays }}</td>
                        <td>{{ item.code }}</td>
                        <td v-if="Filter.transfer >= 1">
                            <span v-if="item.transfer_t == 1">自动</span>
                            <span v-if="item.transfer_t == 2">手动</span>
                        </td>
                        <td>{{ item.amount }}</td>
                        <td>{{ item.odds }}</td>
                        <td>{{ item.percent }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr v-if="main._empty">
                        <td class="empty" colspan="100">暂无数据</td>
                    </tr>
                    <tr v-if="!main._empty && main._total > 1" class="subtotal">
                        <td style="text-align:left;" colspan="8">
                            <p class="c-text-r o-pr">页面小计 (共 {{ main._list.length }} 笔)</p>
                        </td>
                        <td v-if="Filter.transfer >= 1">-</td>
                        <td>{{ CalcuRow('amount') }}</td>
                        <td>-</td>
                        <td>{{ CalcuRow('percent_amount') }}</td>

                    </tr>
                    <tr v-if="!main._empty" class="total">
                        <td style="text-align:left;" colspan="8">
                            <p class="c-text-r o-pr">合计 (共 {{ summary.bets_num }} 笔)</p>
                        </td>
                        <td v-if="Filter.transfer >= 1">-</td>
                        <td>{{ Price(summary.amount,{separate: false}) }}</td>
                        <td>-</td>
                        <td>{{ Price(summary.percent_amount,{separate: false}) }}</td>

                    </tr>
                </tfoot>
            </table>
        </div>
        <Pagination v-if="main._total" v-model="Page" class="o-mtb" :total="main._total" :count="main._count" :page-size="Filter.per_page" @turning="_Get" />
    </el-dialog>
</template>

<script>
// 日期 期数 主单号 走飞时间 走飞内容 赔率 走飞金额 类型
// 日期 期数 帐号 注单号 下注时间 盘类 下注内容 赔率 下注金额 退水 占成 类型
import StoreMix from '@lottery.admin.2.0/mixin/store.modul.js'
export default {
    name: 'OpeningDetailsMudul',
    mixins: [StoreMix],
    data() {
        return {
            Params: {},
            Filter: {
                whole: 1,
                transfer: 0
            },
            summary: {},
            active: 'trader'
        }
    },
    props: {
        bind: {},
        model: {},
        mode: {}
    },
    computed: {
        store() {
            return this.model + '/otrader'
        }
    },
    watch: {
        value(val) {
            if (val) {
                this.init()
                this.view = true
            }
        }
    },
    methods: {
        init() {
            this.Clean()
            this.DataReset('Filter')
            if (this.mode === 'transfer') {
                this.Filter.transfer = 1
            }
            this.Reload()
        },
        Reload() {
            this.Page = 1
            let filter = this.Origin(this.form)
            for (let key in filter) {
                this.$set(this.Filter, key, filter[key])
            }
            this.Cm(`${this.model}/CLEAN`, {
                base: 'otrader', key: '_list'
            })
            this.$set(this, 'summary', {})
            this.Get(1)
            this.GetSum()
        },
        _Get(page) {
            this.Get(page)
            this.GetSum()
        },
        GetSum() {
            let params = this.Origin(this.Filter)
            this.Dp(`${this.model}/_GET_TRADERSUM`, {params}).then(res => {
                this.$set(this, 'summary', res.data)
            })
        }
        // CalcuRow(key){
        //     let total = 0
        //     try{
        //         for(let row of this.main._list){
        //             if(row[key]){
        //                 total = this.Big(total).add(row[key]).toNumber()
        //             }
        //         }
        //         return this.Big(total).round(2,0).toNumber()
        //     }catch(err){
        //         return NaN
        //     }
        //     return total
        // },
    }
}
</script>
