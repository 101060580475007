import SixMix from '@lottery.admin.2.0/mixin/six.js'
import BoardFast from './fast.vue'

export default {
    mixins: [SixMix],
    computed: {
        Table() {
            if (this.client) {
                let obj = {}
                for (let item of this.client.play) {
                    if (!obj[item.group_mark]) {
                        obj[item.group_mark] = []
                    }
                    obj[item.group_mark].push(item)
                }
                return obj
            }
            return {}
        },
        TableAll() {
            let arr = []
            for (let key in this.Table) {
                arr.push(key)
            }
            return arr
        },
        OperateAuth() {
            if (this.$parent.OperateAuth) {
                return this.$parent.OperateAuth
            }
            return {}
        }
    },
    props: {
        
    },
    methods: {
        BoardFast(item, group, input) {
            if (this.$parent.BoardFast) {
                this.$parent.BoardFast(item, group, input)
            }
        },
        Details(item, integration) {
            if (this.$parent.Details) {
                this.$parent.Details(item, integration)
            }
        },
        RebateSet(item, type, group, input, key) {
            if (this.$parent.RebateSet) {
                this.$parent.RebateSet(item, type, group, input, key)
            } else {
                this.$emit('RebateSet', {item, type, group, input, key})
            }
        },
        RebateSetEmit({item, type, group, input}) {
            this.RebateSet(item, type, group, input)
        },
        TransmitSubmit(capacity, group, codes, amount, hand) {
            if (this.$parent.TransmitSubmit) {
                this.$parent.TransmitSubmit(capacity, group, codes, amount, hand)
            }
        },
        TransmitListSubmit() {
            if (this.$parent.TransmitListSubmit) {
                this.$parent.TransmitListSubmit()
            }
        },
        Details(item, row) {
            if (this.$parent.Details) {
                this.$parent.Details(item, row)
            } else {
                this.$emit('Details', {item, row})
            }
        },
        BallType(str) {
            let type = 'text'
            if (str%1 === 0) {
                type = 'ball'
            }
            if (this.client.group == 'lhc') {
                if (type === 'ball') {
                    type = `${type} ${this.CodeColor(str)}`
                }
                if (type === 'text') {
                    type = `${type} ${{'红': 'red', '蓝': 'blue', '绿': 'green'}[str.substr(0, 1)]}`
                }
            }
            return type
        },
        MakeQueue(arr=[], column=1, vertical=true, fill) {
            let queue = []
            if (vertical) {
                let rows = parseInt(arr.length / column)
                if (arr.length%column > 0) {
                    rows++
                }
                for (let i=0;i<arr.length;i++) {
                    let col = parseInt(i / rows)
                    if (!queue[col]) {
                        queue[col] = []
                    }
                    queue[col].push(arr[i])
                }
            } else {
                for (let i=0;i<column;i++) {
                    queue.push([])
                }
                let unit = 0
                for (let item of arr) {
                    if (unit == column) {
                        unit = 0
                    }
                    queue[unit].push(item)
                    unit++
                }
            }
            if (vertical && fill && queue.length > 1) {
                let lindex = queue.length - 1
                let max = queue[0].length
                while (queue[lindex].length < max) {
                    queue[lindex].push({})
                }
            }
            return queue
        },

        AloneTransfer(e) {
            // 本项目走飞不支持多选
            if (e.pick && this.OperateAuth.bind === 3) {
                let id = e.id
                for (let item of this.client.play) {
                    if (item.pick && item.id != id) {
                        this.$set(item, 'pick', false)
                    }
                }
                this.$set(this, 'limit', this.NumberMap(e.name))
                this.$set(this, 'has_double_odds', !!e.has_double_odds)
            } else {
                this.$set(this, 'limit', undefined)
                this.$set(this, 'has_double_odds', false)
            }
        },
        NumberMap(str) {
            if (str && typeof str === 'string') {
                // if(name === '三中二'){
                //     return 2
                // }
                let name = str.substring(0, 1)
                let name2 = str.substring(0, 2)
                let dir = {
                    '一': 1,
                    '二': 2,
                    '三': 3,
                    '四': 4,
                    '五': 5,
                    '六': 6,
                    '七': 7,
                    '八': 8,
                    '九': 9,
                    '十': 10,
                    '十一': 11,
                    '十二': 12
                }
                if (parseInt(str)) {
                    return parseInt(str)
                } else if (dir[name2]) {
                    return dir[name2]
                } else if (dir[name]) {
                    return dir[name]
                }
            }
            return null
        }
    },
    components: {
        BoardFast
        //Group,
    },
    mounted() {

    }
}