const packages = {
    ssc: {
        ssc_5e33c112b7d93: require('./components/ssc/1.vue').default, //  整合
        ssc_5e33c11338d1e: require('./components/ssc/2.vue').default, //  龙虎
        ssc_5e33c11331eeb: require('./components/ssc/3.vue').default, //  任选四
        ssc_5e33c1132b18a: require('./components/ssc/4.vue').default, //  前四
        ssc_5e33c11324227: require('./components/ssc/5.vue').default, //  任选三
        ssc_5e33c1131d1e3: require('./components/ssc/6.vue').default, //  任选二
        ssc_5e33c11316338: require('./components/ssc/7.vue').default, //  趣味
        ssc_5e33c1130f490: require('./components/ssc/8.vue').default, //  大小单双
        ssc_5e33c11308688: require('./components/ssc/9.vue').default, //  不定胆
        ssc_5e33c1130195d: require('./components/ssc/10.vue').default, //  定位胆
        ssc_5e33c112eee89: require('./components/ssc/11.vue').default, //  二码
        ssc_5e33c112e80d2: require('./components/ssc/12.vue').default, //  中三码
        ssc_5e33c112e142e: require('./components/ssc/13.vue').default, //  前三码
        ssc_5e33c112da5a6: require('./components/ssc/14.vue').default, //  后三码
        ssc_5e33c112d37a6: require('./components/ssc/15.vue').default, //  后四
        ssc_5e33c112cca02: require('./components/ssc/16.vue').default, //  五星
        ssc_5e33c112c5c6e: require('./components/ssc/17.vue').default, //  全5中1
        ssc_5e33c1133faad: require('./components/ssc/18.vue').default //  龙虎斗
    },
    dpc: {
        dpc_f8aae46401d35cda: require('./components/dpc/1.vue').default, // 三码
        dpc_aecba027b03fc2f2: require('./components/dpc/2.vue').default, // 不定胆
        dpc_b97e03101f963fc3: require('./components/dpc/3.vue').default, // 二码
        dpc_0fc845236595fa98: require('./components/dpc/4.vue').default, // 定位胆
        dpc_485ead37b0ca2569: require('./components/dpc/5.vue').default, // 大小单双

        dpc_e7f8bece10647e10: require('./components/dpc/6.vue').default, // 主盘势
        dpc_519538d11a7d0a84: require('./components/dpc/7.vue').default, // 一字盘势
        dpc_476b38e90e1a4d8e: require('./components/dpc/8.vue').default, // 二字定位
        dpc_209cd4250923c335: require('./components/dpc/9.vue').default, // 二字组合
        dpc_670cb9dc555cbbe0: require('./components/dpc/10.vue').default, // 二字和数
        dpc_4c3168d11848053d: require('./components/dpc/11.vue').default, // 三字定位
        dpc_c1d0f1ab665649e5: require('./components/dpc/12.vue').default, // 三字组合
        dpc_1338a86cc45d05f7: require('./components/dpc/13.vue').default, // 三字和数
        dpc_5369f9b0d214a970: require('./components/dpc/14.vue').default, // 组选三
        dpc_252ad7b0a767d83d: require('./components/dpc/15.vue').default, // 组选六
        dpc_419157fc5a2c15cf: require('./components/dpc/16.vue').default, // 复式组合
        dpc_b1d7f9488f1ad87a: require('./components/dpc/17.vue').default, // 一字过关
        dpc_0d7b249a8fe6c50e: require('./components/dpc/18.vue').default, // 跨度
        dpc_c0a478d93d5323b8: require('./components/dpc/19.vue').default // 其他
    },
    ffc: {
        // ssc_5e33c112b7d93    : require('./components/ssc/1.vue').default, //  整合
        // ssc_5e33c11338d1e    : require('./components/ssc/2.vue').default, //  龙虎
        // ssc_5e33c11331eeb    : require('./components/ssc/3.vue').default, //  任选四
        // ssc_5e33c1132b18a    : require('./components/ssc/4.vue').default, //  前四
        // ssc_5e33c11324227    : require('./components/ssc/5.vue').default, //  任选三
        // ssc_5e33c1131d1e3    : require('./components/ssc/6.vue').default, //  任选二
        // ssc_5e33c11316338    : require('./components/ssc/7.vue').default, //  趣味
        // ssc_5e33c1130f490    : require('./components/ssc/8.vue').default, //  大小单双
        // ssc_5e33c11308688    : require('./components/ssc/9.vue').default, //  不定胆
        // ssc_5e33c1130195d    : require('./components/ssc/10.vue').default, //  定位胆
        // ssc_5e33c112eee89    : require('./components/ssc/11.vue').default, //  二码
        // ssc_5e33c112e80d2    : require('./components/ssc/12.vue').default, //  中三码
        // ssc_5e33c112e142e    : require('./components/ssc/13.vue').default, //  前三码
        // ssc_5e33c112da5a6    : require('./components/ssc/14.vue').default, //  后三码
        // ssc_5e33c112d37a6    : require('./components/ssc/15.vue').default, //  后四
        // ssc_5e33c112cca02    : require('./components/ssc/16.vue').default, //  五星
        // ssc_5e33c112c5c6e    : require('./components/ssc/17.vue').default, //  全5中1
        // ssc_5e33c1133faad    : require('./components/ssc/18.vue').default, //  龙虎斗
    },
    syw: {
        syw_97eaee0fffeac9a1: require('./components/syw/1.vue').default, // 三码
        syw_3c91854ba7c8f972: require('./components/syw/2.vue').default, // 全5中1
        syw_33065c6149dc208c: require('./components/syw/3.vue').default, // 龙虎斗
        syw_eeb1aafa7ba59c20: require('./components/syw/4.vue').default, // 正码三
        syw_64c3ea42ee54ed34: require('./components/syw/5.vue').default, // 正码二
        syw_0bdba97c257aa817: require('./components/syw/6.vue').default, // 正码一
        syw_7735896dd758bd12: require('./components/syw/7.vue').default, // 特码
        syw_90ea196e7e11de9f: require('./components/syw/8.vue').default, // 两面盘
        syw_471be6ed974d4f50: require('./components/syw/9.vue').default, // 任选单式
        syw_67591104cd4cd067: require('./components/syw/10.vue').default, // 趣味型
        syw_679cc6151cb929a2: require('./components/syw/11.vue').default, // 定位胆
        syw_9469d291763f5b8c: require('./components/syw/12.vue').default, // 不定胆
        syw_54a264fc6c7a5a45: require('./components/syw/13.vue').default, // 二码
        syw_eeb1aafa7ba59c22: require('./components/syw/14.vue').default // 正码四
    },
    klc: {
        klc_96f439d6dda3f7be: require('./components/klc/1.vue').default, //同号
        klc_3efa0c1d7d6aa896: require('./components/klc/2.vue').default, //和值
        klc_324f4fb0f662d72f: require('./components/klc/3.vue').default, //三连号
        klc_e48483a110b2f16b: require('./components/klc/4.vue').default, //不同号
        klc_23a3830a387e1702: require('./components/klc/5.vue').default //大小骰宝
    },
    pks: {
        pks_be2fa538fdeb6d45: require('./components/pks/1.vue').default, // 猜冠军
        pks_204b4f63e6286b00: require('./components/pks/2.vue').default, // 冠亚和值
        pks_7fe961ee5c124dcb: require('./components/pks/3.vue').default, // 第1-10名
        pks_2d46c3b7bdfa6776: require('./components/pks/4.vue').default, // 整合
        pks_6c830dd5ca1772be: require('./components/pks/5.vue').default, // 龙虎
        pks_ce26bc324d280c00: require('./components/pks/6.vue').default, // 定胆位
        pks_c747e99ca74bd0d0: require('./components/pks/7.vue').default, // 猜前五
        pks_954359a0c3e49f1e: require('./components/pks/8.vue').default, // 猜前四
        pks_b9d1ea6889c1e08e: require('./components/pks/9.vue').default, // 猜前三
        pks_c0f18a11661e81fb: require('./components/pks/10.vue').default, // 猜前二
        pks_2da61045b646a650: require('./components/pks/11.vue').default // 冠亚组合
    },
    lhc: {
        lhc_5abdf8b8520b71f3: require('./components/lhc/1.vue').default, // 特码
        lhc_38a77aa456fc813a: require('./components/lhc/2.vue').default, // 正码1-6
        lhc_0987b8b338d6c90b: require('./components/lhc/3.vue').default, // 连码
        lhc_31ca0ca71184bbdb: require('./components/lhc/4.vue').default, // 自选不中
        lhc_91d0dbfd38d950cb: require('./components/lhc/6.vue').default, // 一肖尾数
        lhc_be1bc7997695495f: require('./components/lhc/5.vue').default, // 连肖
        lhc_4fa91c19016cb1f8: require('./components/lhc/7.vue').default, // 正码特
        lhc_4a533591763dfa74: require('./components/lhc/8.vue').default, // 连尾
        lhc_e1fc9c082df6cfff: require('./components/lhc/9.vue').default, // 合肖
        lhc_e3ea33961a7c5b1e: require('./components/lhc/10.vue').default, // 正码
        lhc_integration: require('./components/lhc/integration.vue').default
    },
    default: require('./components/default.vue').default
}
export default {
    components: {
    	...packages.ssc, // 时时彩
	    ...packages.dpc, //
	    ...packages.ffc, //
	    ...packages.syw, // 十一选五
	    ...packages.klc, //
	    ...packages.pks, //
	    ...packages.lhc, // 六合彩
	    default: packages.default
    },
    methods: {
    	UsePackage(name='') {
    		let type = name.split('_')[0]
    		if (packages[type] && packages[type][name]) {
    			return name
    		}
    		return 'default'
    	}
    }
}