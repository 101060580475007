<style lang="scss">
    @import "../style/config.scss";
    .Password {
        width:100%; max-width:300px; margin:0 auto; padding-top:16vh;
        .form {
            padding:36px 16px;
            background-color:#fff; background-color:rgba(255,255,255,.9);
            border:3px solid #c0c0c0;
            border-radius:10px; box-shadow:0 5px 20px rgba(0,0,0,.15);
            .label {
                display:inline-block; width:64px; padding-right:10px; text-align:right; font-size:.6rem;
            }
            input.input {
                border:1px solid #ccc; display:inline-block; width:160px;
                height:36px; line-height:1em; padding:0 8px;
            }
        }
    }
</style>
<template>
    <div class="Password o-plr">
        <div class="form">
            <h6 class="c-text-c">首次登陆请先修改密码</h6>
            <div class="o-pt">
                <div class="o-mt-s">
                    <span class="label">旧密码</span>
                    <input v-model="Params.old_pass" class="input" type="password" autocomplete="off">
                </div>
                <div class="o-mt-s">
                    <span class="label">新密码</span>
                    <input v-model="Params.pass" class="input" type="password" autocomplete="off">
                </div>
                <div class="o-mt-s">
                    <span class="label">重复密码</span>
                    <input v-model="Params.confirm_pass" class="input" type="password" autocomplete="off">
                </div>
                <div class="o-mt">
                    <span class="label" />
                    <Button size="big" type="default" @click="Submit()">提交</Button>
                    <a class="o-ml-l" @click="Logout()">重新登录</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'Password',
    mixins: [StoreMix],
    data() {
        return {
            store: 'myAccount/safety',
            Params: {
                old_pass: '',
                pass: '',
                confirm_pass: ''
            }
        }
    },
    computed: {

    },
    methods: {
        init() {
            this.DataReset()
        },
        Submit() {
            this.Params.action = 'pass'
            this.Post().then(res => {
                this.Login()
            })
        }
    },
    mounted() {
        this.init()
    }
}
</script>