
<template>
    <div class="OperateNavTable">
        <div class="row-title c-text-c">一字组合</div>
        <group :client="client" :list="Table[1]" :length="5" />
        <BoardFast :client="client" :group="1" :step="true" :default-step="0.1" />
        <div v-for="(pack,unit) in [['佰定位','拾定位','个定位','',''],['佰拾和数','佰个和数','拾个和数','佰拾个和数','佰拾个和数尾数']]" class="l-flex o-mt">
            <template v-for="(item,index) in pack">
                <div v-if="index" style="padding:0 .2rem;" />
                <div class="l-flex-1">
                    <div v-if="item">
                        <div class="row-title c-text-c">{{ item }}</div>
                        <group :client="client" :list="Table[[2,8][unit] + index * 2]" :length="1" />
                        <group :client="client" :list="Table[[2,8][unit] + index * 2 + 1]" :length="1" />
                    </div>
                </div>
            </template>
        </div>
        <BoardFast :client="client" :group="[2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]" :step="true" :default-step="0.1" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateDpc6',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group
    }
}
</script>
