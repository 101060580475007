import StoreMix from './store.js'
export default {
    data() {
        return {
            view: false
        }
    },
    mixins: [StoreMix],
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        form: {
            type: Object,
            default: null
        }
    },
    watch: {
        value(val) {
            if (val) {
                this.$set(this, 'view', true)
                if (this.form) {
                    this.$set(this.$data, 'Params', this.form)
                } else {
                    this.DataReset('Params')
                }
                this.init()
            } else {
                this.$set(this, 'view', false)
            }
        },
        view(val) {
            if (!val) {
                this.$emit('input', false)
            }
        }
    },
    computed: {
        Title() {
            if (this.title) {
                return this.title
            }
            if (this.$parent && this.$parent.Editer && this.$parent.Editer.title) {
                return this.$parent.Editer.title
            }
        }
    },
    methods: {
        Cancel() {
            this.$set(this, 'view', false)
        }
    },
    created() {
        this.view = this.value
    }
}