<style lang="scss">
    .OperateLhc-10 {

    }
</style>
<template>
    <div class="OperateLhc-10">
        <group :client="client" :list="Table[0]" :length="5" show-total="end" />
        <BoardFast :client="client" :group="[0]" :step="true" :default-step="0.01" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateLhc10',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group
    }
}
</script>
