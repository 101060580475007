
<template>
    <div class="OperateNavTable">
        <div v-for="(pack,unit) in [['一帆风顺','好事成双','三星报喜','四季发财','']]" class="l-flex o-mb">
            <template v-for="(item,index) in pack">
                <div v-if="index" style="padding:0 .2rem;" />
                <div class="l-flex-1">
                    <div v-if="item" class="row-title c-text-c">{{ item }}</div>
                    <group v-if="item" :client="client" :list="Table[index + 1]" :length="1" :win="false" />
                </div>
            </template>
        </div>
        <BoardFast :client="client" :group="TableAll" :step="true" :default-step="0.1" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateSsc7',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group
    }
}
</script>
