import Vue from 'vue'
import {deepCopy, isObject, isArray} from '../../helper.js'

// 带有焦点属性
// 传参 ：query or [query,dataBase]
// query :
//  undefined or null 重置焦点
//  {key:val} 检索第一个匹配的字段
//  -1 0 x 查找指定索引（-1代表最后一个索引）
// 焦点变化 mutations 方法

export default function(options, ajax) {

    let store = options.store.toUpperCase()

    //let MODEL = model.toUpperCase()

    // 数据添加
    options.mutations[`_${store}_ADD`] = ( state, {base, key, position, value, size} ) => {
        try {
            // if(position === undefined && key === '_list' && value && value.id){
            //     let list = state[base][key]
            //     if(list.length == 0){
            //         position = -1
            //     }
            // }
            if (!size) {
                size = 24
            }
            switch (position) {
                case 0:
                    state[base][key].unshift(value)
                    break;
                case -1:
                    for (let step = 0;step < size;step++) {
                        let length = state[base][key].length
                        let index = length - 1 - step
                        //console.log(index,state[base][key][index] ? state[base][key][index].id : null,value.id,state[base][key][index] ? state[base][key][index].id == value.id : false)
                        if (state[base][key][index] && state[base][key][index].id == value.id) {
                            //console.log('splice',index)
                            return state[base][key].splice(index, 1, value)
                            break;
                        }
                    }
                    state[base][key].push(value)
                    break;
                default:
                    state[base][key].splice(position, 0, value)
                    break;
            }
        } catch (error) {
            console.info(`dgx error`, error)
        }
    }


    //
    // 数据更新
    //
    options.mutations[`_${store}_UPDATE`] = (state, {base, key, value, id, sn, extend} ) => {
        try {
            if (id || sn) {
                let list = eval(`state.${base}.${key}`)
                for (let i=0; i<list.length; i++) {
                    if (list[i].id == id || list[i].sn == id) {
                        if (extend) {
                            Object.assign(state[base][key][i], value)
                        } else {
                            eval(`state.${base}.${key}.splice(i,1,value)`)
                        }
                        break;
                    }
                }
            } else if (extend) {
                Object.assign(state[base][key][i], value)
            } else {
                eval(`state.${base}.${key} = value`)
            }
        } catch (error) {
            console.info(`dgx error`, error)
        }
    }

    //
    // 数据库
    //
    options.mutations[`_${store}_DB`] = (state, {base, value, refresh} ) => {
        try {
            if (value) {
                if (refresh) {
                    Vue.set(state[base], '_db', {
                        list: [],
                        pages: {},
                        length: 0
                    })
                }
                let page = state[base]._page
                if (!state[base]._db.pages[page]) {
                    state[base]._db.pages[page] = []
                }
                for (let item of value) {
                    let index = item.id || item.sn
                    if (!state[base]._db[index]) {
                        state[base]._db.pages[page].push(index)
                        state[base]._db.list.push(item)
                        Vue.set(state[base]._db, 'length', state[base]._db.length + 1)
                    }
                    Vue.set(state[base]._db, index, item)
                }
            }
        } catch (error) {
            console.info(`dgx error`, error)
        }
    }

    //
    //  数据移除
    //
    options.mutations[`_${store}_REMOVE`] = ( state, {base, key, id, index} ) => {
        if (id) {
            let list = eval(`state.${base}`)[key]
            for (let i=0; i<list.length; i++) {
                if (list[i].id == id) {
                    eval(`state.${base}`)[key].splice( i, 1)
                    break;
                }
            }
        } else if (index || index == 0) {
            eval(`state.${base}`)[key].splice(index, 1)
        } else {
            delete eval(`state.${base}`)[key]
        }

    }


    //
    //  数据清空
    //
    options.mutations[`_${store}_CLEAN`] = (state, {base, key}) => {
        Vue.set(state[base], key, new Array())
    }
    options.mutations[`CLEAN`] = (state, {base, key}) => {
        Vue.set(state[base], key, new Array())
    }

    //
    //  重置模块
    //
    options.mutations[`_${store}_RESET`] = (state, model="") => {
        if (model && state[model] && state[model]._reset) {
            let reset = deepCopy(state[model]._reset)
            state[model] = deepCopy(reset)
            state[model]._reset = deepCopy(reset)
        }
    }
}
