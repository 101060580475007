
<template>
    <div class="OperateNavTable">
        <div v-for="(item,index) in ['佰拾和数OOX','佰个和数OXO','拾个和数XOO']" v-if="item">
            <div class="row-title c-text-c">{{ item }}</div>
            <group :client="client" :list="Table[index * 2 + 1]" :length="5" />
            <group :client="client" :list="Table[index * 2 + 2]" :length="5" />
            <BoardFast :client="client" :group="[index * 2 + 1,index * 2 + 2]" :step="true" :default-step="0.1" />
        </div>
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateDpc10',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group
    }
}
</script>
