
import CommonMix from './common.js'
export default {
    name: 'OperateGroup',
    mixins: [CommonMix],
    data() {
        return {

        }
    },
    props: {
        client: {},
        list: {},
        length: {},
        interval: {
            default() {
                return ['3rem', 3, 3, 4, 3]
            }
        },
        radio: {
            default: false
        },
        win: {
            default: true
        },
        transmit: {
            default: true // 部分玩法不支持单行走飞走飞
        },
        manipulation: {
            default: true
        },
        size: {
            default: 'mini'
        },
        showTotal: {
            default: true
        },
        doubleLine: {
            default: false // 有双重赔率要占用两行
        },
        fill: {
            default: false // 是否撑满
        },
        layout: {
            default: 'flex' //  布局模式
        },
        tableIndex: {
            default: 0
        },
        total: {},
        integration: {},
        repair: {
            default: 0 // 赔率修正
        },
        disabled: Boolean
    },
    computed: {
        Interval() {
            let arr = []
            for (let i=0;i<=5;i++) {
                if (this.interval && this.interval[i]) {
                    arr.push(typeof this.interval[i] === 'number' ? `flex:${this.interval[i]};` : `width:${this.interval[i]};`)
                } else {
                    arr.push('flex:3;')
                }
            }
            return arr
        },
        TotalAmount() {
            if (this.total) {
                return this.total
            }
            try {
                let amount = 0
                let count = 0
                for (let item of this.list) {
                    let id = item.id
                    amount = this.Big(amount).add(item.amount).add(item.in_transfer).sub(item.out_transfer).toNumber()
                    if (item.number) {
                        count += item.number
                    }
                }
                let total = {
                    amount: this.Big(amount).round(2, 1).toNumber(),
                    count
                }
                this.$emit('total', {
                    index: this.tableIndex,
                    ...total
                })
                return total
            } catch (err) {
                return NaN
            }
        },
        capacity() {
            if (this.list && this.list.length) {
                let group = this.list[0].group_mark
                if (this.client.capacitys[group]) {
                    return this.client.capacitys[group]
                }
            }
            return null
        }
    },
    methods: {
        AmountTotal(item) {
            try {
                return this.Big(item.amount).add(item.in_transfer).sub(item.out_transfer).round(2, 1).toNumber()
            } catch (err) {
                return NaN
            }
        },
        WinTotal(item) {
            try {
                return this.Big(item.win).round(2, 0).toNumber()
            } catch (err) {
                return NaN
            }
        },
        TransmitCalcu(item) {
            // 顶级代理不参与补货计算
            if (this.Opt.isAdmin) {
                return 0
            }
            try {
                if (item.win < 0) {
                    let win = Math.abs(item.win)
                    let capacity = Math.abs(this.capacity)
                    let amount = this.Big(item.amount).add(item.in_transfer).sub(item.out_transfer).toNumber()
                    let transfer =  Math.ceil(this.Big(win).sub(capacity).div(item.odds).toNumber())
                    if (transfer > 0) {
                        return transfer > amount ? amount : transfer
                    }
                }
                return 0
            } catch (err) {
                return NaN
            }
        },
        // Checkbox(e,id){
        //     if(e && this.radio){
        //         for(let item of this.list){
        //             if(item.id !== id){
        //                 this.$set(item,'pick',false)
        //             }
        //         }
        //     }
        // },
        // Checkbox2(e,pack){
        //     if(e && this.radio){
        //         for(let item of this.list){
        //             if(item.id !== pack.id){
        //                 this.$set(item,'pick',false)
        //             }
        //         }
        //     }
        // },
        RebateSetGroup(item, type, key = "odds") {
            let input = Number(this.client.board.stepdir[item.group_mark])
            this.RebateSet(item, type, null, input, key)
        },
        Pick(item) {
            if (this.disabled) return false
            this.$set(item, 'pick', !item.pick)
            this.$emit('pick', item)
        },
        ShowDetails(item, integration) {
            if (this.disabled) return false
            this.Details(item, integration)
        }
    },
    components: {

    }
}