<style lang="scss">
    @import "./group.scss";
    .OperateGroup {

    }
</style>
<template>
    <table class="OperateGroup" :class="size + ' ' + layout">
        <tr class="layout-tr">
            <td v-for="(pack,unit) in MakeQueue(list,length,'vertical',fill)" class="layout-td">
                <table class="table">
                    <thead>
                        <tr>
                            <td>号码</td>
                            <td>赔率</td>
                            <td>金额</td>
                            <td v-if="win">输赢</td>
                            <td v-if="OperateAuth.bind == 3 && OperateAuth.transmit && transmit && win">补货</td>
                            <td v-if="OperateAuth.bind == 2 && OperateAuth.manipulation && manipulation">操盘</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in pack" class="row" :class="{'pick': item.pick,'double': doubleLine}" @click="Pick(item)">
                            <template v-if="item.name">
                                <td>
                                    <a :class="BallType(item.name)">{{ item.name }}</a>
                                </td>
                                <td>
                                    <p>
                                        <el-popover placement="top" width="120" trigger="hover" :disabled="!item.odds_up">
                                            <div class="l-line-14">
                                                <p class="c-text-65">上级赔率 -  <b class="c-color-t c-text-7">{{ item.odds_up }}</b></p>
                                            </div>
                                            <b slot="reference" class="c-color-e">{{ Big(item.odds).sub(repair) }}</b>
                                        </el-popover>
                                    </p>
                                    <p v-if="item.has_double_odds" style="padding-top:2px;">
                                        <el-popover placement="top" width="120" trigger="hover" :disabled="!item.max_odds_up">
                                            <div class="l-line-14">
                                                <p class="c-text-65">上级赔率 -  <b class="c-color-t c-text-7">{{ item.max_odds_up }}</b></p>
                                            </div>
                                            <b slot="reference" class="c-color-e">{{ parseFloat(item.max_odds) }}</b>
                                        </el-popover>
                                    </p>
                                </td>
                                <td>
                                    <span class="o-plr-s">
                                        <el-popover placement="top" width="175" trigger="hover" :disabled="!AmountTotal(item)">
                                            <div class="l-line-14">
                                                <p class="c-text-65">下注金额 -  <b class="c-color-t c-text-7"><span>{{ Big(item.amount).round(2,0).toNumber() }}</span></b></p>
                                                <p class="c-text-65">收飞金额 -  <b class="c-color-t c-text-7"><span>{{ Big(item.in_transfer).round(2,0).toNumber() }}</span></b></p>
                                                <p class="c-text-65">走飞金额 -  <b class="c-color-t c-text-7"><span>{{ Big(item.out_transfer).round(2,0).toNumber() }}</span></b></p>
                                            </div>
                                            <a slot="reference" @click.stop="ShowDetails(item,integration)"><b style="text-decoration:underline;">{{ AmountTotal(item) }}</b></a>
                                        </el-popover>
                                    </span>
                                </td>
                                <td v-if="win">
                                    <span class="o-plr-s" :style="Interval[3]" :class="{'c-color-s': WinTotal(item) > 0,'win-color': WinTotal(item) < 0}"><b>{{ WinTotal(item) }}</b></span>
                                </td>
                                <td v-if="OperateAuth.bind == 3 && OperateAuth.transmit && transmit && win">
                                    <span class="o-plr-s" :style="Interval[4]">{{ TransmitCalcu(item) }}</span>
                                </td>
                                <td v-if="OperateAuth.bind == 2 && OperateAuth.manipulation && manipulation">
                                    <div class="c-text-c" style="width:2.4rem; margin:0 auto;">
                                        <p class="manipulation">
                                            <Icon class="remove" size=".85" name="wuuiconsuoxiao" @click.native.stop="RebateSetGroup(item,'-')" />
                                            <Icon class="plus" size=".85" name="wuuiconxiangjifangda" @click.native.stop="RebateSetGroup(item,'+')" />
                                            <!-- <i class="el-icon-remove remove" @click.stop="RebateSetGroup(item,'-')"></i><i class="el-icon-circle-plus plus" @click.stop="RebateSetGroup(item,'+')"></i> -->
                                        </p>
                                        <p v-if="item.has_double_odds" class="manipulation" style="padding-top:2px;">
                                            <Icon class="remove" size=".85" name="wuuiconsuoxiao" @click.native.stop="RebateSetGroup(item,'-','max_odds')" />
                                            <Icon class="plus" size=".85" name="wuuiconxiangjifangda" @click.native.stop="RebateSetGroup(item,'+','max_odds')" />
                                            <!--                                             <i class="el-icon-remove remove" @click.stop="RebateSetGroup(item,'-','max_odds')"></i><i class="el-icon-circle-plus plus" @click.stop="RebateSetGroup(item,'+','max_odds')"></i> -->
                                        </p>
                                    </div>
                                </td>
                            </template>
                            <td v-else colspan="10" />
                        </tr>
                        <tr v-if="showTotal === 'end' && unit === length - 1" class="c-text-r">
                            <td class="c-text-r o-pr" colspan="99"><b>笔数：{{ TotalAmount.count }}，合计: {{ TotalAmount.amount }}</b></td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr class="layout-tr">
            <td v-if="showTotal === true" class="layout-td total" colspan="9">
                <p class="c-text-r"><b class="o-pr">笔数：{{ TotalAmount.count }}，合计: {{ TotalAmount.amount }}</b></p>
            </td>
        </tr>
    </table>
</template>

<script>
import Common from './group.js'
export default {
    name: 'OperateGroup',
    mixins: [Common]
}
</script>