<style lang="scss">
    .OperateLhc-4 {
        h6.title {
            padding:3px 5px; text-align:center; background-color:#f3655e; color:#fff;
        }
    }
</style>
<template>
    <div class="OperateLhc-4">
        <div v-show="OperateAuth.bind != 3" class="o-pb">
            <el-radio-group v-model="active" size="mini">
                <el-radio-button v-for="(item,index) in tabs" :key="item" :label="item">{{ index }}</el-radio-button>
            </el-radio-group>
        </div>
        <div v-for="(item,index) in tabs" v-if="active === item" v-show="OperateAuth.bind != 3">
            <group :client="client" :list="Table[item]" :length="5" :win="false" show-total="end" :total="totals[item]" />
            <BoardFast v-if="OperateAuth.bind === 2" :client="client" :group="[item]" :step="true" :default-step="0.01" />
        </div>
        <div v-show="OperateAuth.bind === 3" class="l-flex o-mt">
            <div class="l-flex-1" style="padding-right:.1rem;">
                <group
                    :client="client" :list="Table[5]" :length="1" :radio="true" :transmit="false"
                    :manipulation="false" :win="false" :table-index="51" :repair="Repair"
                    @total="test($event)" @pick="AloneTransfer"
                />
                <group
                    class="o-mt" :client="client" :list="Table[9]" :length="1" :radio="true"
                    :transmit="false" :manipulation="false" :win="false" :table-index="95"
                    @total="test($event)" @pick="AloneTransfer"
                />
            </div>
            <div class="l-flex-1" style="padding-left:.1rem; padding-right:.1rem;">
                <group
                    :client="client" :list="Table[6]" :length="1" :radio="true" :transmit="false"
                    :manipulation="false" :win="false" :table-index="62" @total="test($event)"
                    @pick="AloneTransfer"
                />
                <group
                    class="o-mt" :client="client" :list="Table[10]" :length="1" :radio="true"
                    :transmit="false" :manipulation="false" :win="false" :table-index="106"
                    @total="test($event)" @pick="AloneTransfer"
                />
            </div>
            <div class="l-flex-1" style="padding-left:.1rem; padding-right:.1rem;">
                <group
                    :client="client" :list="Table[7]" :length="1" :radio="true" :transmit="false"
                    :manipulation="false" :win="false" :table-index="73" @total="test($event)"
                    @pick="AloneTransfer"
                />
                <group
                    class="o-mt" :client="client" :list="Table[11]" :length="1" :radio="true"
                    :transmit="false" :manipulation="false" :win="false" :table-index="117"
                    @total="test($event)" @pick="AloneTransfer"
                />
            </div>
            <div class="l-flex-1" style="padding-left:.1rem;">
                <group
                    :client="client" :list="Table[8]" :length="1" :radio="true" :transmit="false"
                    :manipulation="false" :win="false" :table-index="84" @total="test($event)"
                    @pick="AloneTransfer"
                />
                <group
                    class="o-mt" :client="client" :list="Table[12]" :length="1" :radio="true"
                    :transmit="false" :manipulation="false" :win="false" :table-index="128"
                    @total="test($event)" @pick="AloneTransfer"
                />
            </div>
            <div class="l-flex-1" />
        </div>
        <BoardFast v-if="OperateAuth.bind === 3" :client="client" :group="[5,6,7,8,9,10,11,12]" transmit="six-balls" :step="true" :limit="limit" @change="Change" />
        <div v-if="OperateAuth.bind === 3" class="o-mt o-pl c-color-e c-text-7"><b>{{ prompt }}</b></div>
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateLhc4',
    mixins: [CommonMix],
    data() {
        return {
            active: 51,
            tabs: {'五不中': 51, '六不中': 62, '七不中': 73, '八不中': 84, '九不中': 95, '十不中': 106, '十一不中': 117, '十二不中': 128},
            totals: {
                '51': {amount: 0, count: 0},
                '62': {amount: 0, count: 0},
                '73': {amount: 0, count: 0},
                '84': {amount: 0, count: 0},
                '95': {amount: 0, count: 0},
                '106': {amount: 0, count: 0},
                '117': {amount: 0, count: 0},
                '128': {amount: 0, count: 0}
            },
            limit: undefined,
            picks: null,
            prompt: ''
        }
    },
    props: {
        client: {}
    },
    computed: {
        ImproperDir() {
            let improper = this.$store.state.game.improper
            if (improper._init) {
                let dir = {}
                for (let item of improper._list) {
                    let codes = item.str_data.split(',').sort()
                    if (item.status && codes.length === 5 && item.odds > 0) {
                        dir[codes.join(',')] = {
                            ...item
                        }
                    }
                }
                return dir
            }
            return null
        },
        Repair() {
            let name = this.picks
            this.prompt = ''
            if (this.ImproperDir && name) {
                if (this.ImproperDir[name] && this.ImproperDir[name].odds > 0) {
                    this.prompt = '注意：当前选择为热门号码，请注意部分项目赔率已变动!'
                    return this.Big(this.ImproperDir[name].odds).toNumber()
                }
            }
            return 0
        }
    },
    methods: {
        test(e) {
            if (e) {
                this.$set(this.totals, e.index, e)
            }
        },
        Change(e) {
            if (e) {
                let origin = this.Origin(e)
                let list = []
                for (let num of origin) {
                    list.push(parseInt(num))
                }
                if (list && list.length) {
                    this.picks = list.sort().join(',')
                }
            }
        }
    },
    components: {
        Group
    },
    mounted() {
        this.Dp('game/_GET_IMPROPER')
    }
}
</script>
