import dgx from '~/dgx_z/index.js';
import ajax from '@lottery.2.0/library/ajax.js';

const store = 'myReport';
const STORE = store.toUpperCase();

export default dgx({
    store,
    state: {
        amount: {
            title: '总账',
            _options: {
                url: 'team/report/summary'
            }
        },
        amountsum: {
            title: '总账统计',
            _options: {
                url: 'team/report/reportsum'
            }
        },
        useramount: {
            title: '用户总账',
            _options: {
                url: 'user/report/summary'
            }
        },
        	subsidiary: {
        		title: '分类账',
        		_options: {
        			url: 'team/report/ledger'
        		}
        	},
        usersubsidiary: {
            title: '用户分类账',
            _options: {
                url: 'user/report/ledger'
            }
        },
        order: {
            title: '报表注单',
            _options: {
                url: 'team/report/order'
            }
        },
        ordersum: {
            title: '报表注单统计',
            _options: {
                url: 'user/report/ordersum'
            }
        },
        transfer: {
            title: '收飞注单',
            _options: {
                url: 'team/report/transfer'
            }
        },
        userorder: {
            title: '用户报表注单',
            _options: {
                url: 'user/report/order'
            }
        }
        // usertransfer : {
        //     title : '用户收飞订单',
        //     _options : {
        //         url : 'team/report/transfer',
        //     },
        // },
    },
    actions: {

    },
    mutations: {

    }
},
ajax
);
