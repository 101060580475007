import Vue from 'vue'

import dgx from '~/dgx_z/index.js'
import ajax from '@lottery.2.0/library/ajax.js'

const store = 'socket';
const STORE = store.toUpperCase();

import Methods from '~/methods'
import Config from '@lottery.2.0/library/config.js'

let client = null

import Events from 'events' //.EventEmitter;
const EventEmitter = Events.EventEmitter

export default dgx({
    store,
    state: {
        ready: false,
        Event: new EventEmitter()
    },
    actions: {
        CONNECT({state, dispatch, commit}, data={}) {
            if (state.client) {
                return Promise.resolve(true)
            }
            return new Promise((resolve, reject) => {
                client = new WebSocket(Config.socket)
                client.onopen=() => {
                    console.log('长连接链接成功')
                    commit('UPDATE', ['ready', true])
                    resolve(true)
                }
                client.onclose=() => {
                    console.log('长连已被关闭')
                    commit('UPDATE', ['ready', false])
                }
                client.onerror=(err) => {
                    console.log(err)
                }
                client.onmessage=(msg) => {
                    let res = JSON.parse(msg.data);
                    if (process.env.NODE_ENV === 'development') {
                        console.log('长连接接收到消息: ', res);
                    }
                    switch (res.api) {
                        case 'lottery':
                            state.Event.emit('UPDATE_LOTTERY', res.data)
                            break;
                        case 'trader':
                            state.Event.emit('trader', res.data)
                            break;
                        case 'odds_trader':
                            state.Event.emit('odds_trader', res.data)
                            break;
                        default:
                    }
                }

                setInterval(() => {
                    client.send('TICK') // 每 100 秒发送一次心跳包
                }, 100000)
            })
        },
        EVENT({state, dispatch, commit}, data={}) {
            return state.Event
        },
        CHANGE_GAME({state, dispatch, commit}, code) {
            let token = Methods.Cache('token')
            let params = {
                api: 'recording_open', code, token
            }
            client.send(JSON.stringify(params))
        },
        CHANGE_PLAY({state, dispatch, commit}, {key, lottery_id}) {
            let token = Methods.Cache('token')
            let params = {
                api: 'trader', key, lottery_id, token
            }
            client.send(JSON.stringify(params))
        }
    },
    mutations: {
        UPDATE(state, opt) {
            let position, key, val, path, base='state';
            if (opt.constructor === Array) {
                val = opt.pop()
                path = opt.pop().split('.')
                key = path.pop()
                position = path.length ? `${base}.${path.join('.')}` : base
            }
            if (opt.constructor === Object) {
                path = obj.key.spilt('.')
                key = path.pop()
                val = obj.val
                base = opt.base ? opt.base : base
                position = path.length ? `${base}.${path.join('.')}` : base
            }
            try {
                Vue.set(eval(position), key, val)
            } catch (err) {

            }
        }
    }
}, ajax)