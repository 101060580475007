<style lang="scss"></style>
<template>
    <div class="OperateLhc-7">
        <div class="o-pb">
            <el-radio-group v-model="active" size="mini">
                <el-radio-button v-for="(pack,unit) in tabs" :key="pack" :label="unit">{{ pack }}</el-radio-button>
            </el-radio-group>
        </div>
        <template v-for="(pack,unit) in tabs" v-if="active === unit">
            <group :client="client" :list="Table[unit]" :length="5" show-total="end" />
            <BoardFast :client="client" :group="[unit]" :step="true" :default-step="0.1" />
        </template>
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateLhc7',
    mixins: [CommonMix],
    data() {
        return {
            active: 0,
            tabs: ['正码一', '正码二', '正码三', '正码四', '正码五', '正码六']
        }
    },
    props: {
        client: {}
    },
    computed: {

    },
    methods: {

    },
    components: {
        Group
    }
}
</script>
