
<template>
    <div class="OperateNavTable">
        <el-radio-group v-model="client.navMap['2_476b38e90e1a4d8e']" class="o-mb" size="small">
            <el-radio-button :label="1">佰拾定位OOX</el-radio-button>
            <el-radio-button :label="2">佰个定位OXO</el-radio-button>
            <el-radio-button :label="3">拾个定位XOO</el-radio-button>
        </el-radio-group>
        <group :client="client" :list="Table[groupActive]" :length="5" />
        <BoardFast :client="client" :group="[groupActive]" :step="true" :default-step="0.1" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateDpc8',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    computed: {
        groupActive() {
            return this.client.navMap['2_476b38e90e1a4d8e']
        }
    },
    watch: {
        'client.navMap.2_476b38e90e1a4d8e'(val, oldval) {
            this.$parent.LoadPlay(undefined, this.client.nav.active)
        }
    },
    components: {
        Group
    }
}
</script>
