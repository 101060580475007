<style lang="scss">
    .GameCreditCategory {

    }
</style>
<template>
    <div v-if="Running.category == title || (Running.nav && Running.nav.item && Running.nav.item.title === title)" class="GameCreditCategory u-bl u-br">
        <slot />
    </div>
</template>

<script>
import credit from '../../../../../mixin/credit.js'
export default {
    name: 'GameCreditCategory',
    mixins: [credit],
    data() {
        return {

        }
    },
    props: ['title']
}
</script>
