<style lang="scss">
    .OperateLhc-8 {

    }
</style>
<template>
    <div class="OperateLhc-8">
        <group :client="client" :list="Table[0]" :length="3" :interval="interval" :radio="true" :transmit="false" />
        <BoardFast :client="client" :group="[0]" :step="true" transmit="six-tail" :radio="true" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateLhc8',
    mixins: [CommonMix],
    data() {
        return {
            interval: ['3rem', 3, 3, 3, 3]
        }
    },
    props: {
        client: {}
    },
    computed: {

    },
    methods: {

    },
    components: {
        Group
    }
}
</script>
