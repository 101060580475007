<style lang="scss">
    .OperateLhc-1 {
        .o-pr-s {padding-left:.1rem;}
        .o-plr-s {padding-left:.1rem; padding-right:.1rem;}
        .o-pr-s {padding-right:.1rem;}
    }
</style>
<template>
    <div class="OperateLhc-1">
        <group :client="client" :list="Table[1]" :length="5" show-total="end" />
        <BoardFast :client="client" :group="[1]" type="lhc" :step="true" :outbtn="true" />
        <div class="l-flex">
            <div class="l-flex-4">
                <div class="l-flex">
                    <div class="l-flex-1" style="padding-right:.1rem;">
                        <group class="o-mt" :client="client" :list="Table[2]" :length="1" @RebateSet="RebateSetEmit" />
                        <group class="o-mt" :client="client" :list="Table[7]" :length="1" @RebateSet="RebateSetEmit" />
                    </div>
                    <div class="l-flex-1" style="padding-left:.1rem; padding-right:.1rem;">
                        <group class="o-mt" :client="client" :list="Table[4]" :length="1" @RebateSet="RebateSetEmit" />
                        <group class="o-mt" :client="client" :list="Table[10]" :length="1" @RebateSet="RebateSetEmit" />
                    </div>
                    <div class="l-flex-1" style="padding-left:.1rem; padding-right:.1rem;">
                        <group class="o-mt" :client="client" :list="Table[51]" :length="1" @RebateSet="RebateSetEmit" />
                        <group class="o-mt" :client="client" :list="Table[8]" :length="1" @RebateSet="RebateSetEmit" />
                    </div>
                    <div class="l-flex-1" style="padding-left:.1rem; padding-right:.1rem;">
                        <group class="o-mt" :client="client" :list="Table[5]" :length="1" @RebateSet="RebateSetEmit" />
                        <group class="o-mt" :client="client" :list="Table[81]" :length="1" @RebateSet="RebateSetEmit" />
                    </div>
                </div>
                <div class="l-flex" style="padding-right:.1rem;">
                    <div class="l-flex-1" style="padding-right:.1rem;">
                        <group class="o-mt" :client="client" :list="Table[3]" :length="2" @RebateSet="RebateSetEmit" />
                    </div>
                    <div class="l-flex-1" style="padding-left:.1rem;">
                        <group class="o-mt" :client="client" :list="Table[19]" :length="2" @RebateSet="RebateSetEmit" />
                    </div>
                </div>
            </div>
            <div class="l-flex-1" style="padding-left:.1rem;">
                <group class="o-mt" :client="client" :list="Table[6]" :length="1" @RebateSet="RebateSetEmit" />
                <group class="o-mt" :client="client" :list="Table[9]" :length="1" @RebateSet="RebateSetEmit" />
            </div>
        </div>
        <BoardFast :client="client" :group="[2,3,4,5,6,7,8,9,10,51,81,19]" :step="true" :default-step="0.01" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateLhc1',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group
    }
}
</script>
