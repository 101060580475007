<style lang="scss">
    .OperateLhc-5 {
        h6.title {
            padding:3px 5px; text-align:center; background-color:#f3655e; color:#fff;
        }
        .row-title {
            height:1.2rem; line-height:1.2rem; margin-top:.3rem; padding-left:.5rem; background-color:#dcd5ff;
        }
    }
</style>
<template>
    <div class="OperateLhc-5">
        <div v-show="OperateAuth.bind !== 3" class="l-flex">
            <div v-for="(item,index) in ['二肖','三肖','四肖','五肖']" class="l-flex-1 o-pr-s">
                <div class="row-title c-text-c">{{ item }}</div>
                <group :client="client" :list="Table[(index + 2) * 10 + 1]" :length="1" :radio="true" :transmit="false" :win="false" :total="totals[(index + 2) * 10 + 1]" />
            </div>
            <div class="l-flex-1" />
        </div>
        <BoardFast v-if="OperateAuth.bind === 2" :client="client" :group="[21,31,41,51]" :step="true" :default-step="0.1" />

        <div v-show="OperateAuth.bind === 3" class="l-flex o-mt">
            <div v-for="(item,index) in ['二肖','三肖','四肖','五肖']" class="l-flex-1 o-pr-s">
                <group :client="client" :list="Table[index + 1]" :length="1" :radio="true" :manipulation="false" :win="false" :table-index="(index + 2) * 10 + 1" @total="test($event)" @pick="AloneTransfer" />
            </div>
            <div class="l-flex-1" />
        </div>
        <BoardFast v-if="OperateAuth.bind === 3" :client="client" :group="[1,2,3,4]" transmit="six-zodiac" :step="true" :radio="true" :limit="limit" :default-step="0.1" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateLhc5',
    mixins: [CommonMix],
    data() {
        return {
            totals: {
                '21': {amount: 0, count: 0},
                '31': {amount: 0, count: 0},
                '41': {amount: 0, count: 0},
                '51': {amount: 0, count: 0}
            },
            limit: undefined
        }
    },
    props: {
        client: {}
    },
    computed: {

    },
    methods: {
        test(e) {
            if (e) {
                this.$set(this.totals, e.index, e)
            }
        }
    },
    components: {
        Group
    }
}
</script>
