import dgx from '~/dgx_z/index.js';
import ajax from '@lottery.2.0/library/ajax.js';

const store = 'myTeam';
const STORE = store.toUpperCase();

export default dgx({
    store,
    state: {
        statis: {
            title: '团队总览',
            _options: {
                url: 'team/browse'
            }
        },
        report: {
            title: '团队报表',
            _options: {
                url: 'report'
            }
        },
        percentage: {
            title: '奖金组',
            _options: {
                url: 'user/percentage'
            }
        },
        promote: {
            title: '推广链接',
            _options: {
                url: 'spread'
            }
        },
        user: {
            title: '用户列表',
            _options: {
                url: 'user/persons'
            }
        },
        user_limit_tpl: {
            title: '用户限额模板',
            _options: {
                url: 'user/optionsdemo'
            }
        },
        species: {
            title: '彩种信息',
            _options: {
                url: 'colors/info'
            }
        },
        matic: {
            title: '走飞设置',
            _options: {
                url: 'user/automatic'
            }
        },
        backup: {
            title: '注单备份',
            _options: {
                url: 'games/export'
            }
        },
        account: {
            title: '子帐号',
            _options: {
                url: 'index/aaccount'
            }
        },
        superior: {
            title: '用户成级',
            _options: {
                url: 'user/index/superior'
            }
        }
    },
    actions: {
        FILE_BACKUP({state, dispatch, commit}, data={}) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `games/export/file`,
                params: data
            })
        },

        PROPORTION_USER({state, dispatch, commit}, data) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'POST',
                url: `safety`,
                data
            })
        }
    },
    mutations: {

    }
},
ajax
);
