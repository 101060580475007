export default {

    Img(url, suffix = "") {
        if (url) {
            return url.indexOf("://") >= 0 ? url + suffix : this.Config.base_img + url + suffix
        } else {
            return ""
        }
    },

    Img_moblie(url, suffix = "") {
        if (url) {
            return url.indexOf("://") >= 0 ? url + suffix : this.Config.base_img_m + url + suffix
        } else {
            return ""
        }
    },

    Token(token) {
        if (token === undefined) {
            return this.Cache('token')
        } else if (token === null) {
            return this.Cache('token', null)
        } else if (token) {
            return this.Cache('token', token)
        }
    },

    Cache(key, val) {
        if (window.localStorage && key) {
            if (val === undefined) {
                try {
                    return JSON.parse(window.localStorage[key]);
                } catch (err) {
                    return window.localStorage[key];
                }
            } else if (val === null) {
                window.localStorage.removeItem(key)
                return undefined
            } else {
                window.localStorage[key] = JSON.stringify(val)
                return val
            }
        } else {
            return null
        }
    },
    CacheClean() {
        if (window.localStorage) {
            window.localStorage.clean();
            return undefined;
        }
    },

    Time(time, format = "yyyy-MM-dd hh:mm") {
        return this.Untimestamp(Date.parse(new Date(time)) / 1000, format)
    },
    Timestamp(date) {
        let time = date ? new Date(date) : new Date()
        return Date.parse(time) / 1000
    },
    Untimestamp(time, format = "yyyy-MM-dd hh:mm:ss") {
        if (typeof time === 'string' || typeof time === 'number') {
            if (time % 1 === 0 && time < 10000000000) {
                time = new Date(time*1000)
            } else {
                time = new Date(time)
            }
        }
        if (time===null || time === '0000-00-00 00:00:00' || time=='') {
            time = new Date(0)
        }
        let date = {
            "M+": time.getMonth() + 1,
            "d+": time.getDate(),
            "h+": time.getHours(),
            "m+": time.getMinutes(),
            "s+": time.getSeconds(),
            "q+": Math.floor((time.getMonth() + 3) / 3),
            "S+": time.getMilliseconds()
        };
        if (/(y+)/i.test(format)) {
            format = format.replace(RegExp.$1, (time.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        for (let k in date) {
            if (new RegExp("(" + k + ")").test(format)) {
                format = format.replace(RegExp.$1, RegExp.$1.length == 1
                    ? date[k] : ("00" + date[k]).substr(("" + date[k]).length));
            }
        }
        return format
    },

    Go(name, params = {}) {
        this.$router.push({
            name,
            params
        })
    },
    Rd(name, params = {}) {
        this.$router.replace({
            name,
            params
        })
    },
    Back() {
        this.$router.back()
    },
    Msg(message, type = 'success') {
        return this.Toast(message, type)
    },
    Toast(message, type = 'error', duration = 2000) {
        return this.$message({
            message,
            type,
            duration
        });
    },
    Suc(...opt) {
        return this.Msg(...opt)
    },
    Err(...opt) {
        return this.Toast(...opt)
    },
    Notify(title, message, type = "success") {
        return this.$notify({
            title,
            message,
            type
        });
    },

    ShuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            let temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    },

    Extend(old, ...obj) {
        return Object.assign(old, ...obj)
    },
    DataReset(key='Params') {
        if (this.$data[key]) {
            console.log()
            return this.$set(this.$data, key, this.$options.data()[key])
            //return Object.assign(this.$data[key], this.$options.data()[key])
        }
        return console.error('DataReset 错误的 key', key)
    },
    Origin(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    ParamsReset() {
        Object.assign(this.$data.params, this.$options.data().params)
    },
    Confirm(callback = new Function(), msg = '您确定要操作选定的数据么？', title = '操作数据', ) {
        return this.$confirm(msg, title, {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            confirmButtonClass: 'pj-alert-confirm',
            cancelButtonClass: 'pj-alert-cancel',
            type: 'warning',
            center: true
        }).then(() => {
            callback()
        }).catch(() => {})
    },
    DelConfirm(callback = new Function(), msg = '您确定要删除当前选定的数据么？', title = '删除数据', ) {
        return this.$confirm(msg, title, {
            confirmButtonText: '删除',
            cancelButtonText: '取消',
            confirmButtonClass: 'pj-alert-del',
            cancelButtonClass: 'pj-alert-cancel',
            type: 'error',
            center: true
        }).then(() => {
            callback()
        }).catch(() => {})
    },

    Is(type = "object", data) {
        if (typeof type !== 'string') {
            data = type
        }
        switch (type) {
            case 'int':
                return typeof data === 'number' && data % 1 === 0;
                break;
            case 'stringint':
                return parseInt(data) == data
                break;
            case 'object':
                return data && typeof data === 'object' && !isArray(data);
                break;
            case 'array':
                return Array.isArray(obj);
                break;
            case 'empty':
                if (data == null) return true;
                if (data.length > 0) return false;
                if (data.length === 0) return true;
                for (let key in data) {
                    if (Object.prototype.hasOwnProperty.call(data, key)) return false;
                }
                return true;
            case 'login':
                if (this.Token()) {
                    return this.$store.state.base.login.info
                }
                return false
            default:
                return false;
        }
    },

    // 浮点计算
    MathAdd(arg1, arg2) {
        let r1, r2, m;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        return (arg1 * m + arg2 * m) / m;
    },
    MathSub(arg1, arg2) {
        let r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        //动态控制精度长度
        n = r1 >= r2 ? r1 : r2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    MathMul(arg1, arg2) {
        let m = 0,
            s1 = arg1.toString(),
            s2 = arg2.toString()
        try {
            m += s1.split(".")[1].length
        } catch (e) {}
        try {
            m += s2.split(".")[1].length
        } catch (e) {}
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    }
}
