export default {
    data() {
        return {
            crumbkey: 'pid',
            breadcrumb: [
                {
                    title: '所有下级',
                    id: ''
                }
            ]
        }
    },
    computed: {

    },
    methods: {
        GetByBreadcrumb(item={}) {
            let key = this.crumbkey
            this.Filter[key] = ''
            let id = item.uid || item.id
            if (id) {
                this.Filter[key] = id
            }
            this.breadcrumb.push({
                title: item.username,
                id
            })
            this.Filter.subordinate = 1
            this.MakeFilter()
        },
        Breadcrumb(index) {
            this.Filter[this.crumbkey] = this.breadcrumb[index].id
            this.Filter.subordinate = index ? 1 : 0
            this.MakeFilter()
            this.$set(this, 'breadcrumb', this.breadcrumb.slice(0, index+1))
        }
    }
}