import Vue from 'vue'
import {deepCopy, isObject, isArray} from '../../helper.js'


export default function(options, model) {

    let store = options.store.toUpperCase()
    let MODEL = model.toUpperCase()

    options.actions[`_LOAD_${MODEL}`] = ({state, dispatch, commit}, data) => {
        let main = state[model]
        if ( !main._error ) {
            if (!main._init && !main._loading) {
                return Promise.resolve(
                    dispatch(`_GET_${MODEL}`, data)
                )
            } else {
                return Promise.resolve({code: 1})
            }
        } else {
            return Promise.resolve({code: 0})
        }
    }

    options.actions[`_MORE_${MODEL}`] = ({state, dispatch, commit}, data={}) => {
        let main = state[model]
        if ( !main._error && !main._loading) {
            if (!data || !data.params) {
                data = {params: {}}
            }
            if (data.refresh) {
                data.params.page = 1
            } else {
                data.params.page = state[model]._page
            }
            //data.params.size = 8
            return Promise.resolve(
                dispatch(`_GET_${MODEL}`, data)
            )

        } else {
            return Promise.resolve({code: 0})
        }
    }
}
