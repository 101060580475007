const board = {
    id: null,
    fast: {
        active: {},
        list: [
            [['全部玩法'], ['家禽', '野兽'], ['大', '小', '单', '双', '合单', '合双'], ['尾大', '尾小', '尾单', '尾双']],
            [['红波', '绿波', '蓝波'], ['红单', '红双', '红大', '红小'], ['绿单', '绿双', '绿大', '绿小'], ['蓝单', '蓝双', '蓝大', '蓝小']]
        ]
    },
    step: {
        active: 0.5,
        list: [10, 5, 4, 3, 2, 1, 0.5, 0.4, 0.3, 0.2, 0.1, 0.05, 0.03, 0.01, 0.005, 0.001]
    },
    stepdir: {},
    capacity: 200000,
    opt: {
        warn_sound: false,
        warn_modul: false
    }
}

import StoreMix from '@lottery.admin.2.0/mixin/store.js'
import SixMix from '@lottery.admin.2.0/mixin/six.js'

export default {
    name: 'Operate',
    mixins: [SixMix, StoreMix],
    data() {
        return {
            id: null,
            code: null,
            tabs: {
                active: null,
                clients: []
            },
            mode: undefined,

            opt: {
                reload_interval: 10000,
                reload_timer: null,
                reload_count: 0,
                reload_style: 0,
                reload_loading: false
            },
            Matic: {
                view: false,
                title: '自动走飞设置',
                form: null
            },
            Transmit: {
                view: false,
                type: 'normal',
                form: {list: []}
            },
            Transfer: {
                view: false,
                form: {}
            },
            MaticInfo: {
                view: false,
                form: {}
            },
            integration: {
                amount: 0
            },
            status: {
                bet_status: 1,
                transfer_status: 1
            },
            adminTransfer: {
                type: 0
            }
        }
    },
    props: {
        games: {default: {_list: []}},
        types: {default: {_list: []}},
        store: {default: 'six'},
        user_type: {
            default: 1
        },
        header: {
            default: false
        },
        newbtn: {
            default: false
        },
        onlySix: {
            default: true
        },
        bind: {
            default: undefined
        },
        transmit: {
            default: true
        }
    },
    computed: {
        TabItem() {
            if (this.tabs.active || this.tabs.active === 0) {
                let index = this.tabs.active
                if (this.tabs.clients[index]) {
                    return this.tabs.clients[index]
                }
            }
            return null
        },
        TabItemData() {
            if (this.TabItem) {
                let obj = {}
                for (let item of this.TabItem.play) {
                    if (!obj[item.group_mark]) {
                        obj[item.group_mark] = []
                    }
                    obj[item.group_mark].push(item)
                }
                return obj
            }
            return []
        },
        ExpectCalcu() {
            try {
                // let serve = this.TabItem.lot.serve_time
                // let seal = this.TabItem.lot.data.seal_time
                // let end = this.TabItem.lot.data.end_time
                let serve = this.Running.expect.time
                let seal = this.Running.expect.now.close
                let end = this.Running.expect.now.end
                // if(this.Opt.isDev){
                //     console.log('-----------')
                //     console.log(`服务器时间：${serve} - ${this.Time(serve,'yyyy-MM-dd hh:mm:ss')}`)
                //     console.log(`停止下注：${seal} - ${this.Time(seal,'yyyy-MM-dd hh:mm:ss')} / 剩余：${this.TimeTick(seal-serve)}`)
                //     console.log(`完全封盘：${end} - ${this.Time(end,'yyyy-MM-dd hh:mm:ss')} / 剩余：${this.TimeTick(end-serve)}`)
                // }
                return {
                    number: this.Running.expect.now.number,
                    serve: this.Time(serve*1000, 'yyyy-MM-dd hh:mm:ss'),
                    seal: this.TimeTick(seal-serve),
                    end: this.TimeTick(end-serve),
                    count_seal: seal-serve,
                    count_end: end-serve
                }
            } catch (err) {
                return null
            }
        },
        TplName() {
            if (this.TabItem) {
                if (this.TabItem.nav.active === 'integration') {
                    return `${this.TabItem.group}_integration`
                }
                if (this.TabItem.nav.item) {
                    return `${this.TabItem.group}_${this.TabItem.nav.item.name}`
                }
            }
            return null
        },
        OperateAuth() {
            let auth = {
                manipulation: false,
                transmit: false,
                bind: this.bind,
                admin: this.Opt.isAdmin,
                adminTransmit: this.bind === 3 && this.Opt.isAdmin,
                mode: this.adminTransfer.type,
                ...this.status
            }
            if (this.User) {
                if (this.User.role_id) {
                    auth.manipulation = true
                    auth.transmit = this.transmit
                    return auth
                } else if (this.Opt && this.Opt.operate && this.TabItem) {
                    let type = this.TabItem.group
                    if (this.Opt.operate[type]) {
                        return {
                            ...this.Opt.operate[type],
                            ...this.status,
                            bind: this.bind,
                            admin: this.Opt.isAdmin,
                            adminTransmit: this.bind === 3 && this.Opt.isAdmin,
                            mode: this.adminTransfer.type
                        }
                    }
                }
            }
            return auth
        },
        Integration() {
            if (this.TabItem) {
                let group = this.TabItem.group
                let dir = {
                    'lhc': '特整合'
                }
                if (dir[group]) {
                    let title = dir[group]
                    let {amount} = this.integration
                    return {
                        title,
                        amount
                    }
                }
            }
            return null
        },
        ImproperDir() {
            let improper = this.$store.state.game.improper
            if (improper._init) {
                let dir = {}
                for (let item of improper._list) {
                    let codes = item.str_data.split(',').sort()
                    if (item.status && codes.length === 5 && item.odds > 0) {
                        dir[codes.join(',')] = {
                            ...item
                        }
                    }
                }
                return dir
            }
            return null
        }
    },
    watch: {
        'tabs.active'(val) {
            if (val || val==0) {
                this.$emit('change', {id: this.TabItem.id, name: this.TabItem.name})
            }
        },
        'opt.reload_interval'(val) {
            this.AutoReload()
        },
        'bind'(val, old) {
            for (let item of this.tabs.clients) {
                if (!val) {
                    this.$set(item, 'mode', null)
                } else if (val==2) {
                    this.$set(item, 'mode', 'manipulation')
                } else if (val==3) {
                    this.$set(item, 'mode', 'transmit')
                }
            }
            this.OperateReload()
        }
    },
    methods: {
        init() {
            this.mode = this.$route.params.mode
            if (this.Opt.isAdmin) {
                this.Dp(`${this.store}/_GET_TSITE`)
            }
        },
        AutoReload() {
            this.AutoReloadClean()
            if (this.opt.reload_interval) {
                let step = 250
                clearInterval(this.opt.reload_timer)
                this.opt.reload_timer = setInterval(() => {
                    // console.log('123', this.opt.reload_down)
                    // this.opt.reload_down = parseInt((this.opt.reload_interval - this.opt.reload_count) / 1000)
                    this.$set(this.opt, "reload_down", parseInt((this.opt.reload_interval - this.opt.reload_count) / 1000))
                    this.opt.reload_count = this.opt.reload_count + step
                    if (this.opt.reload_count > this.opt.reload_interval) {
                        this.OperateReload()
                    }
                    this.opt.reload_style = `width:${this.opt.reload_count/this.opt.reload_interval*100}%;`
                }, step)
            }
        },
        AutoReloadClean() {
            clearInterval(this.opt.reload_timer)
            this.AutoReloadReset()
        },
        AutoReloadReset() {
            this.opt.reload_count = 0
            this.opt.reload_down = this.opt.reload_interval ? this.opt.reload_interval / 1000 : 0
            this.opt.reload_style = 'width:0;'
        },
        MaticEdit() {
            this.Matic.view = true
            this.Matic.form = this.Origin(this.TabItem)
        },
        TransferView() {
            // this.Transfer.view = true
            // this.$set(this.Transfer,'view',true)
            // this.$set(this.Transfer,'form',{
            //     games_id : this.TabItem.id,
            //     lottery_id : this.TabItem.lot.data.lottery_id,
            // })
            this.Editer.view = true
            this.Editer.mode = 'transfer'
            this.Editer.title = '走飞历史'
            this.$set(this.Editer, 'view', true)
            this.$set(this.Editer, 'form', {
                games_id: this.TabItem.id,
                lottery_id: this.TabItem.lot.data.lottery_id
            })
        },
        GetGameById(id) {
            for (let item of this.games._list) {
                if (item.id === id) {
                    return item
                }
            }
            return null
        },
        Operate(id) {
            if (id) {
                if (!this.TabItem || this.TabItem.id !== id) {
                    for (let i=0;i<this.tabs.clients.length;i++) {
                        if (this.tabs.clients[i].id === id) {
                            // this.ChangeGame(this.tabs.clients[i].name)
                            return this.$set(this.tabs, 'active', i)
                        }
                    }
                    let type = this.GetGameById(id)
                    if (type) {
                        // this.ChangeGame(type.name)
                        this.OperateAdd(type)
                    }
                }
            }
        },
        ChangeGame(item) {
            if (this.Running.id !== item.id) {
                this.$store.state.base.Event.emit('Credit/ChangeGame', item.name)
                this.Operate(item.id)
                console.log('ChangeGame', item)
            }
        },
        // ApplyGame(item){
        //     if(this.Running.id !== item.id){
        //         this.$store.state.base.Event.emit('Credit/ChangeGame',item.name)
        //         this.Operate(item.id)
        //         console.log('ApplyGame',item)
        //     }
        // },
        OperateAdd(game) {
            let name = game.code
            let length = this.tabs.clients.push({
                // 游戏数据
                id: game.id, name: game.code, type: game.type, title: game.title, info: {...game},
                // 计时器
                timer: null, interval: null,
                // 分类
                lot: {loading: true, data: {}, serve_time: 0},
                nav: {loading: true, list: [], item: null, active: null},
                play: [],
                // 工具
                board: this.Origin(board),
                mode: null, //'transmit'
                target: null,
                capacitys: {},
                instant: 1, // 及时注单 1:全部 2:占成
                side: 9, // 及时注单盘类
                index: null,
                history: {
                    target: null,
                    list: []
                },
                navMap: {
                    '2_476b38e90e1a4d8e': 1 // 低频彩 二字定位
                    //'2_4c3168d11848053d': 0, // 低频彩 三字定位
                },
                store: this.store
            })
            let index = length - 1
            let client = this.tabs.clients[index]
            for (let item of this.types._list) {
                if (item.id === game.type) {
                    this.$set(client, 'group', item.code)
                }
            }
            this.$set(this.tabs, 'active', index)

            // 填充期数
            this.OperateHistory(client)

            // 操盘模式 1:及时注单 2:操盘 3:走飞
            this.$set(client, 'mode', null)
            if (this.bind == 2 && this.OperateAuth.manipulation) {
                this.$set(client, 'mode', 'manipulation')
            }
            if (this.bind == 3 && this.OperateAuth.transmit) {
                this.$set(client, 'mode', 'transmit')
            }

            this.$nextTick(() => {
                this.$set(this.tabs.clients[this.tabs.clients.length-1], 'target', document.getElementById(name))
            })
            this.LoadLot(client)
        },
        OperateHistory(client) {
            this.Dp(`${this.store}/GET_HISTORY`, {games_id: client.id, games_type: client.type, per_page: 5}).then(res => {
                let Today = this.Time(new Date(), 'yyyy-MM-dd')
                if (res.data.length && res.data[0]) {
                    let list = []
                    for (let item of res.data) {
                        // if(item.date){
                        //     if(item.date === Today){
                        //         list.push(item)
                        //         if(!client.history.target){
                        //             this.$set(client.history,'target',item.id)
                        //         }
                        //     }else if(list.length < 3 || (list.length == 3 && list[0].date === Today)){
                        //         list.push(item)
                        //     }
                        // }else if(list.length < 5){
                        //     list.push(item)
                        // }
                        if (list.length < 5) {
                            list.push(item)
                        }
                    }
                    if (list[0] && !client.history.target) {
                        this.$set(client.history, 'target', list[0].id)
                    }
                    this.$set(client.history, 'list', list)
                }
            })
        },
        OperateClean(index) {
            if (this.tabs.clients[index]) {
                if (this.tabs.active == this.tabs.clients.length - 1) {
                    this.$set(this.tabs, 'active', this.tabs.active-1)
                }
                if (this.tabs.clients[index].socket) {
                    this.tabs.clients[index].socket.close()
                }
                if (this.tabs.clients[index].timer) {
                    clearInterval(this.tabs.clients[index].timer)
                }
                if (this.tabs.clients[index].interval) {
                    clearInterval(this.tabs.clients[index].interval)
                }
                this.tabs.clients.splice(index, 1)
            }
        },
        OperateCleanAll() {
            this.$set(this.tabs, 'active', null)
            while (this.tabs.clients.length > 0) {
                this.OperateClean(0)
            }
        },
        LoadLot(client=this.TabItem) {
            clearInterval(client.timer)
            this.$set(client.lot, 'loading', true)
            this.Dp(`${this.store}/_GET_OLOT`, {params: {games_id: client.id}}).then(res => {
                this.$store.state.base.Event.emit('Credit/ExpectLoad', res.data)
                this.$set(client.lot, 'loading', false)
                if (res) {
                    for (let key in res.data.now) {
                        this.$set(client.lot.data, key, res.data.now[key])
                    }
                    this.$set(client.lot, 'serve_time', res.data.serve_time)
                    // 启用计时器
                    client.timer = setInterval(() => {
                        if (client.lot.data.lottery_id && client.lot.serve_time) {
                            this.$set(client.lot, 'serve_time', client.lot.serve_time + 1)
                        }
                        if (client.lot.data.lottery_id && client.lot.data.end_time - client.lot.serve_time < 0) {
                            this.LoadLot(client)
                        }
                    }, 1000)
                    if (client.lot.data.lottery_id) {
                        this.LoadNav(client)
                    }
                }
            })
        },
        LoadNav(client=this.TabItem) {
            let index = client.index
            this.$set(client, 'nav', {loading: true, list: [], client: null, active: null})
            this.$set(client, 'play', [])
            let path = `${this.store}/_GET_ONAV`
            let params = {
                lottery_id: client.history.target && !client.mode ? client.history.target : client.lot.data.lottery_id,
                games_type: client.type,
                games_id: client.id
            }
            if (!client.mode) {
                path = `${this.store}/_GET_INAV`
                params.t = client.instant
                params.side = client.side
            }
            let cache = this.Cache(`operate_nav_${client.group}`)
            if (cache && cache.length) {
                this.$set(client.nav, 'loading', false)
                this.SetNav(cache)
                if (!index) {
                    index = 0
                }
                this.LoadPlay(client, index)
                this.Dp(path, {params}).then(res => {
                    this.SetNav(res.data)
                    this.CacheNav(res.data)
                })
            } else {
                this.Dp(path, {params}).then(res => {
                    this.$set(client.nav, 'loading', false)
                    if (res) {
                        this.SetNav(res.data)
                        this.CacheNav(res.data)
                        if (!index) {
                            index = 0
                        }
                        this.LoadPlay(client, index)
                    }
                })
            }
        },
        SetNav(data=[], client=this.TabItem) {
            if (client.nav.list.length == 0) {
                this.$set(client.nav, 'list', data)
            } else {
                let dir = {}
                for (let item of data) {
                    dir[item.id] = this.Origin(item)
                }
                for (let i=0;i<client.nav.list.length;i++) {
                    let id = client.nav.list[i].id
                    if (dir[id]) {
                        this.$set(client.nav.list, i, {...dir[id]})
                    }
                }
            }
        },
        CacheNav(data, client=this.TabItem) {
            let list = []
            for (let item of data) {
                let {id, title, name} = item
                list.push({id, title, name, amount: null})
            }
            this.Cache(`operate_nav_${client.group}`, list)
        },
        LoadPlay(client=this.TabItem, index) {
            this.$set(client.nav, 'active', index)
            this.$set(client, 'play', [])
            this.$set(client, 'index', index)
            let lottery_id = client.history.target && !client.mode ? client.history.target : client.lot.data.lottery_id
            let nav_id = client.nav.list[index].id
            if (nav_id) {
                this.$set(client.nav, 'item', this.Origin(client.nav.list[index]))
                let path = this.bind == 2 ? `${this.store}/_GET_OPLAY` : `${this.store}/_GET_ODATA`
                let params = {
                    lottery_id,
                    games_id: client.id
                }
                if (!client.mode) {
                    path = `${this.store}/_GET_IPLAY`
                    params.t = client.instant
                    params.side = client.side
                }
                if (this.bind === 3 && this.OperateAuth.admin && this.adminTransfer.type === 1) {
                    path = `${this.store}/_GET_JTDATA`
                    this.Dp(`${this.store}/_GET_JTINIT`).then(res => {
                        if (!res.data) {
                            this.adminTransfer.type = 0
                            this.Err('暂不开放快速走飞，已切换为手动走飞')
                            return this.LoadLot(this.TabItem)
                        } else {
                            this.Dp(path, {id: nav_id, params}).then(res => {
                                this.SetPlay(client, res.data)
                            })
                        }
                    })
                } else {
                    let {name, id, title} = client.nav.item
                    let key = `${client.type}_${name}`
                    if (client.navMap[key] || client.navMap[key] == 0) {
                        params.group_mark = client.navMap[key]
                    }
                    //console.log('加载玩法中',id, title, name ,key, client.navMap, client.navMap[key])
                    // 识别操盘 mark id

                    this.Dp(path, {id: nav_id, params}).then(res => {
                        this.SetPlay(client, res.data)
                    })
                }
            }
        },
        SetPlay(client, obj, list=[]) {
            if (!list.length) {
                for (let id in obj) {
                    list.push({
                        ...obj[id],
                        id,
                        pick: false
                    })
                }
                this.$set(client, 'play', list)
                client.play.sort((a, b) => {
                    return a.sort - b.sort
                })
            } else {
                for (let item of list) {
                    let id = item.id
                    let row = obj[id]
                    if (row) {
                        for (let key of ['odds', 'odds_up', 'max_odds', 'max_odds_up', 'amount', 'in_transfer', 'out_transfer', 'win', 'number']) {
                            if (row[key] != undefined && item[key] != row[key]) {
                                this.$set(item, key, row[key])
                            }
                        }
                    }
                }
            }
            client.play.sort((a, b) => {
                if (this.TabItem.nav.item) {
                    for (let title of ['自选不中', '连肖']) {
                        if (this.TabItem.nav.item.title == title) {
                            return b.amount - a.amount
                        }
                    }
                }
                return a.win - b.win
            })
            this.$set(client.board.fast, 'active', {'全部玩法': false})
        },
        OperateReload(client=this.TabItem) {

            this.AutoReloadReset()
            if (!client.mode && client.history.target) {

            } else if (!client || !client.lot.data.lottery_id || client.lot.loading) {
                return
            }

            // 刷新历史数据 (如果是及时注单)
            if (this.bind === 1) {
                this.OperateHistory(client)
            }

            let id = client.id
            let lottery_id = client.lot.data.lottery_id

            // 历史->即使注单
            if (client.history.target && !client.mode) {
                lottery_id = client.history.target
            }
            let games_type = client.type
            let nav_id = client.nav.item.id
            this.opt.reload_loading = true

            // 刷新导航数据
            let path = `${this.store}/_GET_ONAV`
            let params = {
                lottery_id,
                games_type,
                games_id: id
            }
            if (!client.mode) {
                path = `${this.store}/_GET_INAV`
                params.t = client.instant
                params.side = client.side
            }

            this.Dp(path, {params}).then(res => {
                if (res && client.id === id) {
                    for (let item of res.data) {
                        if (client.nav.item && client.nav.item.id == item.id) {
                            this.$set(client.nav, 'item', item)
                            break
                        }
                    }
                    this.SetNav(res.data)
                    //this.$set(client.nav,'list',res.data)
                }
            })

            // 刷新操盘数据
            let path2 = `${this.store}/_GET_OPLAY`
            let params2 = {
                lottery_id,
                games_type,
                id: nav_id,
                games_id: id
            }
            if (!client.mode) {
                path2 = `${this.store}/_GET_IPLAY`
                params2.t = client.instant
                params2.side = client.side
            }
            if (this.bind === 3 && this.OperateAuth.admin && this.adminTransfer.type === 1) {
                path2 = `${this.store}/_GET_JTDATA`
            }

            try {
                let {name, id, title} = client.nav.item
                let key = `${client.type}_${name}`
                if (client.navMap[key] || client.navMap[key] == 0) {
                    params2.group_mark = client.navMap[key]
                }
                // console.log('刷新玩法中',id, title, name ,key, client.navMap, client.navMap[key])
            } catch (e) {

            }
            return this.Dp(path2, {id: client.nav.item.id, params: params2}).then(res => {
                if (client.nav.item && client.nav.item.id === nav_id) {
                    this.$set(client, 'data', res.data)
                    this.SetPlay(client, res.data, client.play)
                }
                this.opt.reload_loading = false
            }, err => {
                this.opt.reload_loading = false
            })
        },
        TransmitSubmit(capacity, group, code, value, hand) {
            // console.log('TransmitSubmit',capacity,group,code,value,hand)
            if (group || group===0) {
                group = this.FastGroupTable(group)
                console.log('group 2', group)
                let fast = false
                let pickTotal = 0
                for (let item of this.TabItem.play) {
                    if (item.pick && group[item.group_mark]) {
                        pickTotal++
                    }
                }
                if (pickTotal === 0) {
                    fast = true
                }
                let order = []
                for (let item of this.TabItem.play) {
                    let isValid = item.pick && group[item.group_mark]
                    let transmit = 0
                    if (!isValid && group[item.group_mark] && fast) {
                        transmit = this.TransmitCalcu(item, capacity)
                        console.log('transmit:', transmit)
                        if (transmit > 0) {
                            isValid = true
                        }
                    }
                    if (isValid) {
                        let win = Math.abs(item.win)
                        let amount = this.Big(item.amount).add(item.in_transfer).sub(item.out_transfer).toNumber()
                        let _capacity = value ? undefined : capacity
                        if (amount > 0) {
                            let val = 0
                            let transfer = _capacity ? Math.ceil(this.Big(win).sub(Math.abs(_capacity)).div(item.odds).toNumber()) : 0
                            if (item.win < 0 && transfer > 0) {
                                val = transfer > amount ? amount : transfer
                            }
                            let odds = hand ? hand.odds : item.odds
                            let belong = this.TabItem.nav.item.title

                            // 自选不中热门自动降低赔率
                            if ( belong == '自选不中' && item.name == '五不中' && this.ImproperDir) {
                                let codes = []
                                for (let c of code.split(',')) {
                                    codes.push(parseInt(c))
                                }
                                codes = codes.join(',')
                                if (this.ImproperDir[codes] && this.ImproperDir[codes].odds > 0) {
                                    odds = this.Big(odds).sub(this.ImproperDir[codes].odds).toNumber()
                                }
                            }

                            order.push({
                                id: item.id,
                                amount,
                                belong,
                                win: item.win,
                                backwater: item.backwater,
                                backwater_percent: hand ? hand.backwater_percent : undefined,
                                title: item.name,
                                val: transmit ? transmit : value ? value : val,
                                odds,
                                max_odds: hand && hand.max_odds ? hand.max_odds : odds,
                                mark_ids: item.mark_ids,
                                code,
                                mark: false,
                                suc: false,
                                hand
                            })
                        }
                    }
                }
                if (!order || !order.length) {
                    return this.Err('未选择需要走飞的项目，或被选择的项目无需走飞。')
                }
                this.TransmitListSubmit(order, hand)
            }
        },
        TransmitCalcu(item, _capacity) {

            try {
                if (item.win < 0) {
                    let win = Math.abs(item.win)
                    let capacity = Math.abs(_capacity)
                    let amount = this.Big(item.amount).add(item.in_transfer).sub(item.out_transfer).toNumber()
                    let transfer =  Math.ceil(this.Big(win).sub(capacity).div(item.odds).toNumber())
                    if (transfer > 0) {
                        return transfer > amount ? amount : transfer
                    }
                }
                return 0
            } catch (err) {
                return NaN
            }
        },

        // 提交走飞确认
        TransmitListSubmit(list, hand) {
            if (list && list.length) {
                this.$set(this.Transmit, 'type', this.bind === 3 && this.OperateAuth.admin ? 'admin' : 'normal')
                this.$set(this.Transmit, 'view', true)
                this.$set(this.Transmit, 'form', {list, hand})
            }
        },
        // 提交走飞
        TransmitPush(data, fast=false) {

            let origin = this.Origin(data)
            let order = origin.list
            let hand = origin.hand
            if (order && order.length) {
                let list = []
                for (let item of order) {
                    if (item.val || item.suc || item.mark) {
                        list.push(item)
                    }
                }
                if (list.length) {
                    let path = `${this.store}/_POST_TRANSFER`
                    if (this.Transmit.type === 'admin') {
                        path = `${this.store}/_POST_JTDATA`
                        if (hand && hand.web_name) {
                            path = `${this.store}/_POST_HTRANSFER`
                        }
                    }
                    return this.Dp(path, {data: {
                        id: this.TabItem.nav.item.id,
                        lottery_id: this.TabItem.lot.data.lottery_id,
                        data: JSON.stringify(list),
                        web_name: hand && hand.web_name ? hand.web_name : undefined
                    }}).then(res => {
                        if (!res.data.length) {
                            this.Suc('请求已提交')
                            this.OperateReload()
                            this.$set(this.Transmit, 'view', false)
                        } else {
                            let errors = {}
                            let sucs = origin.length - res.data.length
                            this.Notify('请注意！', `有 ${res.data.length} 项由于赔率变动提交失败，请重新确认`, 'warning')
                            for (let item of res.data) {
                                errors[item.id] = {odds: item.odds}
                            }
                            let affirm = []
                            for (let item of order) {
                                if (errors[item.id]) {
                                    affirm.push({
                                        ...item,
                                        old_odds: item.odds,
                                        odds: errors[item.id].odds,
                                        mark: true
                                    })
                                } else {
                                    affirm.push({
                                        ...item, val: 0, suc: true
                                    })
                                }
                            }
                            this.$set(this.Transmit.form, 'list', this.Origin(affirm))
                            this.$refs['transmit'].init()
                        }
                    })
                } else {
                    this.$set(this.Transmit, 'view', false)
                    this.Suc('暂无需要走飞的项目')
                }
            } else {
                return Promise.resolve()
            }
        },
        // 金额合计
        AmountTotal(row) {
            try {
                return this.Big(row.amount).add(row.in_transfer).sub(row.out_transfer).round(2, 0).toNumber()
            } catch (err) {
                return row.amount
            }
        },
        // 操盘
        RebateSet(item, type="+", group, input, key) {
            if (typeof input != 'number') {
                return this.Err('步进值格式错误')
            }
            if (input > 10 || input < 0.001) {
                return this.Err('步进值不在正确的取值范围内')
            }
            let data = {
                action: 'setodds',
                data: [],
                games_id: this.TabItem.id,
                games_play_group_id: this.TabItem.nav.item.id,
                lottery_id: this.TabItem.lot.data.lottery_id
            }
            let dir = {}
            if (item) {
                let obj = {
                    id: item.id, rebate: type == '+' ? input : -input, max: key === 'max_odds' ? 1 : 0
                }
                dir[item.id] = this.Origin(obj)
                data.data.push(obj)
            } else {
                group = this.FastGroupTable(group)
                for (let item of this.TabItem.play) {
                    if (item.pick && (!group || group[item.group_mark])) {
                        let obj = {
                            id: item.id, rebate: type == '+' ? input : -input, max: 0
                        }
                        dir[item.id] = this.Origin(obj)
                        data.data.push(obj)
                    }
                }
                if (!data.data.length) {
                    this.Err('请选择需要操盘的项目')
                }
            }
            if (data.data.length) {
                data.data = JSON.stringify(data.data)
                this.Dp(`${this.store}/POST_TRADER`, data).then(res => {
                    if (res) {
                        this.Suc('请求已提交')
                        if (this.TabItem && this.TabItem.play && this.TabItem.play.length) {
                            for (let row of this.TabItem.play) {
                                if (dir[row.id]) {
                                    try {
                                        let pack = dir[row.id]
                                        let target = pack.max ? 'max_odds' : 'odds'
                                        let calcu = this.Big(row[target]).add(pack.rebate).toNumber()
                                        this.$set(row, target, calcu)
                                    } catch (err) {}
                                }
                            }
                        }
                    }
                })
            }
        },
        // 快速选择
        BoardFast(name, group, input) {
            if (!group && group !=0) {
                input = !this.TabItem.board.fast.active[name]
                this.$set(this.TabItem.board.fast.active, name, input)
            } else {
                if (typeof group === 'number') {
                    group = [group]
                }
                if (typeof group === 'string') {
                    group = group.split(',')
                }
                let obj = {}
                for (let id of group) {
                    obj[id] = true
                }
                group = this.FastGroupTable(group)
            }
            let index = 1
            for (let item of this.TabItem.play) {
                if (!group || group[item.group_mark]) {
                    if (name === null) {
                        this.$set(item, 'pick', false)
                    }
                    if (name === '全部玩法') {
                        this.$set(item, 'pick', input)
                    }
                    if (name === '1-49' && parseInt(item.name) >= 1 && parseInt(item.name) <= 49) {
                        this.$set(item, 'pick', input)
                    }
                    // 按最终排序
                    if (name === '前5' || name === '前10') {
                        if (name === '前10' && index >= 1 && index <= 10) {
                            this.$set(item, 'pick', input)
                        }
                        if (name === '前5' && index >= 1 && index <= 5) {
                            this.$set(item, 'pick', input)
                        }
                    }
                    if (this.QuickBetList[name] && parseInt(item.name) >= 1 && parseInt(item.name) <= 49) {
                        for (let i of this.QuickBetList[name]) {
                            if (i==item.name) {
                                this.$set(item, 'pick', input)
                            }
                        }
                    }
                    index++
                }
            }
        },
        FastGroupTable(group) {
            if (!group && group!=0) {
                return null
            }
            if (typeof group === 'number') {
                group = [group]
            }
            if (typeof group === 'string') {
                group = group.split(',')
            }
            let obj = {}
            for (let id of group) {
                obj[id] = true
            }
            return obj
        },
        TimeTick(time=0) {
            let h=0, m=0, s=0;
            if (time > 3600) {
                h = Math.floor(time/3600)
                time = time - h*3600
                h = `${h}`.length === 2 ? `${h}` : `0${h}`
            }
            if (time > 60) {
                m = Math.floor(time/60)
                time = time - m*60
                m = `${m}`.length === 2 ? `${m}` : `0${m}`
            }
            s = `${time}`.length === 2 ? `${time}` : `0${time}`
            return `${h}:${m}:${s}`
        },
        Details(item, integration) {

            let client = this.TabItem
            let games_id = this.TabItem.id
            let lottery_id = client.history.target && !client.mode ? client.history.target : client.lot.data.lottery_id
            if (item) {
                this.Editer.view  = true
                this.Editer.title  = `【${this.TabItem.nav.item.title} ${item.name}】`
                this.Editer.mode = null
                this.Editer.form  = {
                    games_id,
                    lottery_id,
                    id: item.id,
                    group_id: this.TabItem.nav.item.id,
                    integration
                }
            } else {
                this.Editer.view  = true
                this.Editer.title  = `【${this.TabItem.nav.item.title}】`
                this.Editer.mode = null
                this.Editer.form  = {
                    games_id,
                    lottery_id,
                    games_group_id: this.TabItem.nav.item.id,
                    integration
                }
            }
            if (this.Opt.isMobile) {
                this.Go('index/pages/operate/transfer', {title: this.Editer.title, ...this.Editer.form})
            }
        },
        GetStates() {
            if (this.bind === 3 || this.bind === 2) {
                this.Dp(`${this.store}/STATES_TRADER`).then(res => {
                    this.$set(this.status, 'bet_status', res.data == '1' ? 1 : 0)
                })
            }
        },
        // 立即封盘
        TraderStatus(key, status=0) {
            this.Dp(`${this.store}/SEAL_TRADER`, {
                // action : 'seal',
                // key,
                status
            }).then(res => {
                this.Suc('操作成功')
                this.GetStates()
            })
        },
        reload_intervalChange(e) {
            // 跨页面缓存
            try {
                if (e >= 0) {
                    this.$store.commit('base/UPDATE_BLOCK', ['operate', 'reload_interval', e])
                }
            } catch (e) {}
        },
        TryLoadCacheOpt() {
            if (this.$store.state.base.block && this.$store.state.base.block.operate) {
                let reload_interval = this.$store.state.base.block.operate.reload_interval
                if (reload_interval) {
                    this.$set(this.opt, 'reload_interval', reload_interval)
                }
            }
        }
    },
    activated() {
        this.GetStates()
        if (this.onlySix) {
            let id = this.Is('int', this.onlySix) ? this.onlySix : localStorage.getItem('games_id') ? Number(localStorage.getItem('games_id')) : 31
            if (this.Opt.isMobile) {
                if (!this.TabItem) {
                    this.Operate(id)
                    try {
                        const row_gamge = this.Opt.hasDir.game.find(row => row.id == id)
                        if (row_gamge) {
                            this.Cm('game/CHANGE_GAMES', row_gamge)
                        }
                    } catch (e) {}
                    
                } else {
                    // this.LoadLot(this.TabItem)
                }
            } else {
                this.Operate(id)
            }
        } else {
            this.mode = this.$route.params.mode
            if (this.Running && this.Running.id) {
                // this.Operate(this.Running.id)
                this.Operate(1)
            }
        }
        this.TryLoadCacheOpt() // 跨页面读取缓存
        this.AutoReload()
    },
    mounted() {
        this.init()
        this.AutoReload()
        // this.$set(this,'id',this.Running.id)
    },
    beforeDestroy() {
        this.AutoReloadClean()
    }
}