// 封装localStorage
class Storage {
    constructor() {
        this.store = window.localStorage;
    }
    set(key, value, fn) {
        try {
        	let currentTime = new Date().getTime();
            value = JSON.stringify({data: value, time: currentTime});
        } catch (e) {
            value = value;
        }

        this.store.setItem(key, value);

        fn && fn();
    }

    get(key, exprission) {
        if (!key) {
            throw new Error('没有找到key。');
            return;
        }
        if (typeof key === 'object') {
            throw new Error('key不能是一个对象。');
            return;
        }

        let value = JSON.parse(this.store.getItem(key));
        if (value!== null) {
	        if (typeof exprission != "undefined" && new Date().getTime() - value.time > exprission) {
	        	console.log('token已过期');
		      	return false;//token过期返回false
	      	} else {
	        	return value.data;
	        }
        }
    }

    remove(key) {
        this.store.removeItem(key);
    }
}
export default new Storage();
