
import Credit from '../../../../mixin/credit.js'
import Category from './components/category.vue'
import Row from './components/row'
import SixMix from '@lottery.admin.2.0/mixin/six.js'

let oddsTimer = null

export default {
    name: 'GameCreditXGLHC',
    mixins: [SixMix, Credit],
    data() {
        return {
            side: {
                active: 1,
                list: [1, 2, 3, 4],
                group: []
            },
            direction: {
                active: 'a',
                list: ['a', 'b'],
                '特码': '特码'
            },
            // 正码特
            ToSpecial: {
                active: '正码一',
                list: ['正码一', '正码二', '正码三', '正码四', '正码五', '正码六']
            },
            // 自选不中
            ToMiss: {
                active: 0,
                balls: {},
                explain: ''
            },
            // 连肖 + 连尾
            ToContact: {
                active: 0,
                mode: '连碰',
                dir: ['连碰', '胆拖'],
                explain: '',
                comb: []
            },
            // 合肖
            ToMerge: {
                dir: null,
                active: null,
                explain: ''
            },
            ToCode: {
                active: 0,
                mode: '连碰',
                comb: [],
                explain: '',
                zodiac: null,
                codes: null,
                tail: null
            },
            oddsTimer: null,
            tips: {
                data: null,
                view: false
            },
            needReloadOdds: true
        }
    },
    props: {
        List: {
            default: []
        },
        DB: {
            default: {}
        }
    },
    computed: {
        Tips() {
            if (this.tips.data) {
                let tips = this.tips.data
                let list = []
                for (let group in tips) {
                    if (group === 'special') {
                        if (tips[group].num > 2) {
                            list.push({
                                title: `特码@${tips[group].number}`,
                                count: tips[group].num
                            })
                        }
                    } else {
                        for (let child in tips[group]) {
                            if (tips[group][child] > 2) {
                                list.push({
                                    title: `${group}@${child}`,
                                    count: tips[group][child]
                                })
                            }
                        }
                    }
                }
                return list
            }
            return null
        },
        group() {
            return this.$store.state.game.group
        },
        plays() {
            return this.$store.state.game.play
        },
        prompt() {
            return this.COptions.type == 1 ? ['号码', '赔率', '金额'] : ['号码', '赔率']
        },
        promptFull() {
            return this.COptions.type == 1 ? ['项目', '赔率', '金额', '号码'] : ['项目', '赔率', '号码']
        },
        promptInterval() {
            return this.COptions.type == 1 ? [7, 6, 11] : [12, 12]
        },
        GroupActive() {
            if (this.Running.category && this.group._init) {
                for (let item of this.group._list) {
                    if (this.Running.category == item.title) {
                        return {
                            ...item,
                            id: item.group_id || item.id,
                            title: item.title
                            //subset : item.subset,
                        }
                    }
                }
            }
            return {}
        },
        SpecialGroup() {
            let index = this.DirectionIndex[0]
            let list = [{child: []}, {child: []}]
            try {
                for (let item of this.List[index].child) {
                    if (item.view_mark == 1) {
                        list[0].child.push(item)
                    } else if (item.view_mark == 2) {
                        list[1].child.push(item)
                    }
                }
            } catch (err) {}
            return list
        },
        DirectionIndex() {
            if (this.Running.nav.item) {
                let cate = this.Running.nav.item.title
                if (this.List && this.List.length && this.direction[cate]) {
                    let arr = []
                    for (let i=0;i<this.List.length;i++) {
                        if (this.List[i].form == this.direction.active) {
                            arr.push(i)
                        }
                    }
                    return arr
                }
            }
            return []
        },
        ToSpecialList() {
            let title = this.ToSpecial.active
            for (let item of this.List) {
                if (item.title === title) {
                    return item
                }
            }
            return null
        },
        ToCodeList() {
            try {
                let group = this.ToCode.active
                let mode = this.ToCode.mode
                if (this.List && this.List.length) {
                    for (let pack of this.List[group].child) {
                        if (pack.title === this.ToCode.mode) {
                            return pack
                        }
                    }
                }
            } catch (err) {
                return {id: null, child: []}
            }
        },
        SideAuth() {
            if (this.User) {
                return {
                    1: this.User.has_a_handicap,
                    2: this.User.has_b_handicap,
                    3: this.User.has_c_handicap,
                    4: this.User.has_d_handicap
                }
            }
            return {}
        },
        ImproperDir() {
            let improper = this.$store.state.game.improper
            if (improper._init) {
                let dir = {}
                for (let item of improper._list) {
                    let codes = item.str_data.split(',').sort()
                    if (item.status && codes.length === 5 && item.odds > 0) {
                        dir[codes.join(',')] = {
                            ...item
                        }
                    }
                }
                return dir
            }
            return null
        }
    },
    watch: {
        // 期数变化获取热门号码
        'Running.expect.now.expect'(next, last) {
            this.GetTips()
        },
        'Running.nav.side.id'(val) {
            if (val) {
                this.needReloadOdds = true
            }
        },
        'List'(val) {
            console.log("List change")
            this.OddTimerInit(true)
        }
    },
    methods: {
        init() {
            this.Dp('game/_GET_IMPROPER')
            this.GetTips()
            if (oddsTimer === null) {
                this.OddTimerRun(true)
            }
        },
        Reset() {
            this.ToMiss.explain = '请选择 5 项号码'
            this.ToContact.explain = '请选择号码'
            this.ToCode.explain = '请选择号码'
            this.ToMergeInit()
        },
        // 获取自选不中热门
        GetTips() {
            // if (!this.Opt.isLocalhost) {
                this.Dp('game/_GET_TIPS', {only: false, params: {games_id: this.Running.id}}).then(res => {
                    this.$set(this.tips, 'data', res.data)
                })
            // }
        },
        OddTimerInit() {
            if (this.needReloadOdds) {
                this.Dp('game/GOODS_CLEAN_ODDS').then(res => {
                    this.needReloadOdds = false
                    this.$nextTick(() => {
                        this.OddTimerRun(true)
                    })
                })
            }
        },
        OddTimerRun(immediately) {
            clearInterval(oddsTimer)
            if (immediately) {
                this.$nextTick(() => {
                    this.OddTimerGet()
                })
            }
            oddsTimer = setInterval(this.OddTimerGet, 60000)
        },
        OddTimerGet() {

            // console.log("OddTimerGet 1", this.$route.name)
            if (this.$route.name === 'credit/game' || this.$route.name === 'index/pages/table') {
                if (this.Running.expect.now && this.Running.expect.now.id && this.Running.expect.now.status == 0) {
                    if (this.Running.nav && this.Running.nav.item && this.DB && this.Running.model == 7) {
                        let title = this.Running.nav.item.title
                        let belong = null
                        switch (title) {
                            case '自选不中':
                                let active = this.ToMiss.active
                                belong = ['五不中', '六不中', '七不中', '八不中', '九不中', '十不中', '十一不中', '十二不中'][active]
                                break;
                            case '正码特':
                                belong = this.ToSpecial.active
                                break;
                        }
                        let ids = []
                        for (let id in this.DB) {
                            if (!belong || this.DB[id].belong == belong) {
                                ids.push(id)
                            }
                        }
                        // console.log("OddTimerGet 2", {ids})
                        this.Dp('game/GOODS_UPDATE_ODDS', {ids, games_id: this.Running.id})
                    }
                }
            }
        },
        // 自选不中
        ToMissChange(e) {
            if (!this.CheckOdds(e)) {
                return false
            }
            let max = this.ToMiss.active + 5
            this.ToMiss.explain = `请选择 ${max} 项号码`
            let arr = []
            let codes = []
            this.List.forEach((pack, unit) => {
                if (this.ToMiss.active === unit) {
                    pack.child.forEach((item, index) => {
                        if (item.pick) {
                            arr.push(item.name)
                            codes.push(item)
                        }
                        this.$set(item, 'repair', 0)
                    })
                    if (arr.length === max) {
                        // 热门降低赔率
                        let OddsFepair = (numbers, repair=0) => {
                            let obj = null
                            if (numbers) {
                                obj = {}
                                for (let item of numbers.split(',')) {
                                    obj[item] = repair
                                }
                            }
                            pack.child.forEach((item, index) => {
                                if (obj[item.name]) {
                                    this.$set(item, 'repair', repair)
                                }
                            })
                        }
                        pack.child.forEach((item, index) => {
                            if (!item.pick) {
                                this.$set(item, 'disabled', true)
                            }
                        })
                        this.ToMiss.explain = '选择完成'

                        // 选择号码中，以最低的赔率为准
                        let odds = null
                        for (let code of codes) {
                            let coderow = this.Running.play.odds[code.id]
                            if (!odds || odds > coderow.odds) {
                                odds = coderow.odds
                            }
                        }
                        // 如果处于热门号码，则按热门号码赔率再减
                        let nums = arr.sort().join(',')
                        let repair = 0

                        if (max == 5 && this.ImproperDir[nums]) {
                            repair = this.ImproperDir[nums].odds
                            OddsFepair(nums, repair)
                            this.ToMiss.explain = '选择完成，当前号码组合产生赔率变动，请重新确认！'
                        }
                        // ImproperDir
                        return this.$emit('updateOpt', {key: 'customOrder', val: [
                            {
                                name: nums, belong: pack.title, id: pack.id, code: nums, games_play_group_id: pack.games_play_group_id, odds: this.Big(odds).sub(repair).toNumber()
                            }
                        ]})
                    } else {
                        pack.child.forEach((item, index) => {
                            this.$set(item, 'disabled', false)
                        })
                        this.ToMiss.explain = `已选 ${arr.length} 项,还需选择 ${max - arr.length} 项`
                        return this.$emit('updateOpt', {key: 'customOrder', val: []})
                    }
                }
            })
        },
        ToCodeGroup(list, index) {
            this.$emit('reset')
            for (let item of list.child) {
                if (item.title === this.ToCode.mode) {
                    return
                }
            }
            this.ToCode.active = index
            this.ToCode.mode = list.child[0].title
        },
        // 连码
        ToCodeChange(e) {
            if (!this.CheckOdds(e)) {
                return false
            }
            let mode = this.ToCode.mode
            let position = []
            if (mode==='连碰'||mode==='胆拖') {
                let title = this.List[this.ToCode.active].title
                let length = {'三': 3, '二': 2, '特': 2}[title[0]]
                let dir = {}
                let list = []
                this.ToCodeList.child.forEach((sub, i) => {
                    if (sub.pick) {
                        dir[sub.name] = true
                        position.push(sub.name)
                    }
                })
                for (let i=0;i<this.ToCode.comb.length;i++) {
                    let name = this.ToCode.comb[i]
                    if (dir[name]) {
                        list.push(name)
                        dir[name] = false
                    }
                }
                for (let name in dir) {
                    if (dir[name]) {
                        list.push(name)
                    }
                }
                this.$set(this.ToCode, 'comb', list)
                let info = this.Comb(mode, list, length, this.ToCodeList, this.ToCodeList.child)
                return this.ToCode.explain = info.msg
            }
            if (mode==='生肖对碰'||mode==='尾数对碰'||mode==='混合对碰') {

                let oddsDir = {}
                let arr = []
                this.ToCodeList.child.forEach((item, index) => {
                    oddsDir[item.name] = this.Running.play.odds[item.id]
                    if (item.pick) {
                        arr.push(item.name)
                    }
                })
                this.ToCodeList.child.forEach((item, index) => {
                    if (arr.length >= 2) {
                        if (!item.pick) {
                            this.$set(item, 'disabled', true)
                        }
                    } else if (arr.length===1&&mode==='混合对碰') {
                        this.$set(item, 'disabled', !!(!item.pick && arr[0][1] === item.name[1]))
                    } else {
                        this.$set(item, 'disabled', false)
                    }
                })
                if (arr.length >=2 ) {
                    let code = arr.join(',')
                    let pack_1 = this.QuickBetList[arr[0]]
                    let pack_2 = this.QuickBetList[arr[1]]
                    let bets = []
                    for (let n1 of pack_1) {
                        for (let n2 of pack_2) {
                            if (n1 !== n2) {
                                let _code = `${n1},${n2}`
                                bets.push({
                                    name: _code, belong: this.ToCodeList.belong, id: this.ToCodeList.id, code: _code, games_play_group_id: this.ToCodeList.games_play_group_id,
                                    odds: CalcuOdds(`${arr[0]},${arr[1]}`),
                                    max_odds: CalcuOdds(`${arr[0]},${arr[1]}`, 'max_odds')
                                })
                            }
                        }
                    }
                    this.$emit('updateOpt', {key: 'customOrder', val: bets})
                } else {
                    this.$emit('updateOpt', {key: 'customOrder', val: []})
                }
                function CalcuOdds(codes, name='odds') {
                    let odds = null
                    for (let code of codes.split(',')) {
                        let coderow = oddsDir[code]
                        if (!odds || odds > coderow[name]) {
                            odds = coderow[name]
                        }
                        if (name == 'max_odds' && !oddsDir[code].has_double_odds) {
                            odds = undefined
                        }
                    }
                    return odds
                }
                // 生成信息
                let arr_code = []
                let info = `${mode}号码`
                for (let item of arr) {
                    info += ` ${item} [${this.QuickBetList[item].join(',')}]`
                    arr_code.push({
                        code: item,
                        codes: this.QuickBetList[item]
                    })
                }
                if (arr_code.length) {
                    if (arr_code.length === 1) {
                        info += `, 共组成 0 笔`
                    }
                    if (arr_code.length === 2) {
                        let total = 0
                        for (let ca of arr_code[0].codes) {
                            for (let cb of arr_code[1].codes) {
                                if (ca != cb) {
                                    total++
                                }
                            }
                        }
                        info += `, 共组成 ${total} 笔`
                    }
                    return this.ToCode.explain = info
                } else {
                    return this.ToCode.explain = '请选择号码'
                }
            }
        },
        // 合肖
        ToMergeInit() {
            this.$set(this.ToMerge, 'dir', {
                child: this.MakeZodiacList(true)
            })
            this.ToMerge.explain = '请先选择类别，再勾选对应生肖'
        },
        MakeCodesList() {
            let list = []
            for (let i=1;i<=49;i++) {
                list.push({
                    pick: false,
                    name: i
                })
            }
            return list
        },
        MakeTailList() {
            let list = []
            for (let i=0;i<=9;i++) {
                list.push({
                    pick: false,
                    name: `${i}尾`
                })
            }
            return list
        },
        MakeZodiacList(disabled=false) {
            let list = []
            for (let i=12;i>=1;i--) {
                if (this.ZodiacList[i]) {
                    let zodiac = this.ZodiacList[i]
                    list.push({
                        pick: false,
                        name: zodiac,
                        disabled
                    })
                }
            }
            return list
        },
        // 合肖
        ToMergeChange(e) {
            if (e) {
                if (!this.CheckOdds(e)) {
                    return false
                }
                this.ToMerge.active = e
                this.List.forEach((pack, unit) => {
                    pack.child.forEach((item, index) => {
                        this.$set(item, 'pick', e.id===item.id)
                    })
                })
            }
            let length = parseInt(this.ToMerge.active.name)
            let arr = []
            for (let item of this.ToMerge.dir.child) {
                if (item.pick) {
                    arr.push(item.name)
                }
            }
            this.$emit('updateOpt', {key: 'customOrder', val: []})
            this.ToMerge.explain = `请选择 ${length} 项生肖`
            if (arr.length) {
                if (arr.length === length) {
                    this.ToMerge.explain = `选择完成`
                } else {
                    this.ToMerge.explain = `已选 ${arr.length} 项,还需选择 ${length - arr.length} 项`
                }
            }
            if (arr.length < length) {
                for (let item of this.ToMerge.dir.child) {
                    this.$set(item, 'disabled', false)
                }
                if (length === 6 && arr.length === 5) {
                    let JQZS = {
                        dir: {'马': 'JQ', '牛': 'JQ', '羊': 'JQ', '鸡': 'JQ', '狗': 'JQ', '猪': 'JQ', '鼠': 'ZS', '兔': 'ZS', '龙': 'ZS', '虎': 'ZS', '蛇': 'ZS', '猴': 'ZS'},
                        JQ: 0,
                        ZS: 0
                    }
                    // 六肖不中，不可全是单数或者全是双数
                    let mergeDir = {}
                    let pickList = []
                    for (let item of this.ToMerge.dir.child) {
                        let balls = this.QuickBetList[item.name]
                        mergeDir[item.name] = balls[0] % 2 === 0 ? 0 : 1
                        if (item.pick) {
                            JQZS[JQZS.dir[item.name]]++
                            pickList.push(item.name)
                        }
                    }
                    let count = 0
                    for (let name of pickList) {
                        count += mergeDir[name]
                    }
                    if (count === 5) {
                        for (let item of this.ToMerge.dir.child) {
                            if (!item.pick && mergeDir[item.name] === 1) {
                                this.$set(item, 'disabled', true)
                            }
                        }
                        this.ToMerge.explain += '，注意：六肖项目所选生肖不可全为单数或者全为双数'
                    } else if (count === 0) {
                        for (let item of this.ToMerge.dir.child) {
                            if (!item.pick && mergeDir[item.name] === 0) {
                                this.$set(item, 'disabled', true)
                            }
                        }
                        this.ToMerge.explain += '，注意：六肖项目所选生肖不可全为单数或者全为双数'
                    } else if (JQZS.JQ === 5) {
                        for (let item of this.ToMerge.dir.child) {
                            if (!item.pick && JQZS.dir[item.name] === 'JQ') {
                                this.$set(item, 'disabled', true)
                            }
                        }
                        this.ToMerge.explain += '，注意：六肖项目所选生肖不可全为家禽或全为走兽'
                    } else if (JQZS.ZS === 5) {
                        for (let item of this.ToMerge.dir.child) {
                            if (!item.pick && JQZS.dir[item.name] === 'ZS') {
                                this.$set(item, 'disabled', true)
                            }
                        }
                        this.ToMerge.explain += '，注意：六肖项目所选生肖不可全为家禽或全为走兽'
                    }
                }
            } else if (arr.length == length) {
                for (let item of this.ToMerge.dir.child) {
                    this.$set(item, 'disabled', !item.pick)
                }
                let pack = this.ToMerge.active
                let id = pack.id
                let odds = this.Running.play.odds[id] ? this.Running.play.odds[id].odds : 0
                return this.$emit('updateOpt', {key: 'customOrder', val: [
                    {
                        name: arr.join(','), belong: pack.belong, id: pack.id, code: arr.join(','), games_play_group_id: pack.games_play_group_id, odds
                    }
                ]})
            } else if (arr.length > length) {
                do {
                    let code = arr.pop()
                    for (let item of this.ToMerge.dir.child) {
                        if (item.name === code) {
                            this.$set(item, 'pick', false)
                        }
                    }
                } while (arr.length > length)
                for (let item of this.ToMerge.dir.child) {
                    this.$set(item, 'disabled', !item.pick)
                }
            }
        },
        // 连肖
        ToContactGroup(active) {
            if (active) {
                this.ToContact.active = active
            }
            this.$emit('reset')
            this.ToContact.explain = '请选择号码'
        },
        ToContactChange(e, length) {
            let mode = this.ToContact.mode
            let position = []
            let dir = {}
            let list = []
            e.child.forEach((sub, i) => {
                if (sub.pick) {
                    dir[sub.name] = true
                    position.push(sub.name)
                }
            })
            for (let i=0;i<this.ToContact.comb.length;i++) {
                let name = this.ToContact.comb[i]
                if (dir[name]) {
                    list.push(name)
                    dir[name] = false
                }
            }
            for (let name in dir) {
                if (dir[name]) {
                    list.push(name)
                }
            }
            this.$set(this.ToContact, 'comb', list)
            let info = this.Comb(mode, list, length, e, e.child)
            return this.ToContact.explain = info.msg
        },
        Comb(mode="连碰", position=[], length, item, list) {
            let oddsDir = {}
            for (let item of list) {
                let row = this.Running.play.odds[item.id]
                oddsDir[item.name] = row
            }

            let bets = 0
            let order=[], lock = [], comb=[];
            position.forEach((item, index) => {
                if (mode==='胆拖' && index<length-1) {
                    lock.push(item)
                } else {
                    comb.push(item)
                }
            })
            if (position.length >= length) {
                if (mode==='连碰') {
                    let arr = this.Combination(position, length)
                    bets = arr.length
                    let code = position.join(',')
                    for (let bet of arr) {
                        order.push({
                            name: bet, belong: item.belong ? item.belong : item.title, id: item.id, code: bet, games_play_group_id: item.games_play_group_id,
                            odds: CalcuOdds(bet), max_odds: CalcuOdds(bet, 'max_odds')
                        })
                    }
                }
                if (mode==='胆拖') {
                    let code = position.join(',')
                    bets = position.length - length + 1
                    let arr = []
                    for (let num of comb) {
                        arr.push(`${lock.join(',')},${num}`)
                    }
                    for (let bet of arr) {
                        order.push({
                            name: bet, belong: item.belong ? item.belong : item.title, id: item.id, code: bet, games_play_group_id: item.games_play_group_id,
                            odds: CalcuOdds(bet), max_odds: CalcuOdds(bet, 'max_odds')
                        })
                    }
                }

                this.$emit('updateOpt', {key: 'customOrder', val: order})
            } else {
                this.$emit('updateOpt', {key: 'customOrder', val: []})
            }
            if (mode==='连碰') {
                if (list && list.length) {
                    list.forEach((item, index) => {
                        if (!item.pick) {
                            this.$set(item, 'disabled', position.length >= 12)
                        }
                    })
                }
            }
            if (mode==='胆拖') {
                if (list && list.length) {
                    let lockobj = {}
                    for (let code of lock) {
                        lockobj[code] = true
                    }
                    list.forEach((item, index) => {
                        if (position.length >= length && lockobj[item.name]) {
                            this.$set(item, 'disabled', true)
                        } else {
                            this.$set(item, 'disabled', false)
                        }
                    })
                }
            }
            function CalcuOdds(codes, name='odds') {
                let odds = null
                for (let code of codes.split(',')) {
                    let coderow = oddsDir[code]
                    if (!odds || odds > coderow[name]) {
                        odds = coderow[name]
                    }
                    if (name === 'max_odds' && !oddsDir[code].has_double_odds) {
                        odds = undefined
                    }
                }
                return odds
            }
            return {
                order, mode, position, bets, lock, comb,
                msg: position.length ? `${mode}号码 ${lock.length ? `[${lock.join(',')}] + ` : ''}[${comb.join(',')}]，共组成 ${bets} 笔` : '请选择号码'
            }
        },
        Combination(data, length) {
            let list = this.CombinationFun(data)
            let work = []
            for (let item of list) {
                if (item && item.split(',').length === length) {
                    work.push(item)
                }
            }
            return work
        },
        CombinationFun(data, index=0, group=[]) {
            let need_apply = new Array()
            need_apply.push(data[index])
            for (let i=0;i<group.length;i++) {
                need_apply.push(group[i]+','+data[index])
            }
            group.push.apply(group, need_apply)
            if (index+1>=data.length) {
                return group
            } else {
                return this.CombinationFun(data, index + 1, group)
            }
        },
        CheckOpt() {
            let item = this.Running.nav.item
            if (item && item.title) {
                for (let title of ['连码', '连肖', '连尾', '合肖', '自选不中', '过关']) {
                    if (item.title == title) {
                        console.log(`disabledQuick: true, onlyQuick: true`)
                        this.$emit('updateOpt', {key: 'disabledQuick', val: true})
                        this.$emit('updateOpt', {key: 'onlyQuick', val: true})
                    }
                }
            }
        },
        CheckOdds(e) {
            if (e && e.id) {
                if (e.odds) {
                    return true
                }
                let {id} = e
                let row = this.Running.play.odds[id]
                if (row && row.odds) {
                    return true
                }
            }
            this.$set(e, 'pick', false)
            return false
        },
        Span(len) {
            return this.Big(24).div(len).round(0, 1).toNumber()
        }
    },
    components: {
        Category, Row
    }
}