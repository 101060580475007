import dgx from '~/dgx_z/index.js';
import ajax from '@lottery.2.0/library/ajax.js';

const store = 'myRecord';
const STORE = store.toUpperCase();

export default dgx({
    store,
    state: {
	        order: {
	        	title: '游戏记录',
	            _options: {
	                url: 'games/order'
	            }
	        },
	        ordersum: {
	        	title: '游戏记录统计',
	            _options: {
	                url: 'games/order/summation'
	            }
	        },
	        follow: {
	        	title: '追号记录',
	            _options: {
	                url: 'follow'
	            }
	        },
	        income: {
	        	title: '充值记录',
	            _options: {
	                url: 'income'
	            }
	        },
	        expenses: {
	        	title: '提现记录',
	            _options: {
	                url: 'expenses'
	            }
	        },
	        bills: {
	        	title: '账变记录',
	            _options: {
	                url: 'bills'
	            }
	        },
	        history: {
	            title: '开奖记录',
	            _options: {
	                url: 'games/lottery'
	            }
	        }
    },
    actions: {

        	// SUM_ORDER({state, dispatch, commit}, params) {
	        //     return dispatch(`_${STORE}_AJAX`, {
	        //         method: 'GET',
	        //         url: `games/order/summation`,
	        //         params,
	        //     })
	        // },
        	// DETAIL_QUERY({state, dispatch, commit}, data) {
        	// 	commit('UPDATE',{name:'query',key:'editing',val:true})
	        //     return dispatch(`_${STORE}_AJAX`, {
	        //         method: 'POST',
	        //         url: `order/orderDetails`,
	        //         data,
	        //     }).then(res=>{
	        //     	if(res){
	        //     		commit('UPDATE',{name:'query',key:'editing',val:false})
	        //     		commit(`EXTEND`,{
	        //     			name : 'query',
	        //     			id : res.id,
	        //     			key : 'detail',
	        //     			val : res,
	        //     		})
	        //     	}
	        //     	return res
	        //     })
	        // },
        	// DETAIL_CHASE({state, dispatch, commit}, data) {
        	// 	commit('UPDATE',{name:'chase',key:'editing',val:true})
	        //     return dispatch(`_${STORE}_AJAX`, {
	        //         method: 'POST',
	        //         url: `info/betsFollowsDetails`,
	        //         data,
	        //     }).then(res=>{
	        //     	if(res){
	        //     		commit('UPDATE',{name:'chase',key:'editing',val:false})
	        //     		commit(`EXTEND`,{
	        //     			name : 'chase',
	        //     			id : res.sn,
	        //     			key : 'detail',
	        //     			val : res,
	        //     		})
	        //     	}
	        //     	return res
	        //     })
	        // },
    },
    mutations: {

    }
},
ajax
);
