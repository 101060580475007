
<template>
    <div class="OperateNavTable">
        复试组合
        <!--         <div class="l-flex o-mt" v-for="(pack,unit) in [[1,2,'','','']]">
            <template v-for="(item,index) in pack">
                <div v-if="index" style="padding:0 .2rem;"></div>
                <div class="l-flex-1">
                    <div v-if="item">
                        <Group :client="client" :list="Table[index+1]" :length="1"></Group>
                    </div>
                </div>
            </template>
        </div>
        <BoardFast :client="client" :group="TableAll" :step="true" :defaultStep="0.1"></BoardFast> -->
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateDpc16',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group
    }
}
</script>
