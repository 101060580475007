
<template>
    <div class="OperateNavTable">
        <div class="l-flex">
            <template v-for="(item,index) in ['一','二','三','四','五']">
                <div v-if="index" style="padding:0 .2rem;" />
                <div class="l-flex-1">
                    <div class="row-title c-text-c">第{{ item }}球</div>
                    <group :client="client" :list="Table[index * 3 + 1]" :length="1" />
                    <group :client="client" :list="Table[index * 3 + 2]" :length="1" />
                    <group :client="client" :list="Table[index * 3 + 3]" :length="1" />
                </div>
            </template>
        </div>
        <BoardFast :client="client" :group="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]" :step="true" :default-step="0.1" />
        <div class="l-flex o-mt-s">
            <template v-for="(item,index) in ['前','中','后']">
                <div v-if="index" style="padding:0 .2rem;" />
                <div class="l-flex-1">
                    <div class="row-title c-text-c">{{ item }}三</div>
                    <group :client="client" :list="Table[18 + index]" :length="1" />
                </div>
            </template>
            <div style="padding:0 .2rem;" />
            <div class="l-flex-1">
                <div class="row-title c-text-c">综合大小单双</div>
                <group :client="client" :list="Table[16]" :length="1" />
                <group :client="client" :list="Table[17]" :length="1" />
            </div>
        </div>
        <BoardFast :client="client" :group="[16,17,18,19,20]" :step="true" :default-step="0.1" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateSsc1',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group
    }
}
</script>
