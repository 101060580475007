import Vue from 'vue'
import axios from 'axios'

import {deepCopy, isObject, isArray} from '../helper.js'

import fnAttr    from './lib/attr'
import fnActive  from './lib/active'
import fnLoad  from './lib/load'
import fnOrder   from './lib/order'
import fnRESTful from './lib/RESTful'

import fnAjax    from './lib/ajax'
import fnCrud    from './lib/crud'

const CancelToken = axios.CancelToken

export default function(config={}, ajax, num) {

    // 接收配置参数
    let options = Object.assign({
        state: {},
        actions: {},
        mutations: {},
        getters: {}
    }, config)

    let store = options.store.toUpperCase()
    // 赋值全局变量
    options.state._ajax = []
    options.state._models = []
    // options.state._loading = false
    // options.state._editing = false
    options.state.common = {
        _options: {}
    }
    // 遍历所有 state 查找 dgx 模块并创建方法
    for (let model in options.state) {
        if (isObject(options.state[model]) && options.state[model]._options) {

            // 模块通用状态属性
            options.state._ajax[model] = 0
            options.state._models.push(model)
            //options.state[x] = fnAttr(options.state[x])
            fnAttr(options, model)
            // 全局语法糖
            // 传参 ： [model,action,data] or {model,action,data}
            // options.actions['_DGX_ACTION'] =  ({state, dispatch, commit},console=[])=>{
            //     let model, action, data;
            //     if(isArray(console) && console.length > 1){
            //         model  = console[0].toUpperCase()
            //         action = console[1].toUpperCase()
            //         data   = console[2]
            //         return dispatch(`_${action}_${model}`,data)
            //     }
            // }

            // 焦点属性 _ACTIVE_${MODEL}(query)
            // 传参 ：query or [query,db]
            // query : undefined or null 重置焦点  /  {key:val} 检索第一个匹配的字段  /  -1 0 x 查找指定索引（-1代表最后一个索引）
            fnActive(options, model);


            fnLoad(options, model);

            // 带有排序属性
            // 传参 ：sort or [sort,dataBase]
            fnOrder(options, model);

            // 备份重置对象
            options.state[model]._reset = deepCopy(options.state[model])

            // 带有 RESTful
            fnRESTful(options, model)
        }
    }

    // 发起请求 _${store}_AJAX`
    // 结束请求 _${store}_AJAX_FINISH
    // 取消请求 _${store}_AJAX_CANCEL
    // 数据更新 _${store}_AJAX_UPDATE
    fnAjax(options, ajax);

    // 数据添加 _${store}_ADD
    // 数据更新 _${store}_UPDATE
    // 数据移除 _${store}_REMOVE
    // 数据清空 _${store}_CLEAN
    // 重置模块 _${store}_RESET
    fnCrud(options);

    return {
        namespaced: true,
        ...options
    }
}
