
<template>
    <div class="OperateNavTable">
        <div v-for="(pack,unit) in [[1,'','','','']]" class="l-flex o-mb">
            <template v-for="(item,index) in pack">
                <div v-if="index" style="padding:0 .2rem;" />
                <div class="l-flex-1">
                    <group v-if="item" :client="client" :list="Table[item]" :length="1" />
                </div>
            </template>
        </div>
        <BoardFast :client="client" :group="TableAll" :step="true" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateSyw12',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group
    }
}
</script>
