
export default {
    computed: {
        Opt() {
            if (!this.User) {
                return {}
            }
            let opt = {
                ...this.$store.state.base.options
            }
            opt.isMobile = !!this.Config.mobile
            // 判断游戏模式
            opt.isCash = this.$store.state.base.options.platform_method === 'cash'
            opt.isCredit = this.$store.state.base.options.platform_method === 'credit'
            opt.isTeam = this.User ? this.User.user_type == 1 : false
            opt.isAdmin = opt.isTeam && this.User.is_admin
            opt.isSub =  this.User ? !!this.User.is_subset : false
            opt.isDev = this.Config.is_dev
            opt.isLocalhost = opt.isDev && !!~window.location.host.indexOf('localhost')
            opt.SubMenu = {}
            if (this.User && this.User.role_id) {
                opt.isTeam = true
                opt.isAdmin = true
            }
            if (opt.isSub) {
                opt.isSub = {}
            }
            if (this.User && this.User.menu) {
                for (let i of this.User.menu.split(',')) {
                    if (opt.isSub) {
                        opt.isSub[`${i}`] = true
                    }
                    opt.SubMenu[`${i}`] = true
                }
            }
            // 判断是否拥有游戏
            opt.hasDir = {
                type: [],
                game: [],
                typeById: {},
                typeByName: {},
                gameById: {},
                gameByName: {}
            }
            opt.typeById = {}
            opt.gameById = {}
            if (this.User) {

                let obj = {}
                if (this.User.has_games_type) {
                    for (let id of this.User.has_games_type.split(',')) {
                        obj[id] = true
                    }
                }
                for (let key in this.Category) {
                    let item = {
                        id: this.Category[key].id,
                        name: this.Category[key].code,
                        title: this.Category[key].title,
                        code: this.Category[key].code
                    }
                    if (!this.User.has_games_type || obj[item.id]) {
                        let bets = []
                        if (this.User[`${item.name}_has_bet`]) {
                            for (let bet of this.User[`${item.name}_has_bet`].split(',').sort()) {
                                if (typeof bet === 'string') {
                                    bets.push(bet.toUpperCase())
                                }
                            }
                        }
                        opt.hasDir.typeById[item.id] = opt.isCredit ? bets : true
                        opt.hasDir.typeByName[item.code] = opt.isCredit ? bets : true
                        opt.hasDir.type.push({...item})
                    }
                    opt.typeById[item.id] = {...item}
                }
                for (let key in this.Games) {
                    let item = {
                        id: this.Games[key].id,
                        name: this.Games[key].code,
                        title: this.Games[key].title,
                        type: this.Games[key].group,
                        info: this.Games[key].remote_data
                    }
                    if (!this.User.has_games_type || obj[this.Games[key].group]) {
                        opt.hasDir.gameById[this.Games[key].id] = true
                        opt.hasDir.gameByName[this.Games[key].code] = true
                        opt.hasDir.game.push({...item})
                    }
                    opt.gameById[item.id] = {...item}
                }

                if (opt.isCredit && opt.isTeam) {
                    opt.operate = {}
                    for (let item of opt.hasDir.type) {
                        opt.operate[item.name] = {
                            manipulation: !!this.User[`${item.name}_trader_status`],
                            transmit: !!this.User[`${item.name}_transfer_status`]
                        }
                        opt.operate[item.name].view = opt.operate[item.name].manipulation || opt.operate[item.name].transmit
                    }
                }
            }

            opt.subMenu = {
                pc: [
                    {
                        title: '个人总览',
                        icon: 'peoplefill',
                        color: '#FBA429',
                        link: 'my/account/overview',
                        enable: !(opt.isCredit && opt.isTeam)
                    },
                    {
                        title: '团队总览',
                        icon: 'peoplefill',
                        color: '#FBA429',
                        link: 'my/team/statis',
                        enable: opt.isCredit && opt.isTeam
                    },
                    {
                        title: '游戏记录',
                        icon: 'formfill',
                        color: '#EE4841',
                        link: 'my/record/query',
                        enable: true
                    },
                    {
                        title: '追彩查询',
                        icon: 'favorfill',
                        color: '#22A1EC',
                        link: 'my/record/chase',
                        enable: true
                    },
                    {
                        title: '安全中心',
                        icon: 'iconfontanquan',
                        color: '#F95178',
                        link: 'my/account/info',
                        enable: true
                    },
                    {
                        title: '账变记录',
                        icon: 'moneybagfill',
                        color: '#8E22FD',
                        link: 'my/record/account',
                        enable: true
                    }
                ],
                mobile: []
            }

            opt.routerAuthDir = {
                '1': {title: '操盘', view: true, enable: true},
                '2': {title: '走飞', view: true, enable: true},
                '3': {title: '账号管理', view: true, enable: true},
                '4': {title: '新增帐号', view: true, enable: true},
                '5': {title: '修改帐号', view: true, enable: true},
                '6': {title: '报表', view: true, enable: true},
                '7': {title: '开奖', view: opt.isAdmin, enable: true},
                '9': {title: '系统', view: opt.isAdmin, enable: true},
                '10': {title: '代理设置', view: !opt.isAdmin, enable: true},
                '11': {title: '注单备份', view: true, enable: true}
            }

            return opt
        }
    }
}