import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'Sign',
    mixins: [StoreMix],
    data() {
        return {
            store: 'base/line',
            isDev: process.env.NODE_ENV === 'development',
            params: {
                username: '', // 用户名
                pass: '', // 密码

                code: '1234', // 验证码
                key: null,

                re_pass_word: '', // 确认密码
                email: '', // 邮箱
                mobile: '', // 手机号

                qq: '', // QQ
                recommend_code: '',
                recommend_name: ''
            },
            type: 'signin',
            time: null,
            verify: {
                check: false,
                image: null,
                loading: false
            }
        }
    },
    computed: {
        canGetCode() {
            return this.checkPhone(this.params.username)
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.GetKey()
        },
        submit() {
            if (this[`submit_${this.type}`]) {
                this[`submit_${this.type}`]()
            }
        },
        submit_signin() {
            return this.$store.dispatch('base/BASE_SIGNIN', this.params).then(res => {
                this.Toast('登录成功');
                // 存储数据
                localStorage.setItem('fantantoken', res.token);
                this.Cache('token', res.token);
                return this.Login(true)
            }, err => {
                this.params.pass = null
                this.imgVerifyLoad()
            })
        },
        submit_signup() {
            return this.$store.dispatch('base/BASE_SIGNUP', 'this.params').then(res => {
                return this.typeChange('signin')
            })
        },
        submit_forget() {

        },
        // 切换：登录、注册、忘记密码
        typeChange(type = 'signin') {
            this.type = type;
            this.DataReset('params');
            this.imgVerifyLoad()
        },
        imgVerifyLoad() {
            this.params.key = null
            if (this.$refs['verify']) {
                this.verify.check = false
                this.verify.loading = true
                this.Dp('base/IMAGE_VERIFY').then(res => {
                    this.verify.loading = false
                    this.verify.image = res.data
                    this.$refs['verify'].reset()
                })
            }
        }
    }
}
