
import Methods from '~/methods';
import axios from 'axios'
// import store from '@/Z_LOTTERY_NEW/store/index'
import Storage from '~/localstorage'
import Config  from './config'
let CancelToken = axios.CancelToken

const isDev = window.location.protocol === "file:" ||  window.location.hostname === "localhost"
const ajax = axios.create({
    baseURL: Config.base,
    timeout: 180000,
    // withCredentials: true,
    // 转换为 from-data 格式
    transformRequest: [function(data) {
        if (typeof data === 'object') {
            let ret = ''
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            return ret
        }
        return data
    }]
})

// 添加请求拦截器
ajax.interceptors.request.use( config => {
    if (!config.data) {
        config.data = {}
    }
    // 添加 token
    let token = Methods.Cache('token')
    if (token) {
        config.headers['token'] = token
    }
    return config
}, error => {
    window.App.Log('请求拦截器：请求错误', error)
    return Promise.reject(error);
})


// 添加响应拦截器
ajax.interceptors.response.use( res => {
    if (res && res.status==200) {
        // res.data.status = 501
        // res.data.msg = '系统维护中，维护时间：123'
        switch (res.data.status) {
            case 0:
                window.App.Err(res.data.msg, '请求失败')
                break;
            case 1:
                return res
            case 401:
                Methods.Cache('token', null)
                window.App.$router.replace({name: 'login'})
                break;
            case 501:
                window.App.$router.replace({name: 'maintain', params: {msg: res.data.msg}})
                return res
            default:
                window.App.Err('请求失败', res.data.msg)
        }

    }
    window.App.Log(res, '响应拦截器：服务端错误');
    return Promise.reject(res)
}, (error) => {
    window.App.Log('响应拦截器：本地网络错误，或服务器无响应', error)
    return Promise.reject({
        data: {
            err: 500,
            data: null,
            msg: `${error.name ? error.name + ':' : ''}${error.message}`
        }
    })
})

let count = 0
export default function(config) {

    // 计数器
    count ++
    // 取消令牌
    let cancel
    let cancelToken = new CancelToken( fun => {
        cancel = fun
        if (config.getCancel) {
            config.getCancel(count, cancel)
        }
    })
    // 返回封装后的 ajax 对象
    return ajax({
        validateStatus: status => {
            return status >= 200 && status < 600
        },
        id: count,
        cancelToken,
        cancel,
        ...config
    })
}