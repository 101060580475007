<style lang="scss">
    .OperateLhc-9 {
        h6.title {
            padding:3px 5px; text-align:center; background-color:#f3655e; color:#fff;
        }
    }
</style>
<template>
    <div class="OperateLhc-9">
        <div class="l-flex">
            <div class="l-flex-1 o-pr-s">
                <group :client="client" :list="Table[1]" :length="1" :radio="true" :transmit="false" show-total="end" @pick="AloneTransfer" />
                <group :client="client" :list="Table[2]" :length="1" :radio="true" :transmit="false" show-total="end" class="o-mt" @pick="AloneTransfer" />
                <group :client="client" :list="Table[3]" :length="1" :radio="true" :transmit="false" show-total="end" class="o-mt" @pick="AloneTransfer" />
            </div>
            <div class="l-flex-1 o-pr-s">
                <group :client="client" :list="Table[4]" :length="1" :radio="true" :transmit="false" show-total="end" @pick="AloneTransfer" />
                <group :client="client" :list="Table[5]" :length="1" :radio="true" :transmit="false" show-total="end" class="o-mt" @pick="AloneTransfer" />
                <group :client="client" :list="Table[6]" :length="1" :radio="true" :transmit="false" show-total="end" class="o-mt" @pick="AloneTransfer" />
            </div>
            <div class="l-flex-1 o-pr-s">
                <group :client="client" :list="Table[7]" :length="1" :radio="true" :transmit="false" show-total="end" @pick="AloneTransfer" />
                <group :client="client" :list="Table[8]" :length="1" :radio="true" :transmit="false" show-total="end" class="o-mt" @pick="AloneTransfer" />
                <group :client="client" :list="Table[9]" :length="1" :radio="true" :transmit="false" show-total="end" class="o-mt" @pick="AloneTransfer" />
            </div>
            <div class="l-flex-1 o-pr-s">
                <group :client="client" :list="Table[10]" :length="1" :radio="true" :transmit="false" show-total="end" @pick="AloneTransfer" />
                <group :client="client" :list="Table[11]" :length="1" :radio="true" :transmit="false" show-total="end" class="o-mt" @pick="AloneTransfer" />
            </div>
            <div class="l-flex-1 o-pr-s" />
        </div>
        <BoardFast :client="client" :group="[1,2,3,4,5,6,7,8,9,10,11]" :step="true" transmit="six-zodiac" :radio="true" :limit="limit" :default-step="0.01" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateLhc9',
    mixins: [CommonMix],
    data() {
        return {
            limit: undefined
        }
    },
    props: {
        client: {}
    },
    computed: {

    },
    methods: {
        details(pack) {
            let {item, row} = pack
            this.Details(item, row)
        }
    },
    components: {
        Group
    }
}
</script>
