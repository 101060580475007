
export default {
    data() {
        return {
            lotteryNow: null, // 当前期数号
            lotteryLock: null, // 当前期数 ID
            lotteryAuto: false,

            checkView: false, // 下注确认页面

            side: null, // 当前盘口 id

            chipinState: 0, // 投注状态，影响弹窗 - 0:等待确认  1:赔率变动  2:投注成功
            chipinSubmitTotal: {count: 0, total: 0},
            orderEditing: false // 注单编辑
        }
    },
    computed: {
        // 玩法数据库
        DB() {
            let obj = {}
            const NextLevel = (list) => {
                for (let item of list) {
                    if (item.child) {
                        NextLevel(item.child)
                    } else {
                        obj[item.id] = item
                    }
                }
            }
            NextLevel(this.List)
            return obj
        },
        // 期数状态提示
        CountDown() {
            let expect = ''
            if (this.Running.model != 7) {
                try {
                    expect = ` ${this.Running.expect.now.expect}期 `
                } catch (err) {}
            }
            let close = this.Running.expect.count.close
            let end = this.Running.expect.count.end
            let count = close > 0 ? close : 0
            let states = 0
            return {
                states,
                prompt: {
                    '0': `离${expect}截至下注时间`
                }[states],
                format: this.CountDownFormat(count)
            }
        },
        // 当前玩法拥有盘类
        hasSide() {
            let type = this.Running.group
            let sides = this.Opt.hasDir.typeById[type]
            try {
                let list = []
                for (let item of this.Running.nav.table) {
                    for (let side of sides) {
                        if (item.title[0] === side) {
                            list.push(item)
                        }
                    }
                }
                return list
            } catch (err) {
                return []
            }
        },
        // 确认下注注单金额统计
        calcuCart() {
            let total = 0
            for (let item of this.Cart) {
                let amount = item.amount ? parseInt(item.amount) : 0
                total = this.Big(total).add(amount).round(4, 3).toNumber()
            }
            return {
                count: this.Cart.length,
                total
            }
        }
    },
    watch: {
        'Running.nav.side.id'(val) {
            if (val) {
                this.side = val
            }
        }
    },
    methods: {
        init() {
            this.Reset(true, true)
        },
        Reset(cart, opt) {

        },

        // 切换盘口
        NavTable(e, nav) {
            for (let item of this.hasSide) {
                if (item.id === e) {
                    this.Dp('game/NAV_TABLE', item)
                    return this.Dp('game/GOODS_RELOAD', {id: item.id, nav})
                }
            }
        },

        // 用户点击确认下单
        Checkout() {
            if (!this.Running.expect.now.id) {
                return this.Err('无可下注期数')
            }
            if (this.Running.expect.now.status == 1) {
                return this.Err('本期注单已开奖，停止下注')
            }
            if (this.checkView) {
                return this.Submit()
            }
            let type = this.opt.onlyQuick ? 2 : this.opt.disabledQuick ? 1 : this.type
            if (type == 2 && !this.amount) {
                return this.Err('请输入下注金额')
            }
            let cart = this.CheckList()
            if (!cart.length) {
                return this.Err('请选择号码')
            }
            let validCart = []
            for (let item of cart) {
                if (item.odds) {
                    validCart.push(item) // 如果赔率未被加载完全，则不会触发下续操作
                }
            }
            if (!validCart.length) {
                this.Reset()
                return false
            }
            this.$set(this, 'Cart', validCart)
            this.Reset()
            this.lotteryNow = this.Running.expect.now.expect
            this.lotteryLock = this.Running.expect.now.id
            this.chipinState = 0 // 投注状态; 0 = 待命; 1 = 赔率有变需要修改; 2 = 投注成功
            this.checkView = true // 投注状态窗
            //this.orderEditing = false
        },
        // 检查购物车列表
        CheckList() {
            let cart = []
            let type = this.opt.onlyQuick ? 2 : this.opt.disabledQuick ? 1 : this.type
            if (this.opt.customOrder) {
                for (let item of this.opt.customOrder) {
                    let amount = type == 1 ? item.amount : this.amount
                    cart.push({
                        ...item,
                        // rebate : this.rebate,
                        amount
                    })
                }
            } else {
                let oddsDB = this.Running.play.odds
                for (let pack of this.List) {
                    for (let item of pack.child) {
                        let id = item.play_id || item.id
                        let data = this.DB[id]
                        // 一般下注                          快捷下注

                        if (type==1 && item.amount || type==2 && item.pick) {
                            let amount = type == 1 ? item.amount : this.amount
                            if (!amount) {
                                amount = 0
                            }
                            cart.push({
                                name: item.name || item.code,
                                belong: item.belong,
                                games_play_group_id: item.games_play_group_id,
                                ...data,
                                id,
                                amount,
                                odds: oddsDB && oddsDB[id] ? oddsDB[id].odds : null,
                                max_odds: oddsDB && oddsDB[id] && oddsDB[id].has_double_odds ? oddsDB[id].max_odds : null,
                                code: item.code || undefined
                            })
                        }
                    }
                }
            }
            return cart
        },
        Submit() {
            if (this.chipinState === 2) {
                return this.checkView = false
            }
            if (this.orderEditing || !this.Cart.length) {
                return false
            }
            if (!this.calcuCart.total) {
                return this.Err('请输入下注金额')
            }
            let odds = null
            let list = []
            let cart = this.Origin(this.Cart)
            this.$set(this, 'Cart', [])
            for (let item of cart) {
                if (!item.success) {
                    list.push({
                        games_id: this.Running.id,
                        games_play_id: item.id ? item.id : this.template.games_play_id,
                        games_play_group_id: item.games_play_group_id,
                        odds: odds ? odds : item.odds,
                        max_odds: item.max_odds,
                        code: item.code || item.name,
                        mode: 4,
                        multiple: 1,
                        lottery_id: this.lotteryLock,
                        money: item.amount
                    })
                }
            }
            this.orderEditing = true
            return this.$store.dispatch('game/POST_ORDER', {
                data: JSON.stringify(list),
                games_id: this.Running.id,
                side: this.Running.nav.side.id
            }).then(res => {
                this.orderEditing = false
                this.Dp('base/BASE_USER')
                if (!res || !res.length) {
                    try {
                        this.$refs['history'].Reload()
                    } catch (err) {}
                    // this.checkView = false
                    this.Reset()
                    // this.Suc('操作成功')
                    // this.chipinStep = 1
                    this.chipinState = 2
                    this.chipinSubmitTotal.total = 0
                    this.chipinSubmitTotal.count = 0
                    for (let item of cart) {
                        this.chipinSubmitTotal.total = this.Big(this.chipinSubmitTotal.total).add(item.amount ? parseInt(item.amount) : 0).round(4, 3).toNumber()
                    }
                    this.chipinSubmitTotal.count = cart.length
                } else if (res.length) {
                    this.chipinState = 1
                    let succount = 0
                    let obj = {}
                    let _cart =[]
                    for (let item of res) {
                        obj[item.id] = item
                    }
                    for (let item of cart) {
                        let id = item.id || item.games_play_id
                        if (obj[id]) {
                            _cart.push({
                                ...item,
                                old_odds: item.odds,
                                odds: obj[id].odds,
                                old_max_odds: item.max_odds,
                                max_odds: obj[id].max_odds
                            })
                        } else {
                            _cart.push({
                                ...item, success: true
                            })
                            succount++
                        }
                    }
                    this.$set(this, 'Cart', _cart)
                    // this.Suc(`有 ${succount} 项注单提交成功，部分注单赔率变动请重新确认！`,'warning')
                }
                return res
            }, err => {
                this.$set(this, 'Cart', cart)
                this.orderEditing = false
                this.Dp('base/BASE_USER')
                return err
            })
        },

        // 期数格式化
        CountDownFormat(count = 0) {
            let format = [0, 0, 0]
            for (let i = 0; i < format.length; i++) {
                let unit = [3600, 60, 1][i]
                format[i] = Math.floor(count / unit).toString()
                if (format[i].length < 2) {
                    format[i] = `0${format[i]}`
                }
                count = count > unit ? count % unit : count
            }
            return format
        }
    }
}