
<template>
    <div class="OperateNavTable">
        <group :client="client" :list="Table[1]" :length="5" />
        <BoardFast :client="client" :group="[1]" :step="true" :default-step="0.1" />
        <group :client="client" :list="Table[2]" :length="5" />
        <BoardFast :client="client" :group="[2]" :step="true" :default-step="0.1" />
        <div v-for="(pack,unit) in [['佰拾个和数OOO','佰拾个和数尾数','','','']]" class="l-flex o-mt">
            <template v-for="(item,index) in pack">
                <div v-if="index" style="padding:0 .2rem;" />
                <div class="l-flex-1">
                    <div v-if="item">
                        <div class="row-title c-text-c">{{ item }}</div>
                        <group :client="client" :list="Table[index * 2 + 3]" :length="1" />
                        <group :client="client" :list="Table[index * 2 + 4]" :length="1" />
                    </div>
                </div>
            </template>
        </div>
        <BoardFast :client="client" :group="[3,4,5,6]" :step="true" :default-step="0.1" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateDpc13',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group
    }
}
</script>
