
export default {
    data() {
        return {
            category: {
                list: ['特码', '合肖', '正码', '正码特', '一肖尾数', '连码', '自选不中', '连尾', '全部'],
                totle: {},
                active: '特码'
            }
        }
    },
    computed: {
        Base() {
            if (this.$store.state.base.options) {
                return this.$store.state.base.options
            }
            return {}
        },
        ZodiacList() {
            let zodiac = {}
            let dir = this.Base.chinese_zodiac.split(',').reverse()
            for (let i=1;i<=12;i++) {
                zodiac[i] = dir[i-1]
            }
            return zodiac
        },
        QuickBetList() {
            let list = {
                '大': [], '小': [], '单': [], '双': [],
                '大单': [], '小单': [], '合单': [], '大双': [], '小双': [], '合双': [],
                '家禽': [], '走兽': [], '野兽': [],
                '红波': [], '蓝波': [], '绿波': [],
                '尾单': [], '尾双': [], '尾大': [], '尾小': [], '特尾大': [], '特尾小': [], '合尾大': [], '合尾小': []
            }

            let zodiac = this.CodeZodiac()
            let color = this.CodeColor()
            for (let code=1;code<=49;code++) {

                // 数字类型
                if (code != 49) {
                    code >= 25 ? list['大'].push(code) : list['小'].push(code)
                    code % 2 == 1 ? list['单'].push(code) : list['双'].push(code)

                    let merge = 0;
                    for (let num of `${code}`) {
                        merge+=Number(num)
                    }
                    merge % 2 == 1 ? list['合单'].push(code) : list['合双'].push(code)
                }
                // 生肖
                let zc = zodiac[code]
                if (!list[zc]) {
                    list[zc] = []
                }
                list[zc].push(code)
                zc=='马'||zc=='牛'||zc=='羊'||zc=='鸡'||zc=='狗'||zc=='猪' ? list['家禽'].push(code) : list['走兽'].push(code)

                // 颜色
                let cc = {'red': '红波', 'blue': '蓝波', 'green': '绿波'}[color[code]]
                list[cc].push(code)

                // 尾
                let tail = parseInt(code % 10)
                if (!list[`${tail}尾`]) {
                    list[`${tail}尾`] = []
                }
                list[`${tail}尾`].push(code)
                // 头
                let head = code < 10 ? '0' : `${code}`[0]
                if (!list[`${head}头`]) {
                    list[`${head}头`] = []
                }
                list[`${head}头`].push(code)


                //  尾大小
                if (code != 49) {
                    if (code<10) {
                        code % 2 == 1 ? list['尾单'].push(code) : list['尾双'].push(code)
                    } else {
                        let tail = code % 10
                        tail % 2 == 1 ? list['尾单'].push(code) : list['尾双'].push(code)
                    }

                    if (code<10) {
                        code >= 5 ? list['尾大'].push(code) : list['尾小'].push(code)
                        code >= 5 ? list['特尾大'].push(code) : list['特尾小'].push(code)
                    } else {
                        let tail = code % 10
                        tail >= 5 && tail < 10 ? list['尾大'].push(code) : list['尾小'].push(code)
                        tail >= 5 && tail < 10 ? list['特尾大'].push(code) : list['特尾小'].push(code)
                    }
                }
                if (code != 25) {
                    if (code<10) {
                        code >= 5 ? list['合尾大'].push(code) : list['合尾小'].push(code)
                    } else {
                        let tail = (Number(`${code}`[0]) + Number(`${code}`[1])) % 10
                        tail >= 5 && tail <= 9 ? list['合尾大'].push(code) : list['合尾小'].push(code)
                    }
                }
            }

            for (let item of ['红', '蓝', '绿']) {
                list[`${item}大`] = []
                list[`${item}小`] = []
                list[`${item}单`] = []
                list[`${item}双`] = []
                for (let code of list[`${item}波`]) {
                    code >= 25 ? list[`${item}大`].push(code) : list[`${item}小`].push(code)
                    code % 2 == 1 ? list[`${item}单`].push(code) : list[`${item}双`].push(code)
                }
            }
            for (let item of ['大', '小']) {
                for (let code of list[item]) {
                    code % 2 == 1 ? list[`${item}单`].push(code) : list[`${item}双`].push(code)
                }
            }
            for (let item of list['走兽']) {
                list['野兽'].push(item)
            }
            return list
        }
    },
    methods: {

        // 球号数据
        CodeInfo(code) {
            let color = this.CodeColor(code)
            let zodiac = this.CodeZodiac(code)
            return {
                color, zodiac,
                size: code >= 25 ? 'big' : 'small',
                parity: code % 2 == 1 ? 'odd' : 'even'
            }
        },
        // 计算号码颜色 & 返回所有号码颜色列表
        CodeColor(code) {
            if (code && typeof code === 'string') {
                if (code[0]==='红') {return 'red'}
                if (code[0]==='蓝') {return 'blue'}
                if (code[0]==='绿') {return 'green'}
            }
            let dir = {}
            for (let pack of [{key: 'lhc_red_wave', val: 'red'}, {key: 'lhc_blue_wave', val: 'blue'}, {key: 'lhc_green_wave', val: 'green'}]) {
                for (let item of this.Base[pack.key].split(',')) {
                    dir[item] = pack.val
                }
            }
            return code ? dir[code] : dir
        },
        CodeColorGroup() {
            let obj = {}
            let list = this.CodeColor()
            for (let code in list) {
                if (!obj[list[code]]) {
                    obj[list[code]] = []
                }
                obj[list[code]].push(code)
            }
            return obj
        },
        // 计算号码生肖 & 返回所有号码生肖列表
        CodeZodiac(code) {
            let rule = this.ZodiacList
            if (code) {
                let num = parseInt(code)
                try {
                    return rule[num%12] || rule[12]
                } catch (err) {
                    return null
                }
            } else {
                let obj = {}
                for (let code=1;code<=49;code++) {
                    obj[code] = rule[code%12] || rule[12]
                }
                return obj
            }
        }
    },
    mounted() {

    }
}