<style lang="scss">
    @import "../../../style/config.scss";
    .OperateBoardFast {
        .six-balls {
            text-align:center;
            .six-nav {
                background-color:#eee; padding:4px 0;
            }
            .balls{
                height:34px; line-height:34px;
                .el-col {
                    height:34px;
                }
                .ball {
                    width:24px; height:24px; margin-top:3px; margin-left:1px; margin-right:1px; line-height:24px; background-color:$color-t; color:#fff; display:inline-block; border-radius:99px; text-align:center;
                    &.red {background-color:#ec4d4d!important;}
                    &.blue {background-color:#5f79ef!important;}
                    &.green {background-color:#4a9a34!important;}
                }
            }
        }
        .el-input-group__append, .el-input-group__prepend {
            padding:0 10px;
        }
        .el-input__inner {
            padding:0 10px;
        }
    }
</style>
<template>
    <div class="OperateBoardFast">
        <div class="board o-pt-s">
            <div class="l-flex-c">
                <div class="l-flex-1">
                    <div v-if="client.mode && !radio" class="fast l-flex-c">
                        <h6 class="o-pl" style="width:4rem;">快速选择</h6>
                        <div class="l-flex-1">
                            <template v-if="list">
                                <ul v-for="row in list">
                                    <li v-for="(pack,unit) in row">
                                        <span v-if="unit" class="division" />
                                        <a v-for="(item,index) in pack" :class="{'active': client.board.fast.active[item]}" @click="BoardFast(item)">{{ item }}</a>
                                    </li>
                                </ul>
                            </template>
                            <template v-else>
                                <ul v-for="row in Table">
                                    <li v-for="(pack,unit) in row">
                                        <span v-if="unit" class="division" />
                                        <a v-for="(item,index) in pack" :class="{'active': active[item]}" @click="BoardFast(item)">{{ item }}</a>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </div>
                    <div v-if="OperateAuth.bind == 2 && OperateAuth.manipulation" class="step o-pt-s l-flex-c">
                        <h6 class="o-pl" style="width:4rem;">快速操盘</h6>
                        <ul class="l-flex-1">
                            <li>
                                <a v-for="(item,index) in FastBoardStep.list" :class="{'active': FastBoardStep.active === item}" @click="FastBoardStep.active = item">{{ item }}</a>
                                <span class="division" />
                                <input v-model.number="FastBoardStep.active" class="o-mr-s mini">
                                <span v-if="step"><button @click="RebateSet(null,'+')">增加</button></span>
                                <span v-if="step"><button @click="RebateSet(null,'-')">减少</button></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="OperateAuth.bind == 3 && OperateAuth.transmit" class="l-flex-c">
                    <template v-if="OperateAuth.admin && OperateAuth.mode == 0">
                        <div>
                            <div>
                                <span>赔率:</span>
                                <el-input v-model="odds" size="mini" style="width:70px;" />
                                <template v-if="double">
                                    <span class="o-pl-s">最大赔率:</span>
                                    <el-input v-model="max_odds" size="mini" style="width:70px;" />
                                </template>
                                <span class="o-pl-s">退水:</span>
                                <el-input v-model="backwater_percent" size="mini" style="width:70px;" />
                                <span class="o-pl-s">金额:</span>
                                <el-input v-model="transmit_amount" size="mini" style="width:80px;" />
                            </div>
                            <div class="o-pt c-text-r">
                                <span>站点：</span>
                                <el-select v-model="web_name" placeholder="请选择站点" size="mini" style="width:140px;">
                                    <el-option v-for="(item,index) in tsite._list" :key="item.id" :value="item.name">{{ item.name }}</el-option>
                                </el-select>
                                <span class="o-pr" />
                                <Button size="mini" @click="TransmitSubmit()">走飞</Button>
                                <Button size="mini" @click="BoardFast(null)">取消</Button>

                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="l-flex-c">
                            <el-input v-if="transmit === 'normal' || transmit === 'integration'" v-model.number="capacity" size="mini" style="width:7.5rem;"><template slot="prepend">风险值</template></el-input>
                            <span class="o-pl" />
                            <el-input v-model.number="transmit_amount" size="mini" style="width:7.5rem;"><template slot="prepend">走飞金额</template></el-input>
                            <span class="o-pr" />
                            <span v-if="!OperateAuth.transfer_status" class="o-pl-s c-color-e c-text-6">(走飞功能已被上级关闭)</span>
                            <div v-else style="width:110px;">
                                <Button size="mini" @click="TransmitSubmit()">走飞</Button>
                                <Button size="mini" @click="BoardFast(null)">取消</Button>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <template v-if="OperateAuth.bind == 3 && OperateAuth.transmit">
                <div class="step o-mt-s o-pt-s">
                    <template v-if="transmit !== 'normal'">
                        <div v-if="transmit === 'six-balls'" class="six-balls o-pr-l">
                            <el-checkbox-group v-model="FastBox['sixballs']">
                                <el-row tag="div" :gutter="10">
                                    <el-col v-for="(pack,unit) in 10" :key="unit" tag="div" class="list" :span="2">
                                        <el-row>
                                            <el-col tag="div" class="c-text-7 six-nav" :span="12">号码</el-col>
                                            <el-col tag="div" class="c-text-7 six-nav" :span="12">选择</el-col>
                                        </el-row>
                                        <ul v-for="(item,index) in 5" v-if="(pack - 1) * 5 + item <= 49" class="balls">
                                            <el-row>
                                                <el-col :span="12">
                                                    <span class="ball c-text-7" :class="CodeColor((pack - 1) * 5 + item)">{{ FillNumber((pack - 1) * 5 + item) }}</span>
                                                </el-col>
                                                <el-col :span="12">
                                                    <el-checkbox :label="FillNumber((pack - 1) * 5 + item)"><span /></el-checkbox>
                                                </el-col>
                                            </el-row>
                                        </ul>
                                    </el-col>
                                </el-row>
                            </el-checkbox-group>
                        </div>
                        <div v-if="transmit === 'six-zodiac'" class="six-balls o-pr-l">
                            <el-checkbox-group v-model="FastBox['sixballs']">
                                <el-row tag="div" :gutter="10">
                                    <el-col v-for="(pack,unit) in [['鼠','牛','虎'],['兔','龙','蛇'],['马','羊','猴'],['鸡','狗','猪']]" :key="unit" tag="div" class="list" :span="6">
                                        <el-row>
                                            <el-col tag="div" class="c-text-7 six-nav" :span="4">生肖</el-col>
                                            <el-col tag="div" class="c-text-7 six-nav" :span="16">彩球</el-col>
                                            <el-col tag="div" class="c-text-7 six-nav" :span="4">选择</el-col>
                                        </el-row>
                                        <ul v-for="(item,index) in pack" class="balls">
                                            <el-row>
                                                <el-col :span="4">
                                                    <span class="text c-text-7">{{ item }}</span>
                                                </el-col>
                                                <el-col :span="16">
                                                    <template v-if="QuickBetList && QuickBetList[item]">
                                                        <span v-for="(ball,i) in QuickBetList[item]" :key="i" class="ball c-text-7" :class="CodeColor(ball)">{{ ball }}</span>
                                                    </template>
                                                </el-col>
                                                <el-col :span="4">
                                                    <el-checkbox :label="item"><span /></el-checkbox>
                                                </el-col>
                                            </el-row>
                                        </ul>
                                    </el-col>
                                </el-row>
                            </el-checkbox-group>
                        </div>
                        <div v-if="transmit === 'six-tail'" class="six-balls o-pr-l">
                            <el-checkbox-group v-model="FastBox['sixballs']">
                                <el-row tag="div" :gutter="10">
                                    <el-col v-for="(pack,unit) in [['0尾','1尾','2尾'],['3尾','4尾','5尾'],['6尾','7尾','8尾'],['9尾']]" :key="unit" tag="div" class="list" :span="6">
                                        <el-row>
                                            <el-col tag="div" class="c-text-7 six-nav" :span="4">尾数</el-col>
                                            <el-col tag="div" class="c-text-7 six-nav" :span="16">彩球</el-col>
                                            <el-col tag="div" class="c-text-7 six-nav" :span="4">选择</el-col>
                                        </el-row>
                                        <ul v-for="(item,index) in pack" class="balls">
                                            <el-row>
                                                <el-col :span="4">
                                                    <span class="text c-text-7">{{ item }}</span>
                                                </el-col>
                                                <el-col :span="16">
                                                    <template v-if="QuickBetList && QuickBetList[item]">
                                                        <span v-for="(ball,i) in QuickBetList[item]" :key="i" class="ball c-text-7" :class="CodeColor(ball)">{{ ball }}</span>
                                                    </template>
                                                </el-col>
                                                <el-col :span="4">
                                                    <el-checkbox :label="item"><span /></el-checkbox>
                                                </el-col>
                                            </el-row>
                                        </ul>
                                    </el-col>
                                </el-row>
                            </el-checkbox-group>
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'OperateBoardFast',
    mixins: [SixMix],
    data() {
        return {
            odds: null,
            max_odds: undefined,
            backwater_percent: null,
            web_name: undefined,
            transmit_amount: null,
            capacity: -10000,
            active: {
                '全部玩法': false
            },
            FastBox: {
                'sixballs': []
            },
            FastBoardTable: {
                default: [[['全部玩法']]],
                lhc: [
                    [['全部玩法', '前5', '前10'], ['家禽', '走兽'], ['大', '小', '单', '双', '合单', '合双'], ['尾大', '尾小', '尾单', '尾双']],
                    [['红波', '绿波', '蓝波'], ['红单', '红双', '红大', '红小'], ['绿单', '绿双', '绿大', '绿小'], ['蓝单', '蓝双', '蓝大', '蓝小']]
                ]
            },
            FastBoardStep: {
                active: 0.5,
                list: [10, 5, 4, 3, 2, 1, 0.5, 0.4, 0.3, 0.2, 0.1, 0.05, 0.03, 0.01, 0.005, 0.001]
            }
        }
    },
    props: {
        client: {},
        list: {
            default: null
        },
        type: {
            default: 'default'
        },
        group: {
            default: null
        },
        transmit: {
            default: 'normal' // none:禁止走飞  handle:手动走飞  normal:风险值走飞  six-balls:六合彩球走飞  six-zodiac:六合彩生肖走飞  six-tail:六合彩尾数走飞  six-level 过关走飞  integration 整合走飞
        },
        step: {
            default: false
        },
        radio: {
            default: false
        },
        limit: {
            default: undefined
        },
        repair: {
            default: 0 // 赔率修正
        },
        double: {
            default: false
        },
        defaultStep: {
            default: 0.5
        }
    },
    computed: {
        Table() {
            let type = this.type
            if (this.FastBoardTable[type]) {
                return this.FastBoardTable[type]
            }
            return this.FastBoardTable['default']
        },
        OperateAuth() {
            if (this.$parent.OperateAuth) {
                return this.$parent.OperateAuth
            }
            return {}
        },
        tsite() {
            let store = this.client.store
            return this.$store.state[store].tsite
        }
    },
    watch: {
        capacity(val) {
            if (typeof val === 'number') {
                this.SetCapacity(val)
            }
        },
        'FastBoardStep.active'(val) {
            this.SaveStep(val)
        },
        'group'(val) {
            this.SaveStep()
        },
        'limit'(val) {
            this.FastBoxChange()
        },
        'FastBox.sixballs'(val) {
            this.FastBoxChange()
            this.$emit('change', val)
        },
        'double'(val) {
            if (!val) {
                this.max_odds = undefined
            }
        }
    },
    methods: {
        BoardFast(item) {
            let input = undefined
            if (!this.list) {
                input = !this.active[item]
                this.$set(this.active, item, input)
            }
            if (this.$parent.BoardFast) {
                this.$parent.BoardFast(item, this.group, input)
            }
        },
        RebateSet(item, type) {
            if (this.$parent.RebateSet) {
                this.$parent.RebateSet(null, type, this.group, this.FastBoardStep.active)
            }
        },
        TransmitSubmit() {
            let mode = 'sixballs'
            if (this.limit) {
                let list = this.FastBox[mode]
                if (!list || !list.length) {
                    return this.Err('请选择对应的生肖或号码')
                }
                if (list.length != this.limit) {
                    return this.Err(`本项目走飞需要勾选 ${this.limit} 种号码，当前只勾选了 ${list.length} 种`)
                }
            }
            if (this.$parent.TransmitSubmit) {
                if (this.OperateAuth.admin && this.OperateAuth.mode == 0) {
                    if (!this.odds) {
                        return this.Err('请输入需要走飞的赔率')
                    }
                    if (this.double && !this.max_odds) {
                        return this.Err('请输入需要走飞的最大赔率')
                    }
                    if (!this.backwater_percent) {
                        return this.Err('请输入需要走飞的退水')
                    }
                    if (!this.transmit_amount) {
                        return this.Err('请输入需要走飞的金额')
                    }
                    if (!this.web_name) {
                        return this.Err('请选择走飞站点')
                    }
                    let {odds, max_odds, backwater_percent, web_name} = this
                    return this.$parent.TransmitSubmit(undefined, this.group, this.FastBox[mode].join(','), this.transmit_amount, {odds, max_odds, backwater_percent, web_name})
                } else if (this.transmit==='normal' || this.transmit==='integration') {
                    if (!this.transmit_amount) {
                        return this.$parent.TransmitSubmit(this.capacity, this.group)
                    } else {
                        return this.$parent.TransmitSubmit(this.capacity, this.group, this.FastBox[mode].join(','), this.transmit_amount)
                    }
                } else {
                    if (mode && (!this.FastBox[mode] || !this.FastBox[mode].length)) {
                        return this.Err('请选择彩球号码')
                    }
                    if (!this.transmit_amount) {
                        return this.Err('请输入需要走飞的金额')
                    }
                    return this.$parent.TransmitSubmit(undefined, this.group, this.FastBox[mode].join(','), this.transmit_amount)
                }
            }
        },
        TransmitListSubmit(mode) {
            if (mode && (!this.FastBox[mode] || !this.FastBox[mode].length)) {
                return this.Err('请选择彩球号码')
            }
            if (!this.transmit_amount) {
                return this.Err('请选输入需要走飞的金额')
            }
            this.$parent.TransmitSubmit(undefined, this.group, this.FastBox[mode].join(','), this.transmit_amount)
        },
        SetCapacity(val) {
            if (typeof val === 'number') {
                let group = this.group
                if (!group) {
                    group = []
                }
                if (typeof group === 'number') {
                    group = [group]
                }
                if (typeof group === 'string') {
                    group = group.split(',')
                }
                for (let id of group) {
                    this.$set(this.client.capacitys, id, val)
                }
            }
        },
        FillNumber(num) {
            return num < 10 ? '0'+num : ''+num
        },
        SaveStep(val=this.FastBoardStep.active) {
            if (!val || typeof val !=='number') {
                val = '0'
            }
            let group = this.Origin(this.group)
            if (group) {
                if (typeof group === 'number') {
                    group = [group]
                }
                for (let g of group) {
                    this.$set(this.client.board.stepdir, g, `${val}`)
                }
            }
        },
        FastBoxChange() {
            let limit = this.limit
            let mode = 'sixballs'
            let list = this.FastBox[mode]
            while (list.length > limit) {
                list.pop()
            }
            this.$emit('change', this.FastBox['sixballs'])
        }
    },
    mounted() {
        this.SetCapacity(this.capacity)
        this.DataReset('FastBox')
        this.SaveStep()
        this.FastBoardStep.active = this.defaultStep
    }
}
</script>
