<style lang="scss">
    .OperateLhc-3 {
        h6.title {
            padding:3px 5px; text-align:center; background-color:#f3655e; color:#fff;
        }
    }
</style>
<template>
    <div class="OperateLhc-3">
        <div class="l-flex">
            <div class="l-flex-4">
                <group :client="client" :list="Table[0]" :length="4"  :radio="true" :transmit="false" :double-line="true" @pick="AloneTransfer" />
            </div>
            <div class="l-flex-1" />
        </div>
        <BoardFast :client="client" :group="[0]" transmit="six-balls" :radio="true" :limit="limit" :double="has_double_odds" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateLhc3',
    mixins: [CommonMix],
    data() {
        return {
            interval: ['2rem', 3, 3, 3, 3],
            limit: undefined,
            has_double_odds: false
        }
    },
    props: {
        client: {}
    },
    computed: {

    },
    methods: {

    },
    components: {
        Group
    }
}
</script>
