
export default {
    data() {
        return {
            statis: {
                view: true,
                type: 1,
                category: null,
                dir: [],
                list: []
            }
        }
    },
    computed: {
        // 统计数据侦听
        statisList() {
            let code = this.statis.category
            if (code) {
                for (let item of this.statis.list) {
                    if (item.code == code) {
                        return item.list
                    }
                }
            }
            return []
        }
    },
    watch: {

    },
    methods: {
        StatisChange(type, reason) {
            //console.log('StatisChange',reason)
            // if(this.Running.remote_data && this.Running.remote_data.credit_tools === 'rank'){
            //     this.statis.view = true
            //     this.statis.type = type
            //     this.statis.category = null
            //     this.$store.dispatch('game/GET_CREDIT_STATIS',{ type, id:this.Running.id }).then(res=>{
            //         this.$set(this.statis,'list',res)
            //         if(res && res[0]){
            //             this.$set(this.statis,'category',res[0].code)
            //         }
            //     })
            // }else{
            //     this.statis.view = false
            // }
        }
    }
}