import Methods from '~/methods';

function Today(type='day') {
    let date;
    switch (type) {
        case 'day':
            date = new Date(new Date().toLocaleDateString())
            break;
        case 'yesterday':
            date = new Date(new Date(new Date(new Date()-24*60*60*1000)).toLocaleDateString())
            break;
        case 'week':
            date = new Date(new Date().toLocaleDateString())
            var weekday = new Date().getDay() || 7
            date.setDate(date.getDate()-weekday+1)
            break;
        case 'lweek':
            date = new Date(new Date(new Date(new Date()-7*24*60*60*1000)).toLocaleDateString())
            var weekday = new Date().getDay() || 7
            date.setDate(date.getDate()-weekday+1)
            break;
        case 'month':
            var arr = new Date().toLocaleDateString().split('/')
            arr.splice(2, 1, '1')
            date = new Date(arr.join('/'))
            break;
        case 'lmonth':
            var arr = new Date().toLocaleDateString().split('/')
            if (arr[1] == 1) {
                arr = [Number(arr[0])-1+'', '12', '1']

            } else {
                arr = [arr[0], Number(arr[1])-1+'', '1']
            }
            date = new Date(arr.join('/'))
            break;
    }
    return date.getTime() / 1000
}
function MonthLastDay() {
    let date = new Date(new Date().toLocaleDateString())
    let month = date.getMonth()
    date.setMonth(month+1)
    date.setDate(0)
    return date.getTime() / 1000
}
function LastMonthLastDay() {
    let date = new Date(new Date().toLocaleDateString())
    let month = date.getMonth()
    date.setMonth(month)
    date.setDate(0)
    return date.getTime() / 1000
}
function FilterIntervalSet(type) {
    if (type) {
        switch (type) {
            case 'day':
                var time = Today('day')
                return [Methods.Untimestamp(time), Methods.Untimestamp(time+86400-1)]
            case 'yesterday':
                var time = Today('yesterday')
                return [Methods.Untimestamp(time), Methods.Untimestamp(time+86400-1)]
            case 'week':
                var time = Today('week')
                return [Methods.Untimestamp(time), Methods.Untimestamp(time+604800-1)]
            case 'lweek':
                var time = Today('lweek')
                return [Methods.Untimestamp(time), Methods.Untimestamp(time+604800-1)]
            case 'month':
                var time = Today('month')
                var time_last = MonthLastDay()
                return [Methods.Untimestamp(time), Methods.Untimestamp(time_last+86400-1)]
            case 'lmonth':
                var time = Today('lmonth')
                var time_last = LastMonthLastDay()
                return [Methods.Untimestamp(time), Methods.Untimestamp(time_last+86400-1)]
            case '-30':
                var time = Today('day')
                return [Methods.Untimestamp(time - 86400 * 30), Methods.Untimestamp(time)]
            case '-7':
                var time = Today('day')
                return [Methods.Untimestamp(time - 86400 * 7), Methods.Untimestamp(time)]
            case 'nextyear':
                let nextYear = new Date().getFullYear() + 1
                return ['2019-01-01 00:00:00', `${nextYear >= 2020 ? nextYear : 2020}-01-01 23:59:59`]
            default:
                var time = Today('day')
                return ['2000-01-01 00:00:00', Methods.Untimestamp(time+86400-1)]
        }
    }
}
export default {
    data() {
        return {
            Page: 1,
            Filter: {
                start: '',
                end: '',
                key: '',
                //interval : '',
                interval: ['2019-01-01 00:00:00', '2030-01-01 23:59:59']
            },
            Editer: {
                view: false,
                title: null,
                form: null
            },
            FilterIntervalMenu: {
                shortcuts: [
                    {
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', FilterIntervalSet('day'))
                        }
                    },
                    {
                        text: '昨天',
                        onClick(picker) {
                            picker.$emit('pick', FilterIntervalSet('yesterday'))
                        }
                    },
                    {
                        text: '本周',
                        onClick(picker) {
                            picker.$emit('pick', FilterIntervalSet('week'))
                        }
                    },
                    {
                        text: '上周',
                        onClick(picker) {
                            picker.$emit('pick', FilterIntervalSet('lweek'))
                        }
                    },
                    {
                        text: '本月',
                        onClick(picker) {
                            picker.$emit('pick', FilterIntervalSet('month'))
                        }
                    },
                    {
                        text: '上月',
                        onClick(picker) {
                            picker.$emit('pick', FilterIntervalSet('lmonth'))
                        }
                    },
                    {
                        text: '所有',
                        onClick(picker) {
                            picker.$emit('pick', FilterIntervalSet('all'))
                        }
                    }
                ]
            }
        }
    },
    computed: {
        main() {
            if (this.storePath) {
                return this.$store.state[this.storePath[0]][this.storePath[1]]
            }
            return {}
        },
        storePath() {
            if (this.store) {
                return this.store.split('/')
            }
            return null
        }
    },
    methods: {
        FilterInterval(type) {
            return FilterIntervalSet(type)
        },
        FilterIntervalSet(type) {
            this.$set(this.Filter, 'interval', FilterIntervalSet(type))
        },

        Get(page, model, filter, isMore) {
            let data = this.Origin(filter ? filter : this.Filter)
            if (typeof page == 'string') {
                model = page
                filter = model
                page = null
            }
            let origin = {page, model, filter}
            if (page) {
                data.page = page
            }
            // 重置更多加载
            if (this.Filter._more) {
                this.Filter._more = false
            }
            // 如果有时间筛选
            if (data.interval) {
                data.start_time = data.interval[0]
                data.end_time = data.interval[1]
            }
            // 如果指定了一个其他的模型
            if (!model) {
                model = this.storePath[1]
            }

            // 过滤多余字段
            for (let key in data) {
                if (data[key] === '' || data[key] === undefined) {
                    delete data[key]
                }
            }
            delete data.interval

            let path = `${this.storePath[0]}/_GET_${model.toUpperCase()}`
            return this.$store.dispatch(path, {
                params: data,
                more: !!isMore
            }).then((res, opt) => {
                if (origin.model === undefined) {
                    this.Page = res.page
                }
                if (res && res.options && res.options.start_time && res.options.end_time) {
                    if (!this.Filter.interval) {
                        this.$set(this.Filter, 'interval', [res.options.start_time, res.options.end_time])
                    }
                }
                return res
            })
        },
        Item(id, path='') {
            let store = path.split('/')[0]
            let model = path.split('/')[1]
            if (!model) {
                model = store ? store : this.storePath[1]
                store = this.storePath[0]
            }
            let params = this.Origin(this.Filter)
            if (params.interval) {
                params.start_time = params.interval[0]
                params.end_time = params.interval[1]
            }
            return this.$store.dispatch(`${store}/_GET_${model.toUpperCase()}`, {id, params}).then(({data}) => {return data})
        },
        More(model, filter) {
            this.Filter._more = true
            return this.Get(this.Page + 1, model, filter, 'isMore')
        },
        TopReset(model='main') {
            if (!this[model].empty) {
                this.Reset()
                this.MakeFilter()
            }
        },
        LoadTop(loaded) {
            this.MakeFilter().then(res => {
                loaded('done')
            })
        },

        LoadMore(loaded) {
            let model='main'
            if (!this[model]._init || this[model]._loading || !this[model]._more || this.routeName !== this.$route.name) {
                // console.log(!this[model].init,this[model].loading,!this[model].more,this.routeName !== this.$route.name)
                // console.log('无法加载')
            } else {
                return this.More()
            }
        },
        Action(name='POST', data={}, model) {
            // 如果指定了一个其他的模型
            if (!model) {
                model = this.storePath[1]
            }
            let path = `${this.storePath[0]}/${name.toUpperCase()}_${model.toUpperCase()}`
            return this.$store.dispatch(path, data).then(res => {
                return res
            })
        },
        Post(params=this.Origin(this.Params), model=this.storePath[1], callback) {
            let path = `${this.storePath[0]}/_POST_${model.toUpperCase()}`
            if (!callback) {
                callback = (res) => {
                    if (res) {
                        this.view = false // this.$emit('input',false)
                        this.Suc('操作成功')
                        this.$emit('finish', res ? res : 1)
                    }
                    return res
                }
            }
            return this.$store.dispatch(path, {data: params}).then(callback)
        },
        Put(params=this.Origin(this.Params), model=this.storePath[1], callback) {
            let path = `${this.storePath[0]}/_PUT_${model.toUpperCase()}`
            if (!callback) {
                callback = (res) => {
                    if (res) {
                        this.view = false  // this.$emit('input',false)
                        this.Suc('操作成功')
                        this.$emit('finish', res ? res : 1)
                    }
                    return res
                }
            }
            return this.$store.dispatch(path, {data: params, id: params.id||params.sequence}).then(callback)
        },
        Del(params=this.Origin(this.Params), model=this.storePath[1], callback) {
            let path = `${this.storePath[0]}/_DELETE_${model.toUpperCase()}`
            if (!callback) {
                callback = (res) => {
                    if (res) {
                        this.Suc('操作成功')
                        this.Get(this.Page)
                    }
                    return res
                }
            }
            this.DelConfirm(() => {
                return this.$store.dispatch(path, params).then(callback)
            })
        },
        Submit(model=this.storePath[1]) {
            let params = this.Origin(this.Params)
            return params.id ?
                this.Put(params, model) :
                this.Post(params, model)
        },
        Edit(row, title, model='Editer') {
            if (this[model]) {
                this[model].view  = true
                this[model].title = title ? title : row ? '修改数据' : '新增数据'
                this[model].form  = row ? this.Origin(row) : null
            }
            return Promise.resolve(this[model])
        },
        Reset(model=this.storePath[1]) {
            let path = `${this.storePath[0]}/RESET`
            return this.$store.commit(path, {name: model.toLowerCase()})
        },
        MakeFilterInit() {
            // if(this.key) this.key = null
            // if(this.timePicker) this.timePicker = null
            this.DataReset('Filter')
            // this.Filter = this.origin(this.$options.data().filter)
        },
        MakeFilter(empty) {
            if (empty) {
                this.Cm(`${this.storePath[0]}/CLEAN`, {base: this.storePath[1], key: '_list'})
                window.scrollTo(0, 0)
            }
            this.Page = 1
            return this.Get(1)
        },

        TableStrLength(str='', len=100) {
            if (str.length > len) {
                return str.substring(0, len) + '...'
            }
            return str
        },

        Activated() {
            if (this.$route.params._back) {
                if (this.initBack) {
                    this.initBack()
                }
            } else {
                this.init()
            }
        },

        FormatNumber(num, len=2, empty='', mode=0) {
            if (!num && num != 0) {
                return empty
            }
            if (num == 0) {
                return 0
            }
            try {
                num = this.Big(num).round(len, mode).toNumber() + ''
                let ints = num.split('.')[0].split('').reverse()
                let intr = []
                let count = 0
                while (ints.length) {
                    count++
                    intr.push(ints.shift())
                    if (count === 3 && ints.length) {
                        intr.push(',')
                        count = 0
                    }
                }
                return intr.reverse().join('')
            } catch (err) {
                return ''
            }
        },
        Price(num, {float=2, mode=0, force=false, separate=3, format='string'}={}) {
            if (!num && num != 0) {
                return ''
            }
            if (typeof mode === 'string') {
                let floatdir = {
                    down: 0,
                    half_up: 1,
                    half_even: 2,
                    up: 3
                }
                mode = floatdir[mode] ? floatdir[mode] : 0
            }
            if (num == 0) {
                num = 0
            }
            let origin = this.Big(num).round(float, mode).toNumber() + ''
            let ints = origin.split('.')[0].split('').reverse()
            let floats = origin.split('.')[1] ? origin.split('.')[1].split('') : []
            if (separate) {
                if (typeof separate !== 'number') {
                    separate = 3
                }
                let intr = []
                let count = 0
                while (ints.length) {
                    count++
                    intr.push(ints.shift())
                    if (count === 3 && ints.length) {
                        intr.push(',')
                        count = 0
                    }
                }
                ints = intr.reverse()
            } else {
                ints = ints.reverse()
            }
            if (float && force) {
                while (floats.length < float) {
                    floats.push('0')
                }
            }
            if (format === 'string') {
                return floats.length ? `${ints.join('')}.${floats.join('')}` : ints.join('')
            }
            if (format === 'array') {
                return [ints.join(''), floats.join('')]
            }
        },
        Clean(store, model) {
            if (!store) {
                store = this.storePath[0]
            }
            if (!model) {
                model = this.storePath[1]
            }
            this.Cm(`${store}/CLEAN`, {base: model, key: '_list'})
        }
    }
}