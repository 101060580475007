
import Credit from '../../../mixin/credit.js'
import Common from './common.js'
import Statis from './common.statis.js'
//import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'GameCredit',
    mixins: [Credit, Common, Statis],
    data() {
        return {
            rebate: 0, //反水比例
            type: 1,
            amount: null, // 当前页面默认下注金额
            quick: {
                value: [100, 200, 500, 1000, 5000],
                default: [100, 200, 500, 1000, 5000],
                enable: true,
                cache: null,
                view: false,
                tools: false
            },
            List: [],
            Cart: [],
            opt: {
                disabledQuick: false,
                onlyQuick: false,
                customOrder: null,
                formal: false,
                checkoutBtn: true
            },
            // isFormal : false,
            orderEditing: false
        }
    },
    computed: {

    },
    watch: {
        'Running.code'(val) {
            this.Reset(true, true)
            this.StatisChange(1, 'Running.code')
        },
        'Running.type'(val) {
            this.Reset(true, true)
            this.StatisChange(1, 'Running.type')
        },
        'Running.play.belong'(val) {
            this.Reset(true, true)
        },
        'Running.expect.now.expect'(val) {
            this.StatisChange(1, 'Running.expect.now.expect')
        },
        type(val) {
            if (!this.isMobile) {
                this.Reset(true, true)
            }
        }
    },
    methods: {
        init() {
            this.Reset(true, true)
            this.StatisChange(1, 'init')
            // this.Event = this.$store.state.socket.Event
            // this.Event.on('odds_trader',(res)=>{
            //     //this.Reload()
            //     this.UpLoadLisyBySocket(res)
            // })
            if (this.Running.nav && this.Running.nav.side && this.Running.nav.side.id) {
                this.side = this.Running.nav.side.id
            }
            this.$store.state.base.Event.on('credit_order_checkout', () => {
                this.Checkout()
            })
        },
        Reset(cart, opt) {
            // this.amount = null
            // if(this.Running.type==='credit'){
            this.$set(this.$data, 'List', [])
            if (this.Running.play && this.Running.play.list && this.Running.nav && this.Running.nav.item) {
                this.$set(this.$data, 'List', this.ListChild(this.Running.play.list, this.Running.nav.item))
            }
            if (cart) {
                this.$set(this.$data, 'Cart', [])
            }
            if (opt) {
                this.DataReset('opt')
            }
            this.$set(this.opt, 'customOrder', null)
            if (this.$refs['order'] && this.$refs['order'].Reset) {
                this.$refs['order'].Reset()
            }
            // }
        },
        // 生成模版子菜单
        ListChild(list, nav) {
            let names = {
                /*连码*/'0987b8b338d6c90b': {'连碰': 'codes', '胆拖': 'codes', '生肖对碰': 'zodiac', '尾数对碰': 'tail', '混合对碰': 'zodiac,tail'}
                /*连肖*/ // 'be1bc7997695495f' : {'连碰':'zodiac', '胆拖':'zodiac'},
                /*连尾*/ // '4a533591763dfa74' : {'连碰':'tail', '胆拖':'tail'},
            }
            let format = names[nav.name]
            if (format) {
                let arr = []
                for (let item of list) {
                    if (item.format) {
                        let pack = this.Origin(item)
                        pack.child = []
                        for (let type of pack.format) {
                            if (format[type]) {
                                pack.child.push({
                                    id: pack.id || pack.games_play_id,
                                    games_play_group_id: pack.games_play_group_id,
                                    title: type,
                                    belong: pack.title,
                                    child: MakeList(format[type], pack)
                                })
                            }
                        }
                        arr.push(pack)
                    }
                }
                function MakeList(tpl, parent) {
                    let box = {
                        codes: [],
                        zodiac: ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'],
                        tail: ['1尾', '2尾', '3尾', '4尾', '5尾', '6尾', '7尾', '8尾', '9尾', '0尾']
                    }
                    box.codes = [1, 11, 21, 31, 41, 2, 12, 22, 32, 42, 3, 13, 23, 33, 43, 4, 14, 24, 34, 44, 5, 15, 25, 35, 45, 6, 16, 26, 36, 46, 7, 17, 27, 37, 47, 8, 18, 28, 38, 48, 9, 19, 29, 39, 49, 10, 20, 30, 40]
                    box['zodiac,tail'] = box.zodiac.concat(box.tail)
                    let codes = box[tpl]
                    let list = []
                    for (let code of codes) {
                        list.push({
                            id: parent.id || parent.games_play_id,
                            games_play_group_id: parent.games_play_group_id,
                            belong: parent.title,
                            odds: parent.odds,
                            max_odds: parent.has_double_odds ? parent.max_odds : null,
                            name: code,
                            pick: false,
                            disabled: false
                        })
                    }
                    return list
                }
                return this.Origin(arr)
            }
            return this.Origin(list)
        },

        // 六合彩动态修改外层配置
        updateOpt({key, val}) {
            if (key === 'formal') {
                this.$set(this.$data, 'isFormal', val)
            } else {
                this.$nextTick(() => {
                    this.$set(this.opt, key, val)
                })
            }
        },
        // 快选金额设置
        quickInit() {
            this.$set(this.quick, 'cache', this.Origin(this.quick.value))
        },
        quickLoad() {
            try {
                let data = this.Cache('GameCredit_quick')
                this.$set(this.quick, 'value', data ? JSON.parse(data) : this.quick.default)
            } catch (err) {
                this.$set(this.quick, 'value', this.quick.default)
            }
        },
        quickSave(index) {
            let list = []
            for (let item of this.quick.cache) {
                item = parseInt(item)
                if (this.Is('int', item) && item > 0 && item < 1000000) {
                    list.push(item)
                }
            }
            if (list.length>0) {
                this.$set(this.quick, 'value', this.Origin(this.quick.cache))
                this.Cache('GameCredit_quick', JSON.stringify(this.quick.value)) // 保存到本地
                this.Suc('操作成功')
            } else {
                this.Err('操作失败')
            }
            return this.quick.view = false
        },
        quickInput(val) {
            this.$set(this.$data, 'amount', val)
            this.$set(this.quick, 'tools', false)
        },

        // rebateAdd(){
        //     if(this.rebate > 0){
        //         this.rebate = this.Big(this.rebate).sub(0.1).toNumber()
        //     }
        // },
        // rebateSub(){
        //     if(this.rebate < this.calcuRebate.max){
        //         this.rebate = this.Big(this.rebate).add(0.1).toNumber()
        //     }
        // },

        // 长连接中更新数据
        UpLoadLisyBySocket(data) {
            // if(data){
            //     for(let id in data){
            //         for(let list of this.List){
            //             this.NextOddsLevel(list,id,data[id])
            //         }
            //     }
            // }
        }
        // NextOddsLevel(list,id,row){
        //     let key = row.max ? 'max_odds' : 'odds'
        //     if(list.id && list.id == id && list[key]){
        //         try{
        //             let calcu = this.Big(list[key]).add(row.rebate).toNumber()
        //             this.$set(list,key,calcu)
        //         }catch(err){}
        //     }
        //     if(list.child){
        //         for(let item of list.child){
        //             this.NextOddsLevel(item,id,row)
        //         }
        //     }
        // },
    },
    activated() {
        this.quickLoad()
        this.StatisChange(1, 'activated')
    },
    mounted() {
        this.init()
        this.quickLoad()
    }
}