import dgx from '~/dgx_z/index.js';
import ajax from '@lottery.2.0/library/ajax.js';

const store = 'myHelp';
const STORE = store.toUpperCase();

export default dgx({
    store,
    state: {
        	question: {
        		problem: '常见问题',
        		_options: {
        			url: 'help/problem'
        		}
        	},
        	guide: {
        		title: '新手引导',
        		_options: {
        			url: 'help/novice'
        		}
        	},
        	deposit: {
        		title: '存款问题',
        		_options: {
        			url: 'help/deposit'
        		}
        	},
        	withdrawal: {
        		title: '取款问题',
        		_options: {
        			url: 'help/withdrawal'
        		}
        	},
        	rule: {
        		title: '规则菜单',
        		_options: {
        			url: 'help/menuIntroduction'
        		}
        	},
        	content: {
        		title: '规则内容',
        		_options: {
        			url: 'help/contentIntroduction'
        		}
        	}
    },
    actions: {

    },
    mutations: {

    }
},
ajax
);
