//import Group from '../page/game/components/credit/components/group'
export default {
    data() {
        return {

        }
    },
    computed: {
        // 反水点统计
        // calcuRebate(){
        //     //  不可滑动                  可滑动部分  被占用
        //     // |------------------------|-----------|-----|
        //     let scroll = this.Big(1).sub(this.User.min_odds || 1).toNumber() // 可滑动的长度
        //     let occupy = this.Big(1).sub(this.User.odds_rebate || 1).toNumber() // 被占用的反水点数
        //     let obj = {
        //         scroll,
        //         occupy,
        //         max : this.Big(scroll).sub(occupy).mul(100).round(1,0).toNumber(), // 最终可滑动的最大值
        //     }
        //     return obj
        // },
        // 递归查询上级配置
        COptions() {
            function DeepCheck(target) {
                if (target.rebate >= 0 && target.type && target.quick) {
                    return {
                        rebate: target.rebate,
                        type: target.type,
                        quick: target.quick,
                        opt: target.opt
                    }
                }
                if (target.$parent) {
                    return DeepCheck(target.$parent)
                }
                return null
            }
            return DeepCheck(this)
        }
    },
    props: [],
    methods: {
        init(i) {
            this.Reset()
        },
        // 重置表单
        Reset() {
            // this.DB = null
            // if(this.Running.credit && this.Running.credit.db){
            //     this.DB = this.Origin(this.Running.credit.db)
            // }
        },
        // 根据赔率和反水计算新赔率
        CalcuOdds(item = {}, type) {
            if (item.odds && this.COptions) {
                let rebate = this.Big(this.COptions.rebate).div(100).add(this.calcuRebate.occupy).toNumber()
                return this.Big(1).sub(rebate).mul(item.odds).round(3, 3).toNumber()
            }
            let {id} = item
            if (this.Running.play.odds && this.Running.play.odds[id]) {
                let {odds, max_odds, has_double_odds} = this.Running.play.odds[id]
                if (item.repair) {
                    odds = this.Big(odds).sub(item.repair).toNumber()
                }
                odds = odds ? odds : '-'
                max_odds = max_odds ? max_odds : '-'
                return {odds, max_odds, has_double_odds}
            }
            return null
        },
        MakeQueue(item, length=1) {
            let queue = []
            let fill = this.fill
            let fillIndex, fillNumber;
            try {
                if (fill) {
                    fillIndex = fill.split(',')[0]
                    fillNumber = fill.split(',')[1]
                }
            } catch (e) {}
            if (item) {
                let {title='', belong, child} = item
                let list = []
                child.forEach((item, index) => {
                    list.push(item)
                    if (fillIndex == index && fillNumber > 0) {
                        for (let i=0;i<fillNumber;i++) {
                            list.push({fill: true})
                        }
                    }
                })
                for (let i=0;i<length;i++) {
                    queue.push({
                        title, belong, child: []
                    })
                }
                let unit = 0
                for (let item of list) {
                    if (unit == length) {
                        unit = 0
                    }
                    queue[unit].child.push(item)
                    unit++
                }
            }
            return queue
        },
        Checkout() {
            this.$store.state.base.Event.emit('credit_order_checkout')
        }
    },
    mounted() {

    }
}