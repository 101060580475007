import Vue from 'vue'
import {deepCopy, isObject, isArray} from '../../helper.js'

export default function(options, model) {

    // let store = options.store.toUpperCase()
    // let MODEL = model.toUpperCase()

    options.state[model] = Object.assign({

        _name: model,
        _init: false,
        _loading: false,
        _editing: false,
        _ajax: [],
        _error: false,
        _db: {
            list: [],
            pages: {},
            length: 0
        },

        _page: 1,
        _total: null,
        _count: 0,
        _empty: false,
        _list: [],

        _id: null,
        _active: null,
        _item: null,
        _order: null

    }, options.state[model])
}