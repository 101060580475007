
<template>
    <div class="OperateNavTable">
        <!--         <div class="l-flex o-mb-s" v-for="(pack,unit) in 9">
            <template v-for="(item,index) in 5">
                <div v-if="index" style="padding:0 .2rem;"></div>
                <div class="l-flex-1">
                    <Group :client="client" :list="Table[unit*5+index+1]" :length="1"></Group>
                </div>
            </template>
        </div> -->
        <group :client="client" :list="Table[1]" :length="5" />
        <BoardFast :client="client" :group="TableAll" :step="true" :default-step="0.1" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperatePks11',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group
    }
}
</script>
