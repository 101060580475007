import Vue from 'vue'
import {deepCopy, isObject, isArray} from '../../helper.js'

// 带有排序属性
// 传参 ：sort or [sort,dataBase]
//
const SORT = {
    DESC(key) {
        return (obj1, obj2) => {
            let val1 = obj1[key];
            let val2 = obj2[key];
            if (val1 > val2) {
                return -1
            } else if (val1 < val2) {
                return 1
            } else {
                return 0
            }
        }
    },
    ASC(key) {
        return (obj1, obj2) => {
            let val1 = obj1[key];
            let val2 = obj2[key];
            if (val1 < val2) {
                return -1
            } else if (val1 > val2) {
                return 1
            } else {
                return 0
            }
        }
    }
}

export default function(options, model) {

    let store = options.store.toUpperCase()
    let MODEL = model.toUpperCase()

    // if(options.state[x]._options.order){
    //     options.state[x] = attrFactory.order(options.state[x])
    //     options.state[x]._sort = options.state[x]._options.order.sort || []
    //     options.actions[`_ORDER_${MODEL}`] = ({state, dispatch, commit},active)=>{

    //         let dataBase = '_list'
    //         if(isArray(active) && active[0]){
    //             if(active[1]){
    //                 dataBase = active[1]
    //             }
    //             active = active[0]
    //         }

    //         // 获取排序规则
    //         state[model]._order = active
    //         let order = state[model]._sort[active]
    //         if(order){
    //             state[model][dataBase].sort(SORT[order.type](order.key))
    //             // 重置焦点
    //             if(state[model]._active || state[model]._active == 0){
    //                 for(let i=0;i<state[model][dataBase].length;i++){
    //                     if(state[model]._id == state[model][dataBase][i].id){
    //                         state[model]._active = i
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}

//