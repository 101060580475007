import Vue from 'vue'
import {deepCopy, isObject, isArray} from '../../helper.js'

// 带有焦点属性
// 传参 ：query or [query,dataBase]
// query :
//  undefined or null 重置焦点
//  {key:val} 检索第一个匹配的字段
//  -1 0 x 查找指定索引（-1代表最后一个索引）
// 焦点变化 mutations 方法

export default function(options, model) {

    let store = options.store.toUpperCase()
    let MODEL = model.toUpperCase()
    let dgx = options.state[model]._options
    if (dgx.url) {
        for (let method of ['GET', 'POST', 'PUT', 'DELETE']) {

            options.actions[`_${method}_${MODEL}`] = ({state, dispatch, commit}, data={}) => {

                // 发起请求
                return dispatch(`_${store}_AJAX`, {
                    method,
                    url: data.id ? dgx.url + '\\'+data.id : dgx.url,
                    data: data.data || {},
                    params: data.params,
                    model: model,
                    only: data.only !== undefined ? data.only : method
                }).then(res => {
                    // 不同方法对 store 的影响
                    if (res) {
                        switch (method) {
                            case 'GET':
                                // 如果有 id
                                if (res.data) {
                                    if (res.data.id || res.data.sequence) {
                                        commit(`_${store}_UPDATE`, {base: model, key: '_item', value: res.data})
                                        commit(`_${store}_UPDATE`, {base: model, key: '_id', value: data.id || data.sequence})
                                        commit(`_${store}_UPDATE`, {base: model, key: '_active', value: 0})
                                    } else if (Array.isArray(res.data)) {
                                        if (data.params && data.params._more && res.page > 1) {
                                            for (let item of res.data) {
                                                commit(`_${store}_ADD`, {base: model, key: '_list', value: item, position: -1, size: res.per_page})
                                            }
                                        } else {
                                            commit(`_${store}_UPDATE`, {base: model, key: '_list', value: res.data})
                                        }
                                        commit(`_${store}_UPDATE`, {base: model, key: '_page', value: res.page})
                                        let total = Math.ceil(res.per_total/res.per_page)
                                        commit(`_${store}_UPDATE`, {base: model, key: '_total', value: total})
                                        commit(`_${store}_UPDATE`, {base: model, key: '_count', value: res.per_total})
                                        commit(`_${store}_UPDATE`, {base: model, key: '_more', value: res.per_total > res.page * res.per_page})
                                        commit(`_${store}_UPDATE`, {base: model, key: '_empty', value: !!(res.page == 1 && !res.data.length)})
                                    } else {
                                        commit(`_${store}_UPDATE`, {base: model, key: '_list', value: res.data || res})
                                    }
                                }
                                break;
                            case 'POST':
                                commit(`_${store}_ADD`, {
                                    base: model,
                                    key: '_list',
                                    value: res.data,
                                    position: data.position || data.position ===0 ? data.position : -1
                                })
                                break;
                            case 'PUT':
                                commit(`_${store}_UPDATE`, {
                                    id: data.id,
                                    base: model,
                                    key: '_list',
                                    value: res.data,
                                    extend: true
                                })
                                break;
                            case 'DELETE':
                                commit(`_${store}_REMOVE`, {
                                    id: data.id,
                                    base: model,
                                    key: '_list'
                                })
                                break;
                            default:
                                break;
                        }
                    }
                    if (data.active || data.active == 0) {
                        dispatch(`_ACTIVE_${MODEL}`, data.active)
                    }
                    return res
                })
            }
        }
    }
}
