import dgx from '~/dgx_z/index.js'
import ajax from '@/Z_LOTTERY_NEW/library/ajax.js'

const store = 'base';
const STORE = store.toUpperCase();

import Events from 'events' //.EventEmitter;
const EventEmitter = Events.EventEmitter

export default dgx({
    store,
    state: {
        Event: new EventEmitter(),
        sign: {
            _options: {}
        },
        user: {
            info: null
        },
        options: {},

        activity: {
            title: '活动列表',
            _options: {
                url: 'index/activity'
            }
        },
        betstops: {
            title: '投注排行',
            _options: {
                url: 'other/betstops'
            }
        },
        bonustops: {
            title: '中奖排行',
            _options: {
                url: 'other/bonustops'
            }
        },
        announ: {
            title: '系统公告',
            _options: {
                url: 'announcement'
            }
        },
        history: {
            title: '开奖历史',
            _options: {
                url: 'lottery'
            }
        },
        order: {
            title: '游戏记录',
            _options: {
                url: 'games/order'
            }
        },
        line: {
            title: '线路',
            _options: {
                url: 'index/other/line'
            },
            active: null,
            list: []
        },

        router: {
            count: 1
        },

        block: {
            operate: {
                reload_interval: null
            }
        }
    },
    getters: {
        options: (state, getters) => {
            return state.options
        }
    },
    actions: {

        BASE_USER({state, dispatch, commit}, data) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `/user/index/info`,
                model: 'sign',
                data
            }).then(({data}) => {
                commit(`_${STORE}_UPDATE`, {
                    base: 'user',
                    key: 'info',
                    value: data
                })
                if (data && data.id) {
                    dispatch('credit/_GET_TODAY', {}, {root: true})
                }
                return data
            }, res => {
                return data
            })
        },

        // 基础配置
        BASE_OPTIONS({state, dispatch, commit}, data) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `settings`,
                data
            }).then(({data}) => {
                Vue.set(state, `options`, data)
                Vue.set(state.options, `init`, true)
                return data
            })
        },
        BASE_ACTIVITY({state, dispatch, commit}, data) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'POST',
                url: `welcome/activityList`,
                data
            }).then(({data}) => {
                return data
            })
        },
        // 用户登录
        BASE_SIGNIN({state, dispatch, commit}, data) {
            console.log('BASE_SIGNIN', 11)
            return dispatch(`_${STORE}_AJAX`, {
                method: 'POST',
                url: `index/login`,
                model: 'sign',
                data
            }).then(({data}) => {
                return data
            })
        },

        // 获取线路
        GET_LINE({state, dispatch, commit}, data) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `index/other/line`,
                model: 'line',
                data
            }).then(res => {
                let active = {
                    title: '未知',
                    ping: null,
                    origin: window.location.origin
                }
                if (window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1') {
                    active.title = '本地测试'
                    active.ping = 0
                } else {
                    for (let i=0;i<res.data.length;i++) {
                        let origin = res.data[i]
                        if (origin === window.location.origin) {
                            active.title = `线路${i+1}`
                        }
                    }
                }
                commit(`_${STORE}_UPDATE`, {base: 'line', key: 'list', value: res.data})
                commit(`_${STORE}_UPDATE`, {base: 'line', key: 'active', value: active})
                return res
            })
        },
        // 用户注册
        // BASE_SIGNUP({ state, dispatch, commit }, data) {
        //     return dispatch(`_${STORE}_AJAX`, {
        //         method: 'POST',
        //         url: `Jionwx`,
        //         model: 'sign',
        //         data,
        //     }).then(({data})=>{
        //         return data
        //     })
        // },
        // BASE_SIGNIN_CODEKEY({state,dispatch,commit}, data) {
        //     return dispatch(`_${STORE}_AJAX`, {
        //         method: 'POST',
        //         url: `/login/setKey`,
        //         model: 'sign',
        //         data,
        //     }).then(({data})=>{
        //         return data
        //     })
        // },

        // 用户登出
        BASE_SIGNOUT({state, dispatch, commit}, data) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'DELETE',
                url: `index/login/out`,
                model: 'sign',
                data
            }).then(({data}) => {
                commit(`_${STORE}_UPDATE`, {
                    base: 'user',
                    key: 'info',
                    value: null
                });
                return data
            })
        },

        // 获取最高在线人数
        GET_ONLINE({state, dispatch, commit}, data) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `index/other/online`,
                data
            })
        },
        // 获取当前在线人数
        RUNING_ONLINE({state, dispatch, commit}, data) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `index/other/playusers`,
                data
            })
        },

        // 验证码
        IMAGE_VERIFY({state, dispatch, commit}, data) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `index/login/verification`,
                data
            })
        },

        // 搜索用户
        CHECK_USER({state, dispatch, commit}, keywords) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `search/user`,
                params: {
                    keywords
                }
            })
        },

        // 联系我们
        TIPS_COOPERATION({state, dispatch, commit}, data) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `index/Cooperation/tips`,
                data
            })
        },
        POST_COOPERATION({state, dispatch, commit}, data) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'POST',
                url: `index/Cooperation`,
                data
            })
        }
    },
    mutations: {

        ROUTER_COUNT_ADD(state, opt) {
            Vue.set(state.router, 'count', state.router.count + 1)
        },

        UPDATE_BLOCK(state, data=[]) {
            if (data[0]) {
                let name = data[0]
                if (!state.block[name]) {
                    Vue.set(state.block, name, {})
                }
                if (data[1]) {
                    if (data[1] === null) {
                        Vue.set(state.block, name, {})
                    } else if (typeof data[1] === 'string') {
                        Vue.set(state.block[name], data[1], data[2])
                    } else if (typeof data[1] === 'object') {
                        for (let key in data[1]) {
                            Vue.set(state.block[name], key, data[1][key])
                        }
                    }
                }
            }
        }
    }
}, ajax)
