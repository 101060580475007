const is_dev = process.env.NODE_ENV === 'development'
const host =  is_dev ? 'https://api2.gs-z.cn/' : 'https://api2.gs-z.cn/'//'//a.api-wx.com/'
export default {
    project: "lottery",
    version: "0.3.1",
    base: `${host}`,
    base_test: `${host}`,
    base_img: `//www.vwiwf.cn/wx/static/image/`,
    base_img_m: `${host}static/pimg/`,
    socket: is_dev ? 'ws://swooleserve.gs-z.cn' : 'ws://s.api-wx.com',
    environment: navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? 'mobile' : 'pc',
    equipment: process.env.NODE_ENV
}