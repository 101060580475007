import Vue from 'vue'

import dgx from '~/dgx_z/index.js'
import ajax from '@lottery.2.0/library/ajax.js'

const store = 'credit';
const STORE = store.toUpperCase();
import {origin} from '~/helper'

export default dgx({
    store,
    state: {
        report: {
            title: '报表',
            _options: {
                url: 'user/report'
            }
        },
        reportsum: {
            title: '报表统计',
            _options: {
                url: 'user/report/sum'
            }
        },
        subsidiary: {
            title: '分类账',
            _options: {
                url: 'user/ledger'
            }
        },
        actlog: {
            title: '代理日志',
            _options: {
                url: 'user/actions'
            }
        },
        user: {
            title: '用户列表',
            _options: {
                url: 'user/persons'
            }
        },
        today: {
            title: '用户今日',
            _options: {
                url: 'user/index/today'
            }
        },
        superior: {
            title: '用户成级',
            _options: {
                url: 'user/index/superior'
            }
        },
        online: {
            title: '在线列表',
            _options: {
                url: 'index/online'
            }
        }
    },
    actions: {
        // 信用用户手动恢复额度
        BALANCE_USER({state, dispatch, commit}, {id}) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'POST',
                url: `user/balance`,
                data: {
                    id
                }
            })
        }
    },
    mutations: {

    }
}, ajax)
