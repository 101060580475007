<style lang="scss">
    .OperateLhc-2 {
        h6.title {
            padding:3px 5px; text-align:center; background-color:#f3655e; color:#fff;
        }
        .row-title {
            height:1.2rem; line-height:1.2rem; margin-top:.3rem; padding-left:.5rem; background-color:#dcd5ff;
        }
    }
</style>
<template>
    <div class="OperateLhc-2">
        <!--         <div class="l-flex">
            <div v-for="(pack,unit) in [1,2,3,4]" :class="{'l-flex-1':pack!=4,'l-flex-2':pack==4}">
                <div v-for="(item,index) in [0,1,2,3,4,5]">
                    <div class="row-title c-text-c">
                        <span v-if="pack==3">{{ ['正码一','正码二','正码三','正码四','正码五','正码六'][item] }}</span>
                    </div>
                    <Group :client="client" :list="Table[item*4+pack]" :length="pack==4?2:1" @RebateSet="RebateSetEmit" :fill="true"></Group>
                </div>
            </div>
        </div> -->
        <div class="l-flex">
            <div v-for="(item,index) in ['正码一','正码二','正码三','正码四','正码五','正码六']">
                <div class="o-pr">
                    <div class="row-title c-text-c">{{ item }}</div>
                    <group :client="client" :list="Table[(index + 1) * 10 + 1]" :length="1" layout="none" />
                    <group :client="client" :list="Table[(index + 1) * 10 + 3]" :length="1" layout="none" class="o-mt-s" />
                    <group :client="client" :list="Table[(index + 1) * 10 + 2]" :length="1" layout="none" class="o-mt-s" />
                    <group :client="client" :list="Table[(index + 1) * 10 + 5]" :length="1" layout="none" class="o-mt-s" />
                    <group :client="client" :list="Table[(index + 1) * 10 + 7]" :length="1" layout="none" class="o-mt-s" />
                    <group :client="client" :list="Table[(index + 1) * 10 + 6]" :length="1" layout="none" class="o-mt-s" />
                    <group :client="client" :list="Table[(index + 1) * 10 + 4]" :length="1" layout="none" class="o-mt-s" />
                </div>
            </div>
        </div>
        <!-- <BoardFast :client="client" :group="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]" :step="true"></BoardFast> -->
        <BoardFast :client="client" :group="[11,12,13,14,21,22,23,24,31,32,33,34,41,42,43,44,51,52,53,54,61,62,63,64]" :step="true" :default-step="0.01" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateLhc2',
    mixins: [CommonMix],
    data() {
        return {
            interval: ['2rem', 3, 3, 3, 3]
        }
    },
    props: {
        client: {}
    },
    computed: {

    },
    methods: {
        details(pack) {
            let {item, row} = pack
            this.Details(item, row)
        }
    },
    components: {
        Group
    }
}
</script>
