
<template>
    <div class="OperateNavTable">
        <group :client="client" :list="Table[1]" :length="5" />
        <BoardFast :client="client" :group="[1]" :step="true" :default-step="0.1" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateDpc18',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group
    }
}
</script>
