import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        base: require('./modules/base.js').default,
        game: require('./modules/game.js').default,
        //main : require('./modules/main.js').default,
        admin: require('./modules/admin.js').default,
        socket: require('./modules/socket.js').default,

        credit: require('./modules/credit.js').default,
        myRecord: require('./modules/my/record.js').default,
        myAccount: require('./modules/my/account.js').default,
        myTeam: require('./modules/my/team.js').default,
        myReport: require('./modules/my/report.js').default,
        //myMessage : require('./modules/my/message.js').default,
        myHelp: require('./modules/my/help.js').default,

        mySix: require('./modules/my/six.js').default
    }
})
