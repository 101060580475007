<style lang="scss">
.OpeningTransmitMudul {
    .el-input__inner {
        padding:0 7px;
    }
    .el-dialog {
        width: 680px;
        min-width: 720px;
        max-width: 720px;
    }
    .el-dialog__body {
        padding-top:15px;
    }
    table.table {
        tr td {
            padding-top:5px!important; padding-bottom:5px!important;
        }
    }
    .t-btn {
        color:#bbb;
        &:hover{
            color:#d23a3a;
        }
    }
}
</style>
<template>
    <el-dialog class="OpeningTransmitMudul" :title="title" :visible.sync="view" size="" top="5vh" :modal-append-to-body="false">
        <div class="box o-pb" style="min-height:300px;">
            <p v-if="form && form.hand" class="o-ptb">目标站点：{{ form.hand.web_name }}</p>
            <table class="table u-bl u-br u-bb">
                <thead>
                    <tr>
                        <td>项目</td>
                        <td>赔率</td>
                        <td v-if="double">最大赔率</td>
                        <td v-if="type === 'admin'">退水</td>
                        <td>有效金额</td>
                        <td>输赢</td>
                        <td>号码</td>
                        <td>走飞金额</td>
                        <td>操作</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in Params.list" v-if="Params && Params.list">
                        <td>{{ item.title }}</td>
                        <td>
                            <template v-if="item.hand">
                                <el-input v-model.number="item.odds" size="mini" style="width:60px;" />
                            </template>
                            <span v-else>
                                <span v-if="item.old_odds" class="c-color-g c-text-6">{{ item.old_odds }} ></span>
                                <b class="c-color-e">{{ item.odds }}</b>
                            </span>
                        </td>
                        <td v-if="double">
                            <el-input v-model.number="item.max_odds" size="mini" style="width:60px;" />
                        </td>
                        <td v-if="type === 'admin'">
                            <template v-if="item.hand">
                                <el-input v-model.number="item.hand.backwater_percent" size="mini" style="width:60px;" />
                            </template>
                            <span v-else>
                                {{ item.backwater }}
                            </span>
                        </td>
                        <td>{{ item.amount }}</td>
                        <td>{{ item.win }}</td>
                        <td>{{ item.code ? item.code : '-' }}</td>
                        <td>
                            <el-input v-if="!item.suc" v-model.number="item.val" size="mini" style="width:80px;" />
                            <span v-else>已成功走飞</span>
                        </td>
                        <td>
                            <Icon class="t-btn" name="roundclosefill" size="1" @click="Remove(item,index)" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="o-pt c-text-r">
            <Button @click="view = false">取消</Button>
            <Button @click="Submit()">确认走飞</Button>
        </div>
    </el-dialog>
</template>

<script>
import StoreMix from '@lottery.admin.2.0/mixin/store.modul.js'
export default {
    name: 'OpeningTransmitMudul',
    mixins: [StoreMix],
    data() {
        return {
            double: false
            //store : 'game/trader',
        }
    },
    props: {
        model: {},
        type: {
            default: 'normal' // admin
        },
        OperateAuth: {}
    },
    methods: {
        init() {
            this.CheckDouble()
        },
        Submit() {
            this.$emit('confirm', this.Params)
        },
        CheckDouble() {
            this.double = false
            if (this.OperateAuth.bind === 3 && this.Params && this.Params.list) {
                for (let item of this.Params.list) {
                    if (item.title == '三中二' || item.title == '二中特') {
                        return this.double = true
                    }
                }
            }
        },
        Remove(item, index) {
            this.Params.list.splice(index, 1)
            if (this.Params.list.length <= 0) {
                this.view = false
            }
        }
    }
}
</script>
