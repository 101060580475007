<style lang="scss">
    //@import "../style/config.scss";
    $hcolor-t:#828282;
    .pgination {
        font-size:.6rem; text-align:right; display:flex; align-items:center;
        .pgination-main {
            flex:1;
        }
        ul{
            overflow:hidden; display:inline-block;
            li {background:#fff; float:left; padding:.4rem .55rem; border:1px solid #E5E9F2; border-radius:.2rem; cursor:pointer; margin:0 .1rem;}
            li:hover {border-color:$hcolor-t;}
            li.active {
                background:$hcolor-t; border-color:$hcolor-t; color:#fff;
            }
        }
        input.go {width:2.2rem; border:1px solid #E5E9F2; border-radius:.2rem; padding:.4rem .4rem;}
        span {color:#333; margin:0 .25rem;}

        &.mini {
            ul{
                overflow:hidden; display:inline-block;
                li {background:#fff; padding:.3rem .45rem;}
                li.active {color:$hcolor-t; font-weight:bold;}
            }
        }
        .el-select .el-input  {
            input {
                padding-left:10px; padding-right:24px;
            }
            .el-select__caret {
                position: relative; right: -10px;
            }
        }
    }
</style>
<template>
    <div v-show="total > 0" class="pgination" :class="type">
        <slot />
        <div class="pgination-main">
            <ul>
                <li @click="lastPage">上一页</li>
                <li v-for="(item,index) in pageBox" :class="{'active': item == value}" @click="go(item)">{{ item }}</li>
                <li @click="nextPage">下一页</li>
            </ul>
            <ul v-if="type == 'normal'">
                <span><span v-if="count !== undefined"><b>{{ count }}</b> 条记录,</span>共 <b>{{ total }}</b> 页</span>
                <template v-if="!isMobile">
                    <span>跳至</span>
                    <input v-model.number="next" class="go" type="text" @keyup.13="go(next)">
                    <span>页</span>
                    <template v-if="pageSize">
                        <span>每页</span>
                        <el-select v-model="per_page" size="mini" style="width:60px;" @change="SizeChange">
                            <el-option label="10" :value="10" />
                            <el-option label="20" :value="20" />
                            <el-option label="50" :value="50" />
                            <el-option label="100" :value="100" />
                        </el-select>
                        <span>条</span>
                    </template>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pgination',
    data() {
        return {
            next: null,
            page: 1,
            per_page: 20
        }
    },
    props: {
        value: {
            type: Number,
            default: 1
        },
        total: {
            type: Number,
            default: 1
        },
        size: {
            type: Number,
            default: 7
        },
        type: {
            default: 'normal'
        },
        count: {
            default: undefined
        },
        pageSize: {
            default: false
        }
    },
    computed: {
        pageBox() {
            let page  = this.value

            let total = this.total
            let size  = this.size
            let start = 1
            let arr   = []
            if (total<=size) {
                // 页面不足时候直接打印
                size = total
            } else if (page<Math.ceil(size/2)) {
                start = 1
            } else if (total-page<Math.ceil(size/2)) {
                start = page - (size - (total-page)) + 1
            } else {
                start = page - Math.floor(size/2)
            }

            for (let i=0;i<size;i++) {
                arr.push(i+start)
            }
            return arr
        },
        isMobile() {
            // if(this.Opt && this.Opt.isMobile){
            //     return true
            // }
            return false
        }
    },
    watch: {
        pageSize(val) {
            this.$set(this, 'per_page', this.pageSize)
        }
    },
    methods: {
        go(page) {
            if (typeof page==='number'&&page%1===0&&page>0&&page<=this.total&&page!=this.value) {
                this.$emit('input', page)
                this.$emit('turning', page)
            }
        },
        nextPage() {
            if (this.value<this.total) {
                this.$emit('input', this.value+1)
                this.$emit('turning', this.value+1)
            }
        },
        lastPage() {
            if (this.value>1) {
                this.$emit('input', this.value-1)
                this.$emit('turning', this.value-1)
            }
        },
        SizeChange(val) {
            if (this.pageSize) {
                if (this.$parent.Filter && this.$parent.Filter.per_page) {
                    this.$set(this.$parent.Filter, 'per_page', val ? val : this.per_page)
                } else if (this.$parent.$parent.Filter && this.$parent.$parent.Filter.per_page) {
                    this.$set(this.$parent.$parent.Filter, 'per_page', val ? val : this.per_page)
                }
                this.$emit('turning', this.value)
            }
        }
    },
    mounted() {
        // this.$set(this,'per_page',this.pageSize)
    }
}
</script>