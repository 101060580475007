<style lang="scss">
    .GameCreditRow {

    }
</style>
<template>
    <div class="GameCreditRow">
        <slot />
    </div>
</template>

<script>
import credit from '../../../../../mixin/credit.js'
export default {
    name: 'GameCreditRow',
    mixins: [credit],
    data() {
        return {

        }
    },
    props: ['item', 'title']
}
</script>
