import config from './config.js'
import ajax from './ajax.js'
//import md5 from 'js-md5';
import {extend, isEmpty, deepCopy, isObject, isArray} from '../../common/helper'


export default {

    Toast(message, type='info', duration=2000, position="bottom") {
        if ((type|0)===type) {
            type = ['success', 'warning', 'info', 'error'][type]
        }
        if (this.MToast) {
            return this.MToast({message, type, duration, position});

        } else if (this.$message) {
            this.$message({
                showClose: true,
                duration,
                message,
                type
            })
        }
    },
    Notify(title, message, type="success") {
        if (this.$notify) {
            this.$notify({title, message, type})
        } else {
            return this.Toast(message);
        }
    },
    Cache(key, val, time) {
        if (key) {
            if (val===undefined && time===undefined) {
                return this.getCache(key)
            } else if ( val===null || time===0 ) {
                return this.cleanCache(key)
            } else {
                return this.setCache(key, val, time)
            }
        }
    },
    getCache(key) {
        if (window.localStorage) {
            let cache = window.localStorage[key];
            let cachetime = parseInt(window.localStorage.getItem(config.prefix+'_cache_life_'+key));
            let timestamp = new Date().getTime();
            // 检查缓存时间
            if (cachetime == 0 || cachetime > timestamp) {
                // 有效缓存
                try {
                    return JSON.parse(window.localStorage[key]);
                } catch (err) {
                    return window.localStorage[key];
                }
            } else {
                // 过期缓存
                this.cleanCache(key);
                return undefined;
            }
        } else {
            return null
        }
    },
    setCache(key, val, time) {
        if (window.localStorage) {
            // 缓存时间
            let timestamp = time>0 && this.isInt(time) ? time * 1000 + new Date().getTime() : 0;
            window.localStorage.setItem(config.prefix+'_cache_life_'+key, timestamp);
            // 返回缓存结果
            return window.localStorage[key] = JSON.stringify(val);
        } else {
            return null
        }
    },
    cleanCache(key) {
        if (window.localStorage) {
            window.localStorage.removeItem(key);
            window.localStorage.removeItem(config.prefix+'_cache_life_'+key);
            return undefined;
        }
    },
    cleanAllCache(key) {
        if (window.localStorage) {
            window.localStorage.clean();
            return undefined;
        }
    },

    timestamp(date) {
        let time = date ? new Date(date) : new Date()
        return Date.parse(time) / 1000
    },
    untimestamp(timestamp, format="yyyy-MM-dd hh:mm:ss") {
        let d = timestamp ? new Date(timestamp*1000) : new Date()
        let date = {
            "M+": d.getMonth() + 1,
            "d+": d.getDate(),
            "h+": d.getHours(),
            "m+": d.getMinutes(),
            "s+": d.getSeconds(),
            "q+": Math.floor((d.getMonth() + 3) / 3),
            "S+": d.getMilliseconds()
        };
        if (/(y+)/i.test(format)) {
            format = format.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        for (let k in date) {
            if (new RegExp("(" + k + ")").test(format)) {
                format = format.replace(RegExp.$1, RegExp.$1.length == 1
                    ? date[k] : ("00" + date[k]).substr(("" + date[k]).length));
            }
        }
        return format
    }
}