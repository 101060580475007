<style lang="scss">
    @import "./index.scss";
    .Operate {
        .info {
            height:0; padding-top:0; padding-bottom:0;
            .downtime {
                position:absolute; z-index:2; right:0; top:-3.25rem; padding-right:0;
            }
            .auto-reload-2 {
                position:absolute; z-index:2; top:4px; right:100px; padding-right:0;
            }
            .reload {
                position:absolute; z-index:2; top:4px; right:15px;
            }
            .tools {
                position:absolute; height:30px; line-height:30px; z-index:2; right:220px; top:-3.55rem; padding-right:0;
                button {
                    padding:2px 6px; font-size:.65rem; margin-left:4px;
                }
            }
        }
        .el-button.el-button--warning.reload-btn {
            color: #333;
            background-color: #94be39;
            border-color: #94be39;
            &:focus, &:hover {
                background: #81a92b;
                border-color: #81a92b;
                color: #333;
            }
            &.is-disabled {
                background-color: #81a92b;
                border-color: #81a92b;
                &:focus, &:hover {
                    background: #81a92b;
                    border-color: #81a92b;
                    color:#333;
                }
            }
        }
        .model_font {
            .el-input__inner {
                font-size: 14px;
            }
        }
    }
</style>
<template>
    <div class="Operate" :class="{'many': !onlySix && this.Opt.hasDir.game && this.Opt.hasDir.game.length > 1}">
        {{/* 游戏分类 */}}
        <div v-if="!onlySix && this.Opt.hasDir.game && this.Opt.hasDir.game.length > 1" class="tabs-router-2">
            <div class="o-plr">
                <ul>
                    <li v-for="(item,index) in tabs.clients" :id="item.name" :name="item.name" class="tab" :class="{'active': tabs.active == index}" @click="Operate(item.id)">
                        <div class="l-flex-c">
                            <span class="l-flex-1">{{ item.title }}</span>
                            <i v-if="tabs.clients.length > 1" class="el-icon-close" @click.stop="OperateClean(index)" />
                        </div>
                    </li>
                    <el-dropdown v-if="newbtn && !onlySix && tabs.clients.length < 5" trigger="click">
                        <li class="tab tab-new">
                            <div class="l-flex-c">
                                <span class="l-flex-1">新增游戏</span>
                                <i class="el-icon-circle-plus" style="color:#fff;" />
                            </div>
                        </li>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item,index) in this.Opt.hasDir.game" :key="item.id" @click.native="Operate(item.id)">{{ item.title }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </ul>
            </div>
        </div>
        <div v-if="TabItem" v-loading="TabItem.lot.loading" class="ctrl o-mb o-plr o-pb o-pb-l" style="position:relative;">
            <div>
                <div class="info o-pb l-flex-c">
                    <div v-if="ExpectCalcu && !TabItem.lot.loading && TabItem.lot.data.lottery_id" class="downtime o-pr" :class="{'l-flex-1': bind}">
                        <span v-if="ExpectCalcu.count_seal > 0" class="o-pr-l">封盘倒计时：<b class="c-color-t">{{ ExpectCalcu.seal }}</b></span>
                        <span v-else-if="ExpectCalcu.count_end > 0" class="o-pr-l c-color-t">当前已截止投注</span>
                    </div>
                    <div v-if="TabItem.lot.data.lottery_id" class="auto-reload-2 o-pr">
                        <template>
                            <span class="o-plr-s c-text-6">自动刷新</span>
                            <el-select v-model="opt.reload_interval" size="mini" style="width:80px;" @change="reload_intervalChange">
                                <el-option label="禁用" :value="0" />
                                <el-option label="10秒" :value="10000" />
                                <el-option label="30秒" :value="30000" />
                                <el-option label="60秒" :value="60000" />
                            </el-select>
                        </template>
                    </div>
                    <div class="tools">
                        {{/* 游戏切换 */}}
                        <span v-if="Running.id && Opt.hasDir.game && Opt.hasDir.game.length > 1">
                            <span>模式：</span>
                            <el-select class="o-mr model_font" :value="Running.id" style="width:7rem;" size="mini">
                                <el-option v-for="(item,index) in Opt.hasDir.game" :key="item.id" :label="item.title" :value="item.id" @click.native="ChangeGame(item)" />
                            </el-select>
                        </span>
                        {{/*  走飞工具 */}}
                        <template v-if="OperateAuth.transmit && bind === 3">
                            <template v-if="OperateAuth.admin">
                                <!--                                 <span>走飞模式:</span>
                                <el-select v-model="adminTransfer.type" size="mini" style="width:110px; position:relative; top:-1px;" @change="LoadLot(TabItem)">
                                    <el-option label="快速走飞" :value="1"></el-option>
                                    <el-option label="手动走飞" :value="0"></el-option>
                                </el-select>
                                <span style="padding-left:34px;"></span> -->
                            </template>
                            <button @click="TransferView()">走飞历史</button>
                            <button @click="MaticInfo.view = true">走飞说明</button>
                            <button v-if="!OperateAuth.admin" @click="MaticEdit()">自动走飞</button>
                        </template>
                        {{/*  及时注单 */}}
                        <template v-if="!TabItem.mode">
                            <span>期数：</span>
                            <el-select v-model="TabItem.history.target" size="mini" style="width:110px;" @change="LoadNav(TabItem)" class="model_font">
                                <el-option v-for="(item,index) in TabItem.history.list" :key="item.id" :label="item.number" :value="item.id" />
                            </el-select>
                            <span class="o-pr-s o-pl c-text-6">注单类型：</span>
                            <el-select v-model="TabItem.instant" size="mini" class="model_font" style="width:80px;" @change="LoadNav(TabItem)">
                                <el-option label="全部" :value="0" />
                                <el-option label="占成" :value="1" />
                            </el-select>
                            <span class="o-pr-s o-pl c-text-6">盘类：</span>
                            <el-select v-model="TabItem.side" size="mini" class="model_font" style="width:80px;" @change="LoadNav(TabItem)">
                                <el-option label="所有" :value="9" />
                                <el-option label="A盘" :value="0" />
                                <el-option label="B盘" :value="1" />
                                <el-option label="C盘" :value="2" />
                                <el-option label="D盘" :value="3" />
                                <el-option label="E盘" :value="4" />
                            </el-select>
                        </template>
                        {{/*  管理员 */}}
                        <button v-if="OperateAuth.admin && TabItem.mode" style="margin-left:36px;" @click="TraderStatus('bet',status.bet_status ? 0 : 1)">
                            <span v-if="status.bet_status">立即封盘</span>
                            <span v-else>重新开盘</span>
                        </button>
                    </div>
                    <div v-if="TabItem.lot.data.lottery_id" class="reload o-pl">
                        <el-button class="reload-btn" type="warning" size="mini" :loading="opt.reload_loading" :disabled="opt.reload_loading" style="width:80px;" @click="OperateReload()">刷新<span v-if="opt.reload_interval && opt.reload_down && !opt.reload_loading">({{ opt.reload_down }})</span></el-button>
                    </div>
                </div>

                {{ /* 导航头 */ }}
                <template v-if="TabItem.lot.data.lottery_id || TabItem.history.target">
                    <nav class="tabs" :style="{backgroundColor: Running.gradient_color ? Running.gradient_color.navi : undefined}">
                        <ul style="overflow:hidden; border-right: 1px solid #a1c2d4;">
                            <li v-for="(item,index) in TabItem.nav.list" class="tabs-item" :class="{'active': TabItem.nav.active == index}" @click="LoadPlay(TabItem,index)">
                                <span>{{ item.title }}(</span><b v-if="item.amount > 0" class="c-color-t">{{ item.amount }}</b><span v-else>0</span><span>)</span>
                            </li>
                        </ul>
                    </nav>
                    <div v-if="TplName" v-loading="!TabItem.play.length" class="operate c-text-65 o-pt" style="">
                        <component :is="UsePackage(TplName)" :client="TabItem" />
                    </div>
                </template>
            </div>
            <div v-if="TabItem && !TabItem.lot.data.lottery_id && !TabItem.lot.loading && (TabItem.mode || !TabItem.history.target)" v-loading="TabItem.loading" class="c-text-c o-ptb-l">
                <span class="o-pr">当前无可下注期数</span>
                <el-button type="" size="small" @click="LoadLot(TabItem)">刷新</el-button>
            </div>

            <!--  <Sound :src="Img('project/wx/sound/order.mp3')" ref="sound"></Sound>  -->
            <editer v-model="Editer.view" :title="Editer.title" :form="Editer.form" :bind="bind" :model="store" :mode="Editer.mode" />
            <transmit ref="transmit" v-model="Transmit.view" title="确认走飞列表" :form="Transmit.form" :model="store" :type="Transmit.type" :operate-auth="OperateAuth" @confirm="TransmitPush" />
            <!-- <Transfer v-model="Transfer.view" title="走飞历史" :form="Transfer.form" :model="store"></Transfer> -->
            <matic v-model="Matic.view" :title="Matic.title" :form="Matic.form" :model="store" />
            <matic-info v-model="MaticInfo.view" title="走飞说明" :form="MaticInfo.form" />
        </div>
    </div>
</template>
<script>
import OperateMix from './operate.js'
import PackMix from './packages.js'

import Editer from './modul/details.vue'
// import Transfer from './modul/transfer.vue'
import Transmit from './modul/transmit.vue'
import Matic from './modul/automatic.vue'
import MaticInfo from './modul/maticinfo.vue'

export default {
    mixins: [OperateMix, PackMix],
    components: {
        Editer,
        Transmit,
        //Transfer,
        Matic,
        MaticInfo
    },
    beforeDestroy() {
        this.OperateCleanAll()
        this.AutoReloadClean()
    }
    // beforeRouteLeave(to, from, next) {
    //     console.log('beforeRouteLeave')
    //     // 导航离开该组件的对应路由时调用
    //     // 可以访问组件实例 `this`
    //     next()
    // }
}
</script>
