<style lang="scss">
    .GameCreditLimitMudul {
        &.el-dialog__wrapper[size=big] .el-dialog {
            width: 1200px;
            max-width: 98%;
            .el-dialog__body {
                padding-top: 15px;
            }
        }
        .ctrl {
            background-color:#f0f0f0; border-radius:5px;
        }
        $mark-danger:#ff8f76;
        $mark-warning:yellow;
        table.table {
            input {
                width:46px; padding:3px 6px; border-radius:3px; outline:none; border:1px solid #ccc;
                &[state='danger'] {
                    background-color:$mark-danger;
                }
                &[state='warning'] {
                    background-color:$mark-warning;
                }
            }
            tbody {
                tr td{
                    padding-top:3px; padding-bottom:3px;
                }
                tr.pick {
                    td {
                        background-color:#ffecd5;
                    }
                }
            }
        }
        .fast {
            input {
                padding: 0 8px;
            }
        }
        .top-input{
            .el-input__inner {
                padding-left: 10px; padding-right: 10px;
            }
        }
    }
</style>
<template>
    <el-dialog class="GameCreditLimitMudul" :title="title" :visible.sync="view" size="big" top="5vh" :modal-append-to-body="false" :close-on-click-modal="false">
        <div class="l-flex-c o-p ctrl">
            <div class="l-flex-1">
                <span>游戏：</span>
                <el-select v-model="Filter.games_id" style="width:160px;" size="small">
                    <el-option v-for="(item, index) in games._list" :key="item.id" :label="item.title" :value="item.id" />
                </el-select>
            </div>

            <div class="o-mlr" style="width: 1px; height: 35px; border-left: 1px solid #BBB;" />
            <span>模板：</span>
            <el-select v-model="tpls.id" class="o-mr" style="width:160px;" size="small">
                <el-option v-for="(item, index) in tpls.list" :key="item.id" :label="item.data.title" :value="item.id" />
            </el-select>
            <Button type="danger" size="mini" @click="removeTPL()">删除</Button>
            <Button type="warning" size="mini" @click="useTPL()">使用</Button>
            
            <div class="o-mlr" style="width: 1px; height: 35px; border-left: 1px solid #BBB;" />
            <el-input v-model="tpls.title" size="mini" class="top-input" style="width: 140px; flex: 0 0 140px;" placeholder="请输入模板名称" maxlength="20" />
            <Button class="o-ml" size="small" style="flex: 0 0 100px;" @click="saveTPL">保存为模板</Button>
            
            <div class="o-mlr" style="width: 1px; height: 35px; border-left: 1px solid #BBB;" />
            <Button class="o-mr-l" size="small" @click="load()">重读数据</Button>
            <Button size="small" @click="Max()">与我同步</Button>
            <Button size="small" type="primary" @click="Submit()">提交修改</Button>
        </div>
        <!-- <div class="l-flex-c o-p ctrl" style="margin-top: 5px;">
            <div style="flex: 0 0 auto;">模板：</div>
            <div style="position: relative; height: 48px; flex: 1 0 auto;">
                <div style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; ">
                    <div class="l-flex-c" style="width: 100%; height: 100%; overflow-x: auto; flex-wrap: nowrap;">
                        <li v-for="(item, index) in tpls.list" :key="item.id" class="l-flex-c o-plr-s" style="flex: 0 0 auto;">
                            <div v-if="index > 0" style="height: 25px; border-right: 1px dashed #BBB; margin-left: 6px; margin-right: 12px;" />
                            <div class="l-flex-1 o-pr c-line-1">{{ item.data.title }}</div>
                            <Button type="danger" size="mini" @click="removeTPL(item)">删除</Button>
                            <Button type="warning" size="mini" @click="useTPL(item)">使用</Button>
                        </li>
                        <span v-if="tpls.list.length === 0">暂无可用模板</span>
                    </div>
                </div>
            </div>
            <div style="width: 1px; height: 48px; border-left: 1px solid #AAA;" />
            <el-input v-model="tpls.title" size="mini" class="top-input o-ml" style="width: 140px; flex: 0 0 140px;" placeholder="请输入模板名称" maxlength="20" />
            <Button class="o-ml" size="small" style="flex: 0 0 100px;" @click="saveTPL">保存为模板</Button>
        </div> -->
        <div class="l-flex-c o-ptb">
            <span class="o-pl-l" />
            <el-checkbox v-model="table.pickall" @change="PickAll($event)" />
            <span class="o-plr">全选</span>
            <div class="l-flex-1 l-flex-c">
                <span class="o-pr-s o-pl">A盘:</span>
                <el-input v-model="table.A" size="mini" class="top-input" style="width:3.2rem;" />
                <span class="o-pr-s o-pl">B盘:</span>
                <el-input v-model="table.B" size="mini" class="top-input" style="width:3.2rem;" />
                <span class="o-pr-s o-pl">C盘:</span>
                <el-input v-model="table.C" size="mini" class="top-input" style="width:3.2rem;" />
                <span class="o-pr-s o-pl">D盘:</span>
                <el-input v-model="table.D" size="mini" class="top-input" style="width:3.2rem;" />
                <span class="o-pr-s o-pl">E盘:</span>
                <el-input v-model="table.E" size="mini" class="top-input" style="width:3.2rem;" />

                <span style="padding-left:16px; padding-right:6px;">单注限额:</span>
                <el-input v-model="table.order_max_amount" size="mini" style="width:5.3rem;" />
                <span style="padding-left:12px; padding-right:6px;">下注限额:</span>
                <el-input v-model="table.bets_limit_amount" size="mini" style="width:5.3rem;" />
            </div>
            <Button size="mini" plain @click="FastLoad()">清除</Button>
            <Button size="mini" @click="FastSubmit()">传输</Button>
        </div>
        <div v-loading="main._loading">
            <table v-loading="main._editing" class="table" border="0" cellspacing="1" cellpadding="0" style="border-collapse:collapse;">
                <thead>
                    <tr style="text-align:left;">
                        <td style="text-align:center;">选择</td>
                        <td style="text-align:left;">玩法</td>
                        <td>A盘</td>
                        <td>B盘</td>
                        <td>C盘</td>
                        <td>D盘</td>
                        <td>E盘</td>
                        <td>单注限额</td>
                        <td>下注限额</td>
                    </tr>
                </thead>
                <tbody style="text-align:left;">
                    <tr v-for="(item,index) in table.list" :class="{'pick': item.pick}">
                        <td style="text-align:center;">
                            <el-checkbox v-model="item.pick" />
                        </td>
                        <td>{{ item.name }}</td>
                        <td><input v-model="item.backwater_a" v-maxlimit="item" name="backwater_a"><span class="c-color-e" style="padding-left:4px;">({{ item.limit.backwater_a == 0 ? '-' : Price(item.limit.backwater_a,{separate: false}) }})</span></td>
                        <td><input v-model="item.backwater_b" v-maxlimit="item" name="backwater_b"><span class="c-color-e" style="padding-left:4px;">({{ item.limit.backwater_b == 0 ? '-' : Price(item.limit.backwater_b,{separate: false}) }})</span></td>
                        <td><input v-model="item.backwater_c" v-maxlimit="item" name="backwater_c"><span class="c-color-e" style="padding-left:4px;">({{ item.limit.backwater_c == 0 ? '-' : Price(item.limit.backwater_c,{separate: false}) }})</span></td>
                        <td><input v-model="item.backwater_d" v-maxlimit="item" name="backwater_d"><span class="c-color-e" style="padding-left:4px;">({{ item.limit.backwater_d == 0 ? '-' : Price(item.limit.backwater_d,{separate: false}) }})</span></td>
                        <td><input v-model="item.backwater_e" v-maxlimit="item" name="backwater_e"><span class="c-color-e" style="padding-left:4px;">({{ item.limit.backwater_e == 0 ? '-' : Price(item.limit.backwater_e,{separate: false}) }})</span></td>
                        <td><input v-model="item.order_max_amount" v-maxlimit="item" name="order_max_amount" style="width:80px;"><span class="c-color-e" style="padding-left:4px;">({{ Price(item.limit.order_max_amount,{separate: false}) }})</span></td>
                        <td><input v-model="item.bets_limit_amount" v-maxlimit="item" name="bets_limit_amount" style="width:80px;"><span class="c-color-e" style="padding-left:4px;">({{ Price(item.limit.bets_limit_amount,{separate: false}) }})</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="c-text-r o-p">
            <Button class="o-mr-l" size="small" @click="load()">重读数据</Button>
            <Button size="small" @click="Max()">与我同步</Button>
            <Button size="small" type="primary" @click="Submit()">提交修改</Button>
        </div>

        <!-- <div v-show="tpls.status" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 21; background-color: rgba(0, 0, 0, .65);" @click.self="tpls.status = 0">
            <div class="l-flex-v" style="width: 100%; height: 450px; position: absolute; left: 0; right: 0; bottom: 0; padding: 18px 15px 15px 15px;  background-color: #FFF; border-radius: 12px 12px 0 0; box-sizing: border-box;">
                <div v-show="tpls.status === 1" class="l-flex-1" style="opacity: auto;">
                    <li v-for="item in tpls.list" :key="item.id" class="l-flex-c o-mtb-s">
                        <div class="l-flex-1 o-pr c-line-1">{{ item.data.title }}</div>
                        <Button type="danger" size="small" @click="removeTPL(item)">删除</Button>
                        <Button type="warning" size="small" @click="useTPL(item)">使用</Button>
                    </li>
                    <li v-if="tpls.list.length === 0" class="o-p-l c-color-g c-text-c">当前游戏下暂无模板</li>
                </div>
                <div v-show="tpls.status === 2" class="l-flex-1 l-flex-v">
                    <p class="o-ptb c-text-c">建议先提交当前限额配置后再保存为模板</p>
                    <input v-model="tpls.title" placeholder="请输入模板名称" maxlength="20" style="width: 200px; font-size: 14px; height: 40px; line-height: 40px; display: block; margin: 0 auto;" />
                </div>
                <div class="o-p c-text-c">
                    <Button v-show="tpls.status === 1" type="info" @click="tpls.status = 0">取消</Button>
                    <Button v-show="tpls.status === 1" @click="tpls.status = 2; tpls.title = '';">保存当前配置为模板</Button>
                    <Button type="info" v-show="tpls.status === 2" @click="tpls.status = 1">取消</Button>
                    <Button v-show="tpls.status === 2" @click="saveTPL">保存</Button>
                </div>
            </div>
        </div> -->
    </el-dialog>
</template>
<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
export default {
    name: 'GameCreditLimitMudul',
    mixins: [StoreMix],
    data() {
        return {
            store: 'myAccount/gamesgroup',
            user: {},
            Filter: {
                uid: null,
                games_id: 37
            },
            Params: {
                action: 'membersodds',
                uid: null
            },
            table: {
                length: 1,
                style: '860px',
                list: [],
                pickall: false,
                A: null,
                B: null,
                C: null,
                D: null
            },
            fastOpt: [
                {key: '--', val: null}, {key: '1', val: 1}, {key: '0.5', val: 0.5}, {key: '0.1', val: 0.1}, {key: '0.05', val: 0.05}, {key: '0.01', val: 0.01},
                {key: '0', val: 0}, {key: '-0.01', val: -0.01}, {key: '-0.05', val: -0.05}, {key: '-0.1', val: -0.1}, {key: '-0.5', val: -0.5}, {key: '-1', val: -1}
            ],
            tpls: {
                list: [],
                id: undefined,
                keys: ['backwater_a', 'backwater_b', 'backwater_c', 'backwater_d', 'backwater_e', 'order_max_amount', 'bets_limit_amount'],
                title: '',
                status: 1
            }
        }
    },
    computed: {
        games() {
            return this.$store.state.game.games
        }
    },
    watch: {
        // 用游戏 ID 获取分组 1
        'Filter.games_id'(val) {
            if (val) {
                this.load()
                this.loadTPL()
            }
        }
    },
    directives: {
        maxlimit: {
            update(el, binding, vnode, oldvnode) {
                let {value, oldValue} = binding
                let name = el.getAttribute('name')
                let val = Number(value[name])
                let limit = value.limit ? Number(value.limit[name]) : null
                let origin = Number(value.origin[name])
                if (val == origin) {
                    el.setAttribute('state', 'origin')
                } else if (limit && limit < val) {
                    el.setAttribute('state', 'danger')
                } else {
                    el.setAttribute('state', 'warning')
                }
            }
        }
    },
    methods: {
        init() {
            if (this.Params.id) {
                let id = this.Params.id
                this.$set(this.Filter, 'uid', id)
            }
            this.load()
            this.loadTPL()
        },
        load() {
            this.DataReset('table')
            this.Dp('myAccount/_GET_GAMESGROUP', {params: this.Filter}).then(res => {
                if (res) {
                    let list = []
                    let limit = {}
                    for (let item of res.data.max_data) {
                        limit[item.mark_id] = item
                    }
                    for (let item of res.data.data) {
                        let obj = {
                            ...item,
                            limit: {
                                ...limit[item.mark_id] ? limit[item.mark_id] : {}
                            },
                            origin: {
                                ...this.Origin(item)
                            }
                        }
                        list.push(obj)
                    }
                    this.$set(this.table, 'list', list)
                }
            })
        },
        FastLoad() {
            this.$set(this.table, 'bets_limit_amount', null)
            this.$set(this.table, 'order_max_amount', null)
            this.$set(this.table, 'A', null)
            this.$set(this.table, 'B', null)
            this.$set(this.table, 'C', null)
            this.$set(this.table, 'D', null)
            this.$set(this.table, 'E', null)
        },
        FastSubmit() {
            let bet = parseFloat(this.table.bets_limit_amount)
            let order = parseFloat(this.table.order_max_amount)
            for (let item of this.table.list) {
                if (item.pick) {
                    if (bet >= 0) {
                        this.$set(item, 'bets_limit_amount', this.FastInputRange(bet, item, 'bets_limit_amount'))
                    }
                    if (order >= 0) {
                        this.$set(item, 'order_max_amount', this.FastInputRange(order, item, 'order_max_amount'))
                    }
                    for (let key of ['A', 'B', 'C', 'D', 'E']) {
                        let value = this.table[key]
                        if (value !== '' && value !== null && value >= 0) {
                            let name = `backwater_${key.toLowerCase()}`
                            this.$set(item, name, this.FastInputRange(value, this.Origin(item), name))
                        }
                    }
                }
            }
        },
        FastInputRange(num, item, key) {
            if (num <= 0) {
                return 0
            }
            num = parseFloat(num)
            if (item && item.limit && item.limit[key] >= 0) {
                let max = parseFloat(item.limit[key])
                if (num > max) {
                    return max
                }
            }
            return num
        },
        Max() {
            for (let row of this.table.list) {
                for (let key of ['backwater_a', 'backwater_b', 'backwater_c', 'backwater_d', 'backwater_e', 'bets_limit_amount', 'order_max_amount']) {
                    if (row.limit && row.limit[key] && row[key] != row.limit[key]) {
                        this.$set(row, key, row.limit[key])
                    }
                }
            }
        },
        loadTPL() {
            this.Dp('myTeam/_GET_USER_LIMIT_TPL', {params: {games_id: this.Filter.games_id}}).then(res => {
                if (res) {
                    this.$set(this.tpls, 'list', res.data.map(item => {
                        try {
                            item.data = JSON.parse(item.str_data)
                        } catch (e) {
                            item.data = {title: '', list: []}
                        }
                        return item
                    }))
                    if (this.tpls.id) {
                        const item = this.tpls.list.find(row => row.id == this.tpls.id)
                        if (!item) this.tpls.id = undefined
                    }
                    if (!this.tpls.id && this.tpls.list[0]) {
                        this.tpls.id = this.tpls.list[0].id
                    }
                }
            })
        },
        saveTPL() {
            const {title} = this.tpls
            if (!title) return this.Msg('请输入模板名称')
            const data = {title, list: this.table.list.map(item => {
                const obj = {}
                for (const key of this.tpls.keys) {
                    obj[key] = item[key]
                }
                return obj
            })}
            this.Dp('myTeam/_POST_USER_LIMIT_TPL', {data: {games_id: this.Filter.games_id, str_data: JSON.stringify(data)}}).then(res => {
                if (res) {
                    this.tpls.title = ''
                    this.tpls.status = 1
                    this.Suc('保存成功')
                    this.loadTPL()
                }
            })
        },
        removeTPL(item) {
            if (!item && this.tpls.id) item = this.tpls.list.find(row => row.id == this.tpls.id)
            if (!item) return this.Err('请选择模版')
            this.Confirm(() => {
                this.Dp('myTeam/_DELETE_USER_LIMIT_TPL', {id: item.id}).then(res => {
                        if (res) {
                            this.tpls.status = 1
                            this.Suc('提交成功')
                            this.loadTPL()
                        }
                    })
            }, '是否确认移除选定数据?', '移除模版')
        },
        useTPL(item) {
            if (!item && this.tpls.id) item = this.tpls.list.find(row => row.id == this.tpls.id)
            if (!item) return this.Err('请选择模版')
            try {
                const list = JSON.parse(item.str_data).list
                list.forEach((item, index) => {
                    if (this.table.list[index]) {
                        for (const key of this.tpls.keys) {
                            this.table.list[index][key] = item[key]
                        }
                    }
                })
                this.Suc('模板数据已应用，请确认无误后再点击 “提交修改”')
                this.tpls.status = 0
            } catch (err) {
                this.Msg('数据损坏，无法套用数据')
                console.log(err)
            }
        },
        Submit() {
            let data = []
            for (let item of this.table.list) {
                let keys = ['backwater_a', 'backwater_b', 'backwater_c', 'backwater_d', 'backwater_e', 'order_max_amount', 'bets_limit_amount']
                for (let key of keys) {
                    if (item[key] != item.origin[key]) {
                        let obj = {
                            id: item.id,
                            mark_id: item.mark_id
                        }
                        for (let k of keys) {
                            obj[k] = item[k]
                        }
                        data.push(obj)
                        break
                    }

                }
            }
            if (data.length) {
                this.Dp('myAccount/_POST_GAMESGROUP', {
                    data: {
                        uid: this.Params.uid,
                        games_id: this.Filter.games_id,
                        data: JSON.stringify(data)
                    }
                }).then(res => {
                    this.Suc('限额已修改成功')
                    this.load()
                })
            } else {
                this.Suc('当前页面无可提交的数据', '提交成功', 'warning')
            }
        },
        PickAll(e) {
            for (let item of this.table.list) {
                this.$set(item, 'pick', e)
            }
        }
    },
    components: {

    }
}
</script>
