
<template>
    <div class="OperateNavTable">
        <div class="l-flex">
            <div class="l-flex-1">
                <group :client="client" :list="Table[1]" :win="false" :length="1" />
            </div>
            <div class="l-flex-1" />
            <div class="l-flex-1" />
            <div class="l-flex-1" />
            <div class="l-flex-1" />
        </div>
        <BoardFast :client="client" :group="[1]" :step="true" :default-step="0.1" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperatePks1',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group
    }
}
</script>
