<style lang="scss">
    @import "../style/config.scss";
    .Sound {
        audio {
            display:none;
        }
    }
</style>
<template>
    <a class="Sound" @click="play()">
        <audio ref="audio" :src="src" :autoplay="false" />
    </a>
</template>

<script>
export default {
    name: 'Sound',
    data() {
        return {

        }
    },
    props: {
        src: {}
    },
    computed: {

    },
    methods: {
        play() {
            this.$refs['audio'].play()
            //window.aa =this.$refs['audio']
        }
    }
}
</script>