<style lang="scss">
    .Load {
        display:inline-block; position:relative; vertical-align:middle; text-align:center;
        .core {
            display:inline-block; position:relative; width:2rem; height:2rem; vertical-align:middle; margin:0 auto;
        }
        .circular {
            height:100%; width:100%;
            animation:rotate 2s linear infinite; transform-origin:center center;
            position:absolute; top:0; bottom:0; left:0; right:0;
        }
        .path {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
            animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
            stroke-linecap: round;
            stroke:#333;
        }
        @keyframes rotate {
            100% {
                transform: rotate(360deg);
            }
        }
        @keyframes dash {
            0% {stroke-dasharray:1, 200; stroke-dashoffset:0;}
            50% {stroke-dasharray:89, 200; stroke-dashoffset:-35px;}
            100% {stroke-dasharray:89, 200; stroke-dashoffset:-124px;}
        }
        &.full {
            position:absolute; top:0; bottom:0; left:0; right:0; width:100%; height:100%; z-index:100;
            display:flex; justify-content:center; align-items:Center;
        }
        .fast {
            .circular {
                animation:rotate 1.2s linear infinite;
            }
        }
    }
</style>
<template>
    <span class="Load" :class="{'full': full}">
        <span class="core" :class="speed" :style="SizeStyle">
            <svg class="circular" viewBox="25 25 50 50">
                <circle class="path" :style="ColorStyle" cx="50" cy="50" r="20" fill="none" :stroke-width="weight" stroke-miterlimit="10" />
            </svg>
        </span>
    </span>
</template>

<script>
export default {
    name: 'Load',
    props: {
        size: {
            default: 2
        },
        weight: {
            default: 2.5
        },
        full: {
            default: false
        },
        color: {
            default: '#333'
        },
        speed: {
            default: 'normal'
        }
    },
    computed: {
        SizeStyle() {
            let size = this.size.toString()
            if (size.indexOf('px')>=0 || size.indexOf('pt')>=0 || size.indexOf('em')>=0 || size.indexOf('rem')>=0 || size.indexOf('vh')>=0 || size.indexOf('vw')>=0 || size.indexOf('%')>=0) {
                return `width:${size}; height:${size};`
            }
            return `width:${size}rem; height:${size}rem;`
        },
        ColorStyle() {
            if (this.color) {
                return `stroke:${this.color};`
            }
            return ''
        }
    }
}
</script>