import Vue from 'vue'

import dgx from '~/dgx_z/index.js'
import ajax from '@lottery.2.0/library/ajax.js'

const store = 'admin';
const STORE = store.toUpperCase();
import {origin} from '~/helper'

export default dgx({
    store,
    state: {
        backwater: {
            title: '基础退水',
            _options: {
                url: 'trader/backwater'
            }
        },
        output_lhc: {
            title: '开奖历史',
            _options: {
                url: 'games/lottery'
            }
        },
        process: {
            title: '开奖进度',
            _options: {
                url: 'index/process'
            }
        },
        settings: {
            title: '系统配置',
            _options: {
                url: 'settings'
            }
        },
        autoodds: {
            title: '自动赔率',
            _options: {
                url: 'trader/autoodds'
            }
        },

        order: {
            title: '订单',
            _options: {
                url: 'games/order'
            }
        },
        orderinfo: {
            title: '订单',
            _options: {
                url: 'games/order/info'
            }
        },
        improper: {
            title: '自选不中',
            _options: {
                url: 'trader/optional'
            }
        },


        oddsbase: {
            title: '游戏分组',
            _options: {
                url: 'trader/baseodds'
            }
        },


        subset: {
            title: '玩法子集',
            _options: {
                url: 'games/playsplus/subset'
            }
        },

        announ: {
            title: '平台公告',
            _options: {
                url: 'announcement'
            }
        },

        account: {
            title: '子帐号',
            _options: {
                url: 'user/account'
            }
        }
    },
    actions: {
        GET_ODDSBASE({state, dispatch, commit}, {id, params}) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `trader/baseodds/${id}`,
                params
            })
        },
        AUTO_OUTPUT_NUMBER({state, dispatch, commit}, params) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `games/lottery/next`,
                params
            })
        },
        DELETE_ORDER({state, dispatch, commit}, data) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'PUT',
                url: `games/order/del`,
                model: 'orderinfo',
                data
            })
        }
    },
    mutations: {

    }
}, ajax)
