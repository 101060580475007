<style lang="scss">
.OpeningAutomaticMudul {
    .el-dialog__body {
        padding-top:0;
    }
    table.table {
        input {
            padding:4px 6px; background-color:#fff; outline:none; border:1px solid #ccc; border-radius:5px;
        }
        tr td {
            padding-top:4px!important; padding-bottom:4px!important;
        }
    }
}
</style>
<template>
    <el-dialog class="OpeningAutomaticMudul" :title="title" :visible.sync="view" size="big" top="5vh" :modal-append-to-body="false">
        <div v-if="form">
            <h6 class="o-pt-s o-pb-l">
                <b>{{ form.title }}</b>
            </h6>
            <table class="table u-bl u-br u-bb">
                <thead>
                    <tr>
                        <td>玩法名称</td>
                        <td>是否启用</td>
                        <td>自动走飞金额</td>
                        <td>最后更新时间</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in Table">
                        <td>{{ item.name }}</td>
                        <template v-if="item.name.substr(item.name.length - 1,1) === 'B'">
                            <td colspan="3">(特码 B 合并入特码 A 中，以特码 A 中金额为准)</td>
                        </template>
                        <template v-else>
                            <td>
                                <el-checkbox v-if="item.support" v-model="item.auto_transfer" :true-label="1" :false-label="0" />
                                <span v-else>暂不支持</span>
                            </td>
                            <td><input v-model="item.auto_transfer_amount" style="width:120px;"></input></td>
                            <td>{{ item.update_at ? item.update_at : '-' }}</td>
                        </template>
                    </tr>
                </tbody>
            </table>
            <div class="o-pt c-text-r">
                <Button type="" @click="Reload()">重读数据</Button>
                <Button type="primary" @click="Submit()">保存配置</Button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import StoreMix from '@lottery.admin.2.0/mixin/store.modul.js'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'OpeningAutomaticMudul',
    mixins: [StoreMix, SixMix],
    data() {
        return {
            Params: {},
            Table: []
        }
    },
    props: {
        model: {}
    },
    computed: {
        store() {
            return `${this.model}/matic`
        }
    },
    watch: {
        active(val) {
            this.all = false
        }
    },
    methods: {
        init() {
            this.$set(this, 'Table', [])
            this.Reload()
        },
        Reload() {
            this.Get(1, undefined, {games_id: this.form.id}).then(res => {
                for (let item of res.data) {
                    this.$set(item, '_auto_transfer', item.auto_transfer)
                    this.$set(item, '_auto_transfer_amount', item.auto_transfer_amount)
                }
                this.$set(this, 'Table', res.data)
            })
        },
        Submit() {
            let data = []
            for (let item of this.Table) {
                if (item.auto_transfer != item._auto_transfer || item.auto_transfer_amount != item._auto_transfer_amount) {
                    data.push({
                        id: item.id,
                        name: item.name,
                        support: item.support,
                        auto_transfer: item.auto_transfer,
                        auto_transfer_amount: item.auto_transfer_amount
                    })
                }
            }
            if (data.length) {
                this.Dp(`${this.model}/_POST_MATIC`, {data: {games_id: this.form.id, data: JSON.stringify(data)}}).then(res => {
                    this.Suc('自动走飞配置已保存')
                    this.$emit('finish', res ? res : 1)
                    this.Reload()
                })
            } else {
                this.Err('没有需要更改的选项')
            }
        }
    },
    mounted() {
        // this.init()
    }
}
</script>
