import Vue from 'vue'

import dgx from '~/dgx_z/index.js'
import ajax from '@lottery.2.0/library/ajax.js'

import Methods from '~/methods';

const store = 'game';
const STORE = store.toUpperCase();
import {origin} from '~/helper'

import Events from 'events' //.EventEmitter;
const EventEmitter = Events.EventEmitter


export default dgx({
    store,
    state: {
        Event: new EventEmitter(),
        init: {
            ready: false,
            category: {},
            games: {},
            _options: {}
        },
        play: {
            category: null,
            games: null,
            play_code: null
        },
        statis: {
            _options: {} // 出码排行
        },
        plan: {
            _options: {} // 追号计划
        },
        running: {},
        expect: {
            sync: false,
            sales: false,
            time: null,
            count: {
                close: null,
                end: null
            },
            now: {},
            next: {},
            last: {}
        },
        rules: {
            view: false,
            code: null,
            data: {},
            _options: {}
        },
        // GameGoodDB : {},

        games: {
            title: '玩法种类',
            _options: {
                url: 'games/index'
            }
        },
        group: {
            title: '玩法分类',
            _options: {
                url: 'games/index/type'
            }
        },
        nav: {
            title: '玩法导航',
            _options: {
                url: 'games/plays'
            }
        },
        odds: {
            title: '请求赔率',
            _options: {
                url: 'games/plays'
            }
        },
        improper: {
            title: '自选不中热门号码',
            _options: {
                url: 'trader/optional'
            }
        },


        remain: {
            title: '剩余期数',
            _options: {
                url: 'games/period'
            }
        },
        history: {
            title: '开奖记录',
            _options: {
                url: 'games/lottery'
            }
        },
        order: {
            title: '注单记录',
            _options: {
                url: 'games/order'
            }
        },
        ordersum: {
            title: '注单记录',
            _options: {
                url: 'games/order/summation'
            }
        },

        // 操盘
        olot: {
            title: '游戏期数',
            _options: {
                url: 'games/period'
            }
        },

        onav: {
            title: '操盘导航',
            _options: {
                url: 'trader/ginstant'
            }
        },
        oplay: {
            title: '操盘列表',
            _options: {
                url: 'trader/ginstant'
            }
        },
        odata: {
            title: '走飞列表',
            _options: {
                url: 'trader/ginstant'
            }
        },
        inav: {
            title: '及时注单',
            _options: {
                url: 'trader/ginstant'
            }
        },
        iplay: {
            title: '及时注单',
            _options: {
                url: 'trader/ginstant'
            }
        },

        jtinit: {
            title: '集团状态',
            _options: {
                url: 'jttop/other/transferstatus'
            }
        },
        jtdata: {
            title: '集团走飞列表',
            _options: {
                url: 'jttop/transfer'
            }
        },

        transfer: {
            title: '提交走飞',
            _options: {
                url: 'trader/gtransfer'
            }
        },
        htransfer: {
            title: '手动走飞',
            _options: {
                url: 'trader/htransfer'
            }
        },

        trader: {
            title: '及时注单详情',
            _options: {
                url: 'games/bets/trader'
            }
        },
        tradersum: {
            title: '及时注单合计',
            _options: {
                url: 'games/order/summation'
            }
        },
        otrader: {
            title: '操盘订单详情',
            _options: {
                url: 'games/order'
            }
        },
        otradersum: {
            title: '操盘注单合计',
            _options: {
                url: 'games/order/summation'
            }
        },

        // transferhistory : {
        //     title : '走飞历史',
        //     _options : {
        //         url : 'games/bets/transfer',
        //     },
        // },
        // transferhistorysum : {
        //     title : '走飞历史合计',
        //     _options : {
        //         url : 'games/bets/transfersum',
        //     },
        // },

        matic: {
            title: '走飞设置',
            _options: {
                url: 'user/automatic'
            }
        },
        rule: {
            title: '规则说明',
            _options: {
                url: 'games/rule'
            }
        },
        tsite: {
            title: '走飞站点',
            _options: {
                url: 'index/website'
            }
        },
        tips: {
            title: '长龙提示',
            _options: {
                url: 'games/outcode'
            }
        }
    },
    getters: {
        category: (state, getters) => {
            let list = {}
            for (let i in state.init.category) {
                let item = state.init.category[i]
                list[i] = {
                    ...state.init.category[i],
                    games: []
                }
            }
            let games = state.init.games
            for (let i in games) {
                let game = origin(games[i])
                for (let key in list) {
                    if (list[key].id == game.group) {
                        list[key].games.push(game)
                    }
                }
            }
            // 删除多余部分(分类下没有游戏的)
            for (let i in list) {
                if (!list[i].games.length) {
                    delete list[i]
                }
            }
            return list
        },
        games: (state, getters) => {
            return state.init.games
        },
        running: (state, getters) => {
            let expect = state.expect
            let running = state.running
            return {
                expect,
                ...running
            }
        }
    },
    actions: {
        POST_TRADER({state, dispatch, commit}, data={}) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'POST',
                url: `trader/gtrader`,
                data
            })
        },
        RESET_GAME({state, dispatch, commit}, data={}) {
            Vue.set(state.running, 'id', null)
            state.Event.emit('RESET_GAME')
        },
        // 初始化游戏列表、分类列表
        INIT_GAMES({state, dispatch, commit}, data={}) {
            data.limit = 9999
            if (state.init.ready) {
                return state.init.games
            } else {
                return dispatch('_GET_GAMES').then(games => {
                    return dispatch('_GET_GROUP').then(res => {
                        if (res && res.data) {
                            let obj = {}
                            for (let item of res.data) {
                                obj[item.title] = {
                                    ...item
                                }
                            }
                            commit(`_${STORE}_UPDATE`, {base: 'init', key: 'category', value: obj})
                        }
                        return dispatch(`_GET_GAMES`, {
                            data
                        }).then(({data}) => {
                            commit('RELOAD_GAMES', data) // 继承并存储游戏数据
                            return data
                        })
                    })
                })
            }
        },
        NAV_INIT({state, dispatch, commit}, data={}) {
            Vue.set(state.running, 'nav', {active_id: null, active_title: '', list: [], item: null, table: null, side: null, loading: true})
            Vue.set(state.running, 'play', {list: [], belong: null, loading: true, odds: {}, odds_loading: true})
            return dispatch(`_GET_NAV`, {
                params: {
                    play_type: data.play_type,
                    games_type: data.game_type,
                    games_model: data.game_model
                }
            }).then(res => {
                Vue.set(state.running.nav, 'list', res.data)
                state.Event.emit('NAV_INIT', res.data)
                return res.data
            })
        },
        NAV_CHANGE({state, dispatch, commit}, {table, item}) {
            if (table) {
                Vue.set(state.running.nav, 'loading', true)
                Vue.set(state.running.play, 'list', [])
                return dispatch(`_GET_NAV`, {
                    params: {
                        games_group_id: item.id,
                        play_type: {'credit': 2, 'formal': 1}[state.running.type],
                        games_type: state.running.group,
                        games_model: state.running.model
                    }
                }).then(res => {
                    Vue.set(state.running.nav, 'item', item)
                    Vue.set(state.running.nav, 'active_id', item.id)
                    Vue.set(state.running.nav, 'active_title', item.title)
                    Vue.set(state.running.nav, 'table', res.data)
                    Vue.set(state.running.nav, 'loading', false)
                    state.Event.emit('NAV_CHANGE', {table, data: item})
                    return item
                })
            } else {
                state.Event.emit('NAV_CHANGE', {table, data: item})
                return item
            }
        },
        NAV_TABLE({state, dispatch, commit}, item) {
            Vue.set(state.running.nav, 'side', item)
        },

        // 游戏列表初始化
        GOODS_RELOAD({state, dispatch, commit}, {id, nav}) {
            Vue.set(state.running.play, 'list', [])
            // 查询缓存
            let cache = Methods.Cache(`Plays:${id}`)
            let useCache = false // true
            // console.log("GOODS_RELOAD 1", { id, nav }, {iscache: cache && cache.length && useCache})
            if (cache && cache.length && useCache) {
                //console.log('Send GOODS_RELOAD')
                return dispatch('GOODS_RELOAD_ODDS', {id, data: cache, cache, nav}).then(list => {
                    state.Event.emit('GOODS_RELOAD', {id, nav, data: cache})
                    return list
                })
            } else {
                Vue.set(state.running.play, 'loading', true)
                let type = state.running.type
                return dispatch(`_${STORE}_AJAX`, {
                    method: 'GET',
                    url: `games/plays/${id}`,
                    model: 'init',
                    params: {
                        games_id: state.running.id,
                        games_type: state.running.group,
                        games_model: state.running.model
                    }
                }).then(res => {
                    // console.log('Send GOODS_RELOAD 2 nocache', {id, res, nav})
                    //.Event.emit('GOODS_RELOAD',{ id, nav, data:res.data })
                    return dispatch('GOODS_RELOAD_ODDS', {id, data: res.data, nav}).then(list => {
                        state.Event.emit('GOODS_RELOAD', {id, nav, data: cache})
                        return list
                    })
                })
            }
        },

        // 游戏赔率初始化
        GOODS_RELOAD_ODDS({state, dispatch, commit}, {id, data, cache, nav}) {
            //console.log("GOODS_RELOAD_ODDS 1",{ id, data, cache, nav } )
            if (!cache) {
                Methods.Cache(`Plays:${id}`, data) // 如果不是缓存，则进行缓存数据
            }
            let ids = []
            var NextFloor = (subset, parent) => {
                let list = []
                if (subset) {
                    for (let item of subset) {
                        let obj = {
                            id: item.games_play_id || item.id,
                            games_play_group_id: nav.id,
                            level: parent ? parent.level + 1 : 1,
                            title: item.title
                        }
                        if (state.running.type === 'formal') {
                            obj = {
                                ...obj,
                                ...item
                            }
                        }
                        if (item.format) {
                            obj.form = item.format.side
                            if (item.format.subset) {
                                obj.format = item.format.subset
                            }
                        }
                        if (parent) {
                            obj.belong = parent.title
                            if (!obj.games_play_group_id) {
                                obj.games_play_group_id = parent.games_play_group_id
                                //console.log('GOODS_RELOAD_ODDS 1',obj.games_play_group_id)
                            } else {
                                //console.log('GOODS_RELOAD_ODDS 2',obj.games_play_group_id)
                            }
                        } else {
                            obj.id = obj.id || obj.games_play_group_id
                        }

                        for (let key of ['code', 'form', 'name', 'odds', 'max_odds', 'has_double_odds', 'code', 'view_mark']) {
                            if (item[key]) {
                                obj[key] = item[key]
                            }
                        }
                        if (item.subset && !item.algorithm) {
                            obj.child = NextFloor(item.subset, obj)
                        } else {
                            obj.disabled = false
                            obj.pick = false
                            if (item.algorithm) {
                                obj.formal = {...item}
                            }
                            ids.push(obj.id)
                        }
                        list.push(obj)
                    }
                }
                return list
            }

            let plays = NextFloor(data)
            Vue.set(state.running.play, 'list', plays)
            Vue.set(state.running.play, 'belong', id)
            Vue.set(state.running.play, 'loading', false)
            // console.log("GOODS_RELOAD_ODDS 2", plays)
            state.Event.emit('GOODS_RELOAD_ODDS', {id, data, cache, nav, res: plays})
            return plays
        },


        // 游戏赔率重载
        GOODS_UPDATE_ODDS({state, dispatch, commit}, {plays=[], nav, ids, lottery_id, games_id}) {
            // console.log('GOODS_UPDATE_ODDS', nav, ids, lottery_id, games_id)
            // if(!plays || plays.length == 0){
            //     return false
            // }
            Vue.set(state.running.play, 'odds_loading', true)
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `games/odds`,
                model: 'odds',
                params: {
                    ids: ids.join(','),
                    games_id,
                    lottery_id,
                    side: state.running.nav.side.id
                }
            }).then(res => {
                Vue.set(state.running.play, 'odds_loading', false)
                for (let item of res.data) {
                    let row = state.running.play.odds[item.id]
                    if (row) {
                        Vue.set(state.running.play.odds, item.id, {
                            _odds: row.odds,
                            _max_odds: row.max_odds,
                            odds: parseFloat(item.odds),
                            max_odds: parseFloat(item.max_odds),
                            has_double_odds: !!item.has_double_odds
                        })
                    } else {
                        Vue.set(state.running.play.odds, item.id, {
                            odds: parseFloat(item.odds),
                            max_odds: parseFloat(item.max_odds),
                            has_double_odds: !!item.has_double_odds
                        })
                    }
                }
                state.Event.emit('GOODS_UPDATE_ODDS', {plays, nav, ids, lottery_id, data: state.running.play.odds})
                return plays
            })
        },
        // 游戏赔率清除
        GOODS_CLEAN_ODDS({state, dispatch, commit}, data) {
            if (state.running.play && state.running.play.odds) {
                for (let id in state.running.play.odds) {
                    Vue.delete(state.running.play.odds, id)
                }
            }
        },

        // 游戏直播数据
        LIVE_GAME({state, dispatch, commit}, data={}) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `games/period`,
                model: 'init',
                params: data
            }).then(({data}) => {
                commit('RUNNING_SET', {live: data})
                return data
            })
        },

        // 下单（批量）
        POST_ORDER({state, dispatch, commit}, data={}) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'POST',
                url: `games/order`,
                model: 'order',
                data
            }).then(({err, data}) => {
                return data
            })
        },

        // // 追号计划
        // GET_PLAN({ state, dispatch, commit },data={}){
        //     return dispatch(`_${STORE}_AJAX`,{
        //         method : 'POST',
        //         url    : `game/getRemainingNumber`,
        //         model  : 'plan',
        //         data,
        //     }).then(({data})=>{
        //         return data
        //     })
        // },
        // 下单（追号计划）
        POST_FOLLOW({state, dispatch, commit}, data={}) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'POST',
                url: `games/follow`,
                model: 'order',
                data
            }).then(({data}) => {
                return data
            })
        },
        CANCEL_FOLLOW({state, dispatch, commit}, sequence) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'PUT',
                url: `games/follow/${sequence}`,
                model: 'order'
            })
        },
        ITEM_FOLLOW({state, dispatch, commit}, sequence) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `games/follow/${sequence}`
            })
        },

        // 撤单
        CALCEL_ORDER({state, dispatch, commit}, data={}) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'POST',
                url: `order/recall`,
                model: 'order',
                data
            }).then(({data}) => {
                return data
            })
        },

        GET_RANK({state, dispatch, commit}, data={}) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `games/auxiliary`,
                model: 'rank',
                params: data
            }).then(({data}) => {
                return data
            })
        },

        GET_RULES({state, dispatch, commit}, {code, type}) {
            if (code) {
                let game = state.init.games[code]
                let key = `${type}:${code}`
                commit('RULES_SET', {view: true, code})
                return state.rules.data[key] ?
                    Promise.resolve(state.rules.data[key]) :
                    dispatch(`_${STORE}_AJAX`, {
                        method: 'POST',
                        url: `help/ruleDescription`,
                        model: 'rules',
                        data: {
                            id: game.id,
                            code,
                            type: {formal: 1, credit: 2}[type]
                        }
                    }).then(({data}) => {
                        commit('RULES_DATA_SET', {code, type, content: data})
                        return data
                    })
            }
        },

        // 冷热遗漏
        GET_CREDIT_STATIS({state, dispatch, commit}, {type, id}) {
            commit('RUNNING_SET', {statis: {type, id, list: []}})
            let ac = type == 1 ? 'outcode' : 'misscode'
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `games/auxiliary`,
                model: 'statis',
                params: {
                    ac,
                    games_id: id
                }
            }).then(({data}) => {
                commit('RUNNING_SET', {statis: {type, id, list: data}})
                return data
            })
        },

        // 追号订单
        GET_FOLLOW_BY_ORDER({state, dispatch, commit}, sn) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'POST',
                url: `info/betsFollowsDetails`,
                data: {
                    sn
                }
            }).then(({data}) => {
                return data
            })
        },

        // 追号订单 - 详情
        GET_EXPECT_BY_ORDER({state, dispatch, commit}, oid) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'POST',
                url: `order/orderDetails`,
                data: {
                    oid
                }
            }).then(({data}) => {
                return data
            })
        },

        // 管理员 - 当前封盘状态
        STATES_TRADER({state, dispatch, commit}, data) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `trader/gtrader/status`
            })
        },

        // 管理员 - 立即封盘
        SEAL_TRADER({state, dispatch, commit}, data) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'PUT',
                url: `trader/gtrader/122`,
                data
            })
        },

        // 获取游戏历史开奖记录
        GET_HISTORY({state, dispatch, commit}, params) {
            return dispatch(`_${STORE}_AJAX`, {
                method: 'GET',
                url: `games/lottery`,
                params
            })
        }
    },
    mutations: {

        // 初始化游戏，继承远程数据
        RELOAD_GAMES(state, games) {
            let dir = state.init.games
            for (let game of games) {
                let code = game.code
                let obj = {
                    id: game.id, // 游戏 id
                    group: game.type, // 玩法种类 ID
                    belong: game.type_name, // 玩法种类
                    icon: game.logo_img, // 图标
                    enable: game.enable, // 是否启用
                    code,
                    model: game.model,
                    remote_data: game,
                    bg_color: game.bg_color,
                    gradient_color: game.gradient_color
                }
                // 123
                if (!dir[code]) {
                    obj.title = game.title
                    if (game.has_credit) {
                        obj.credit = {init: false}
                    }
                    if (game.has_official) {
                        obj.formal = {init: false}
                    }
                    dir[code] = obj
                }
                Object.assign(dir[code], obj)
            }
            Vue.set(state.init, 'game', dir)
            Vue.set(state.init, 'ready', true)
            return dir
        },

        // 初始化商品列表，继承远程数据
        RELOAD_GOODS(state, opt) {
            let code = opt.code
            let type = opt.type
            let target = state.init.games[code][type]
            let db = {
                length: 0
            }
            for (let L1 of opt.list) {
                if (L1.child) {
                    for (let L2 of L1.child) {
                        for (let L3 of L2.child) {
                            let id = L3.games_play_id || L3.id
                            db[id] = L3
                            db.length = db.length + 1
                        }
                    }
                }
            }
            target.db = db
            target.init = true
            target.list = opt.list
            Vue.set(state.init.games[code], type, target)
            return opt
        },

        // 弃用 - 装载游戏玩法列表
        RELOAD_GOODS_LIST(state, {code, type, group}) {
            let target = state.init.games[code][type]
            if (target.init) {
                for (let i=0;i<target.list.length;i++) {
                    if (target.list[i].id === group.id) {
                        state.init.games[code][type].list.splice(i, 1, group)
                        Vue.set(state.init.games[code][type], 'list', state.init.games[code][type].list)
                        break;
                    }
                }
            }
        },

        // 切换游戏
        CHANGE_GAMES(state, opt) {
            state.running = {}
            for (let key in opt) {
                Vue.set(state.running, key, opt[key])
            }
        },

        // 切换游戏分类
        CHANGE_CATEGORY(state, opt) {
            let type = state.running.type
            if (opt) {
                Vue.set(state.running, 'category', opt.title)
            } else if (state.running[type] && state.running[type].list && state.running[type].list[0]) {
                Vue.set(state.running, 'category', state.running[type].list[0].title)
            } else {
                Vue.set(state.running, 'category', '未知分类')
            }
        },

        // 游戏期数 保存
        CHANGE_EXPECT(state, opt) {
            for (let key in opt) {
                Vue.set(state.expect, key, opt[key])
            }
        },

        // 把下一期切换到当前期
        NEXT_EXPECT(state, opt) {
            Vue.set(state.expect, 'now', state.expect.next)
        },

        // 本地时间每秒递增
        EXPECT_COUNT(state, opt) {
            if (opt) {
                Vue.set(state.expect, 'sync', opt.sync)
                Vue.set(state.expect, 'sales', opt.sales)
            }
            if (state.expect.time) {
                let time  = state.expect.time + 1
                let close = state.expect.now.close ? state.expect.now.close - time : null
                let end   = state.expect.now.end ? state.expect.now.end - time : null
                if (!end) {
                    end = state.expect.next.start ? state.expect.next.start - time : null
                }
                Vue.set(state.expect, 'time', time)
                Vue.set(state.expect.count, 'close', close)
                Vue.set(state.expect.count, 'end', end)
            }
        },

        // 存储游戏实时数据
        RUNNING_SET(state, opt) {
            for (let key in opt) {
                Vue.set(state.running, key, opt[key])
            }
        },

        RULES_SET(state, opt) {
            for (let key in opt) {
                Vue.set(state.rules, key, opt[key])
            }
        },
        RULES_DATA_SET(state, {code, type, content}) {
            Vue.set(state.rules.data, `${type}:${code}`, content)
        }
    }
}, ajax)
