<style lang="scss">
    .GameCreditPrompt{
        background-color:#e5e5e5;
        font-size:.6rem; font-weight:bold; line-height:.8rem;
        .box {
            padding:.3rem 0;
            .prompt {

            }
            &:not(:first-child){
                .prompt {
                    border-left:1px solid #f2f2f2;
                }
            }
            &:not(:last-child){
                .prompt {
                    border-right:1px solid #bbb;
                }
            }
        }
    }
</style>
<template>
    <el-row v-if="value && value.length > 0" tag="div" class="GameCreditPrompt">
        <el-col v-for="(item,index) in value" v-if="interval[index] != 0" :key="item" tag="div" class="box" :span="interval && (interval[index] || interval[index] === 0) ? interval[index] : 24 / value.length">
            <div class="prompt">{{ item }}</div>
        </el-col>
    </el-row>
</template>

<script>
import Credit from '../../../../../mixin/credit.js'
export default {
    name: 'GameCreditPrompt',
    mixins: [Credit],
    data() {
        return {
            active: 0
        }
    },
    props: {
        value: {
            type: Array,
            default() {
                return ['号码', '赔率', '金额']
            }
        },
        interval: {
            type: Array,
            default() {
                return [7, 6, 11]
            }
        }
    },
    computed: {

    },
    methods: {

    }
}
</script>
