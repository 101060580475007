
<template>
    <div class="OperateNavTable">
        <div v-for="(pack,unit) in [['万千','万百','万十','万个'],['千百','千十','千个',''],['百十','百个','',''],['十个','','','']]" class="l-flex o-mb">
            <template v-for="(item,index) in pack">
                <div v-if="index" style="padding:0 .2rem;" />
                <div class="l-flex-1">
                    <div v-if="item">
                        <div class="row-title c-text-c">{{ item }}</div>
                        <group :client="client" :list="Table[[1,5,8,10][unit] + index]" :length="1" />
                    </div>
                </div>
            </template>
        </div>
        <BoardFast :client="client" :group="TableAll" :step="true" :default-step="0.1" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateSsc118',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group
    }
}
</script>
