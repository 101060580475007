<style lang="scss">
    .OperateLhc-6 {
        h6.title {
            padding:3px 5px; text-align:center; background-color:#f3655e; color:#fff;
        }
    }
</style>
<template>
    <div class="OperateLhc-6">
        <div class="l-flex">
            <div class="l-flex-4">
                <group :client="client" :list="Table[0]" :length="4" />
            </div>
            <div class="l-flex-1" />
        </div>
        <BoardFast :client="client" :group="[0]" :step="true" :default-step="0.01" />
        <div class="l-flex">
            <div class="l-flex-4">
                <group :client="client" :list="Table[1]" :length="4" />
            </div>
            <div class="l-flex-1" />
        </div>
        <BoardFast :client="client" :group="[1]" :step="true" :default-step="0.01" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateLhc6',
    mixins: [CommonMix],
    data() {
        return {

        }
    },
    props: {
        client: {}
    },
    computed: {

    },
    methods: {

    },
    components: {
        Group
    }
}
</script>
