<style lang="scss">
.Icon {
   vertical-align: middle;
   fill: currentColor;
   overflow: hidden;
}
</style>
<template>
    <svg v-if="name" class="Icon" aria-hidden="true" :style="iconStyle" @click="Click()">
        <use :xlink:href="iconName" />
    </svg>
</template>

<script>
export default {
    name: 'Icon',
    data() {
        return {

        }
    },
    props: {
        name: {
            type: String
        },
        size: {
            default: 1
        }
    },
    computed: {
        iconName() {
            if (this.name) {
                return `#icon-${this.name}`
            }
            return ''
        },
        iconStyle() {
            return `width:${this.size}rem; height:${this.size}rem;`
        }
    },
    methods: {
        Click() {
            this.$emit('click')
        }
    }
}
</script>