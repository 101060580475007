import Vue from 'vue'
import ajax from './ajax.js'
import config from './config.js'

import { MessageBox } from 'element-ui'

import Big from 'big.js'
Big.prototype.toNumber = function() {
    return Number(this)
}
window.Big = Big

import OptMix from '../mixin/opt.js'

import Methods from '~/methods';
import CRUD from '~/crud';

const opts = {
    mixins: [OptMix],
    data() {
        return {
            Params: {},
            Config: config
        }
    },
    computed: {
        Main() {
            if (this.model) {
                let store = this.model.split("/")[0]
                let model = this.model.split("/")[1]
                if (store && model) {
                    return this.$store.state[store][model]
                }
            }
        },
        Games() {
            return this.$store.getters['game/games']
        },
        GamesSelector() {
            let selector = []
            for (let name in this.Category) {
                let pack = this.Category[name]
                let obj = {
                    label: pack.title,
                    value: pack.id,
                    code: pack.code,
                    children: []
                }
                for (let item of pack.games) {
                    obj.children.push({
                        label: item.title,
                        value: item.id,
                        code: item.code
                    })
                }
                selector.push(obj)
            }
            return selector
        },
        Running() {
            //console.log('asdasd',this.$store.getters['game/running'])
            let games = this.$store.getters['game/running'];
            // let games_id = localStorage.getItem('games_id')
            // if (games_id != 'undefined') {
            //     games.id = parseInt(games_id);
            //     if (games.id === 34) {
            //         games.title = '澳门六合彩';
            //     }
            //     if (games.id === 35) {
            //         games.title = '台湾大乐透';
            //     }
            //     if (games.id === 36) {
            //         games.title = '新加坡多多彩';
            //     }
            //     if (games.id === 31) {
            //         games.title = '香港六合彩';
            //     }
            // }
            return games;
        },
        Category() {
            return this.$store.getters['game/category']
        },
        User() {
            return this.$store.state.base.user.info
        },
        Balance() {
            if (this.User && this.Opt) {
                if (this.Opt.isCash) {
                    return this.User.balance
                }
                if (this.Opt.isCredit && this.$store.state.game.group._init && this.Running.remote_data) {
                    let type = ''
                    for (let item of this.$store.state.game.group._list) {
                        if (this.Running.remote_data.type === item.id) {
                            type = item.code
                        }
                    }
                    if (this.User[`${type}_balance`] != undefined) {
                        return this.User[`${type}_balance`]
                    }
                }
            }
            return '-'
        },
        CreditInfo() {
            if (this.User && this.Opt.isCredit) {
                let obj = {
                    group: this.Running.belong,
                    side: ''
                }
                let type = 7
                if (this.Running.remote_data) {
                    type = this.Running.remote_data.type
                }
                if (this.$store.state.game.group._init) {
                    for (let item of this.$store.state.game.group._list) {
                        if (type === item.id) {
                            obj.type = item.code
                        }
                    }
                    obj.balance = this.User[`${obj.type}_balance`]
                    obj.ubalance = this.User[`${obj.type}_use_balance`]
                    if (this.User[`${obj.type}_has_bet`]) {
                        let list = []
                        for (let s of this.User[`${obj.type}_has_bet`].split(',')) {
                            list.push(`${s.toUpperCase()}盘`)
                        }
                        obj.side = list.join('|')
                    }
                }
                if (this.Running.expect && this.Running.expect.now && this.Running.expect.now.end) {
                    obj.expect = this.Running.expect.now.expect
                    obj.date = this.Time(this.Running.expect.now.end*1000, 'yyyy-MM-dd')
                }
                return obj
            }
            return {}
        }
    },
    methods: {

        Ajax: ajax,

        ...CRUD,
        ...Methods,
        
        Big(opt) {
            // 浮点计算： 加-add  减-sub  乘-mul  除-div  四舍五入-round(位数，模式)
            // return new Big(opt)
            let Log = this.Log
            try {
                return new Big(opt)
            } catch (err) {
                let big = function(num) {Log('constructor', num) ;return big;}
                big.add = function(num) {Log('add', num);return this;}
                big.sub = function(num) {Log('sub', num);return this;}
                big.mul = function(num) {Log('mul', num);return this;}
                big.div = function(num) {Log('div', num);return this;}
                big.round = function(num, num2) {Log('round', num, num2);return this;}
                big.toNumber = function(num) {return NaN}
                return big
            }
        },

        // 路由跳转
        Go(name=this.$route.name, params={}, query={}) {
            // console.log(name)
            // console.log(params)
            // console.log(query)
            localStorage.setItem('games_id', params.id);

            // if(params.id == 34){
            //     localStorage.setItem('games_title','澳门');
            // }else{
            //     localStorage.setItem('games_title','香港');
            // }
            this.$router.push({name, params, query})
            this.Cm('base/ROUTER_COUNT_ADD')

            if (name == 'index/pages/table') {
                window.location.reload();
            }

        },
        Rd(name=this.$route.name, params={}, query={}) {
            this.$router.replace({name, params, query})
        },
        Dp(name, data) {
            return this.$store.dispatch(name, data)
        },
        Cm(name, data) {
            return this.$store.commit(name, data)
        },
        // 自动拼合静态资源路径
        Img(url, suffix="") {
            let base = this.Config.base_img
            let baseArr = this.Config.base_img.split('/')
            let host = null
            let location = []
            while (baseArr.length > 0) {
                let str = baseArr.splice(0, 1)[0]
                if (str && str != 'http' && str != 'https') {
                    if (!host) {
                        host = str
                    } else {
                        location.push(str)
                    }
                }
            }
            if (url) {
                if (url.indexOf("://") >= 0) {
                    return url + suffix
                } else if (url.split('/')[0]==='project' || url.split('/')[0]==='upload' ) {
                    return `//${host}/${url}${suffix}`
                }
                if (url.indexOf('/static/images') >= 0) {
                    url = url.substring(14, url.length)
                }
                if (url.indexOf('static/images') >= 0) {
                    url = url.substring(3, url.length)
                }
                return this.Config.base_img + url + suffix
            }
            return ""
        },
        // 背景图片样式
        BackIMG(url='') {
            return `background:url('${this.Img(url)}') no-repeat center center;`
        },


        // 根据时间戳转换当前时间
        Time(time, format="yyyy-MM-dd hh:mm") {
            return this.Untimestamp(time, format)
        },
        // 获取当前时间戳
        Timestamp(date) {
            let time = date ? new Date(date) : new Date()
            return Date.parse(time) / 1000
        },


        Msg(message, type='warning') {
            return this.$root.Toast(message, type, 1500)
        },
        Suc(message, type='success') {
            if (!this.$message && this.MToast) {
                return this.MToast({message, iconClass: 'icon icon-roundcheckfill', duration: 1500, position: 'middle'});
            }
            return this.Notify('操作成功', message, type, 1500)
        },
        Err(message, title='操作失败', type='error') {
            if (!this.$message && this.MToast) {
                return this.MToast({message, iconClass: 'icon icon-roundclosefill', duration: 1500, position: 'middle'});
            }
            return this.Notify(title, message, type);
        },
        Toast(message, type='success', duration=1500, position='bottom') {
            //return this.$root.Toast(message,type,duration)
            let title = type == 'success' ? '操作成功' : '操作失败'
            if (this.$message) {
                let h = this.$createElement;
                return this.$message({
                    iconClass: '-',
                    duration,
                    center: true,
                    customClass: 'ModulCenter',
                    message: h('p', {
                        class: 'modul'
                    }, [
                        h('div', {
                            class: 'title'
                        }, title),
                        h('div', {
                            class: 'content',
                            style: 'margin-top:1rem;'
                        }, [
                            h('p', null, message)
                        ])
                    ])
                });
            } else if (this.MToast) {
                return this.MToast({message, type, duration, position});
            }
        },
        Notify(title, message, type) {
            return this.$root.Notify(title, message, type);
        },


        Token(token) {
            if (token) {
                this.Cache('token', token)
            }
            if (token===null) {
                this.Cache('token', null)
            }
            return this.Cache('token')
        },

        // 登录状态判断
        Login(callback) {
            // 如果没有 token 则直接跳转登录
            if (!this.Token()) {
                this.Go('login')
                return Promise.resolve(null);
            }
            // 向后端请求用户登录信息
            return this.$store.dispatch('base/BASE_USER').then(res => {
                let Event = this.$store.state.base.Event
                if (Event && res) {
                    Event.emit('login')
                }
                if (res) {
                    if (callback) {
                        // 进行回调
                        if (this.$route.params.from) {
                            this.$router.replace({name: this.$route.params.name})
                        } else {
                            this.$router.replace({path: '/'})
                        }
                    }
                }
                return res
            })
        },
        LoginCheck() {
            if (this.User) {
                return true
            }
            this.$router.replace({name: 'login', form: this.$route.fullPath})
            //this.Go('login')
            return false
        },

        // 退出登录
        Logout() {
            if (this.$confirm) {
                return this.$confirm('即将退出当前帐号, 是否继续?', '退出登录', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: this.Config.environment,
                    type: 'warning'
                }).then(() => {
                    let token = this.Cache('token')
                    return this.$store.dispatch('base/BASE_SIGNOUT', {token}).then(res => {
                        this.Cache('token', null)
                        this.Go('login')
                        window.location.reload()
                        return res
                    })
                }, err => {})
            } else if (this.MessageBox) {
                return this.MessageBox.confirm('是否退出当前账号？').then(() => {
                    let token = this.Cache('token')
                    return this.$store.dispatch('base/BASE_SIGNOUT', {token}).then(res => {
                        this.Cache('token', null)
                        this.Go('login')
                        window.location.reload()
                        return res
                    })
                }, err => {})
            }
        },

        Confirm(callback=new Function(), msg='您确定要操作选定的数据么？', title='操作数据',) {
            if (this.$confirm) {
                return this.$confirm(msg, title, {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'pj-alert-confirm',
                    cancelButtonClass: 'pj-alert-cancel',
                    customClass: this.Config.environment,
                    type: 'warning',
                    center: true
                }).then(() => {
                    callback()
                }).catch(() => {})
            } else if (this.MessageBox) {
                return this.MessageBox.confirm(msg).then(() => {
                    callback()
                }, err => {})
            }
        },
        DelConfirm(callback=new Function(), msg='您确定要删除当前选定的数据么？', title='删除数据',) {
            if (this.$confirm) {
                return this.$confirm(msg, title, {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'pj-alert-del',
                    cancelButtonClass: 'pj-alert-cancel',
                    customClass: this.Config.environment,
                    type: 'error',
                    center: true
                }).then(() => {
                    callback()
                }).catch(() => {})
            } else if (this.MessageBox) {
                return this.MessageBox.confirm(msg).then(() => {
                    callback()
                }, err => {})
            }
        },

        // 对象继承
        Extend(old, ...obj) {
            return Object.assign(old, ...obj)
        },
        // 拷贝一个对象
        Origin(obj) {
            try {
                return JSON.parse(JSON.stringify(obj))
            } catch (err) {
                return obj
            }
        },

        OnlineService() {
            window.open(`${this.Config.base}welcome/onlineService`)
        },
        CheckContextLength(str, max=24) {
            if (str && str.length > max) {
                return `${str.substring(0, max)}...`
            }
            return str
        },

        PriceStr(price) {
            if (price) {
                let arr = `${price}`.split('.')
                if (!arr[1]) {
                    arr[1] = '00'
                }
                while (arr[1].length < 2) {
                    arr[1]+= '0'
                }
                return [arr[0], arr[1]]
            }
            return ['0', '00']
        },

        PrintTable() {
            if (this.$refs['table']) {
                let mywindow = window.open('', 'PRINT', 'height=400,width=600');
                mywindow.document.write(`
                    <html>
                        <head>
                            <style>
                                html,body {margin:0; padding:0; font-size:9pt;}
                                table {
                                    width:100%; border:1px solid #000; border-collapse:collapse!important;
                                }
                                td {border:1px solid #000; font-size:9pt; padding:2pt 2pt;}
                                .el-table__body, .el-table__footer, .el-table__header {table-layout:fixed; border-collapse:separate;}
                                .PrintHide {display:none;}
                                .cell {padding:0 4px;}
                                .l-flex {display:flex;}
                                .l-flex-1 {flex:1;}
                                .l-flex-2 {flex:2;}
                                .l-flex-3 {flex:3;}
                                .l-flex-4 {flex:4;}
                            </style>
                        </head>
                        <body>${this.$refs['table'].$el ? this.$refs['table'].$el.innerHTML : this.$refs['table'].innerHTML}</body>
                    </html>`);
                mywindow.document.close();
                mywindow.focus();
                mywindow.print();
                mywindow.close();
                return true;
            }
        },

        Log(...obj) {
            if (process.env.NODE_ENV === 'development') {
                console.log('\n', ...obj, '\n')
            }
        },

        CalcuRow(key) {
            let total = 0
            try {
                for (let row of this.main._list) {
                    if (row[key] && parseFloat(row[key]) == row[key]) {
                        total = this.Big(total).add(row[key]).toNumber()
                    }
                }
                return this.Big(total).round(2, 0).toNumber()
            } catch (err) {
                return NaN
            }
            return total
        },

        // 定义转换函数
        NumberTransformInt(str) {
            return this.NumberTransform(str, true)
        },
        NumberTransform(tranvalue, isInt=false) {
            if (tranvalue == 0) {
                return '零'
            }
            if (typeof tranvalue === 'number') {
                tranvalue = tranvalue + ''
            }
            //拆分整数与小数
            let splits = (tranvalue) => {
                let value = new Array('', '');
                temp = tranvalue.split(".");
                for (let i=0;i<temp.length;i++) {
                    value[i] = temp[i];
                }
                return value;
            }
            let str = ""
            try {
                let i=1;
                let dw2 = new Array("", "万", "亿");//大单位
                let dw1 = new Array("十", "百", "千");//小单位
                let dw = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九");//整数部分用
                //以下是小写转换成大写显示在合计大写的文本框中
                //分离整数与小数
                let source = splits(tranvalue);
                let num = source[0];
                let dig = source[1];

                //转换整数部分
                let k1=0;//计小单位
                let k2=0;//计大单位
                let sum = 0;

                let len = source[0].length;//整数的长度
                for (i=1;i<=len;i++) {
                    var n = source[0].charAt(len-i);//取得某个位数上的数字
                    let bn = 0;
                    if (len-i-1>=0) {
                        bn = source[0].charAt(len-i-1);//取得某个位数前一位上的数字
                    }
                    sum = sum+Number(n);
                    if (sum!=0) {
                        str = dw[Number(n)].concat(str);//取得该数字对应的大写数字，并插入到str字符串的前面
                        if (n=='0')sum = 0;
                    }
                    if (len-i-1>=0) {//在数字范围内
                        if (k1!=3) {//加小单位
                            if (bn!=0) {
                                str = dw1[k1].concat(str);
                            }
                            k1++;
                        } else {//不加小单位，加大单位
                            k1=0;
                            var temp = str.charAt(0);
                            if (temp=="万" || temp=="亿")//若大单位前没有数字则舍去大单位
                                str = str.substr( 1, str.length-1);
                            str = dw2[k2].concat(str);
                            sum = 0;
                        }
                    }
                    if (k1==3)//小单位到千则大单位进一
                    { k2++;}
                }
                if (!isInt) {
                    //转换小数部分
                    let strdig="";
                    if (dig!="") {
                        var n = dig.charAt(0);
                        if (n!=0) {
                            strdig += dw[Number(n)]+"角";//加数字
                        }
                        var n = dig.charAt(1);
                        if (n!=0) {
                            strdig += dw[Number(n)]+"分";//加数字
                        }
                    }
                    str += "元" + strdig
                }
            } catch (e) {
                return tranvalue
            }
            return str
        },

        ResetCache(prefix='') {
            if (window && window.localStorage && typeof prefix == 'string') {
                for (let key in window.localStorage) {
                    console.log(key, !~['length', 'key', 'getItem', 'setItem', 'removeItem', 'clear', 'token'].indexOf(key), ~key.indexOf(prefix))
                    if (!~['length', 'key', 'getItem', 'setItem', 'removeItem', 'clear', 'token'].indexOf(key) && ~key.indexOf(prefix)) {
                        window.localStorage.removeItem(key)
                    }
                }
            }
        }
    }
}
Vue.mixin(opts)

export default opts