
export default {
    name: 'Game',
    data() {
        return {
            ready: false,
            store: 'game/init',
            timer: false,
            navLoading: false
        }
    },
    computed: {

    },
    watch: {
        // 路由变化，新路由会触发应用新游戏
        '$route.params.code': {
            handler(val) {
                this.ApplyGame(val)
            },
            deep: true
        },
        // 游戏封盘，请求下期数据
        'Running.expect.count.end'(val, old) {
            if (val===0 || old === 1) {
                this.Log('自动切换期数')
                this.Cm('game/NEXT_EXPECT')
                setTimeout(() => {
                    this.Log('服务器同步期数')
                    this.ExpectInit()
                }, 3000)
            }
        }
    },
    components: {

    },
    methods: {

        // 游戏初始化，加载远程数据
        // init(){
        //     this.ready = true
        //     this.ApplyGame()
        // },

        // 应用游戏（游戏被切换时，包含官方信用玩法切换）
        ApplyGame(code=this.$route.params.code, type=this.$route.params.type) {
            if (code && type) {
                // 查询是否拥有该游戏
                if (this.Games[code]) {
                    // 查询是否有对应的官方或信用玩法，如果没有则自动转换
                    // if(!this.Games[code][type]){
                    //     type = type == 'credit' ? 'formal' : 'credit'
                    // }
                    // this.Dp('socket/CHANGE_GAME',code) // 切换长链接侦听
                    this.Cm('game/CHANGE_GAMES', {type, ...this.Games[code]}) // 更变游戏
                    // 期数初始化
                    // if(this.Opt.isTeam){
                    //     return // 代理模式不需要期数初始化
                    // }
                    return this.ExpectInit().then(res => {
                        if (!this.Opt.isTeam) {
                            return this.NavInit(res) // 加载商品导航
                        }
                    })
                }
            }
        },
        // 加载游戏导航
        NavInit(obj) {
            console.log(obj)
            //if(obj && obj.now && obj.now.id){
            let type = this.Running.type
            return this.Dp('game/NAV_INIT', {
                game_id: this.Running.id,
                play_type: {formal: 1, credit: 2}[this.Running.type],
                game_type: this.Running.group,
                game_model: this.Running.model,
                code: this.Running.code,
                lottery_id: obj.now.id
            }).then(res => {
                if (res.length) {
                    this.NavChange(res[0]) // 切换到第一个游戏导航
                }
            })
            //}
        },
        // 切换游戏导航
        NavChange(item) {

            // 对比目标导航是否与旧的相同
            let oldNav = this.Origin(this.Running.nav.item)
            if (oldNav && item && oldNav.id === item.id) {
                return false
            }
            // 重复加载判定
            if (this.navLoading) {
                return false
            }
            this.$set(this, 'navLoading', true)
            // 尝试保存上一个盘，切换分类时候会保存对应的盘口
            let side = null
            let table = true// this.Opt.isCredit && this.Running.type === 'credit' // 只有信用游戏才有盘口
            if (this.Running.nav.side && this.Running.nav.side.title) {
                side = this.Running.nav.side.title
            }
            // console.log("NavChange mb change")
            this.Dp('game/NAV_CHANGE', {table, item}).then(res => {
                if (table) {
                    let group = this.Running.group
                    if (this.Opt.hasDir.typeById[group] && this.Opt.hasDir.typeById[group].length) {

                        let trySide = (side, arr=[]) => {
                            if (side) {
                                for (let pack of arr) {
                                    if (pack.title && pack.title[0] == side[0]) {
                                        this.NavTable(pack, item)
                                        return true
                                    }
                                }
                            } else {
                                this.$set(this, 'navLoading', false)
                            }
                            return false
                        }
                        if (!trySide(side, this.Running.nav.table)) {
                            side = this.Opt.hasDir.typeById[group][0]
                            trySide(side, this.Running.nav.table)
                        }
                    }
                } else {
                    this.Dp('game/GOODS_RELOAD', {id: item.id}).then(res => {
                        this.$set(this, 'navLoading', false)
                    })
                }
            })
        },

        // 切换盘口
        NavTable(item, nav) {
            this.Dp('game/NAV_TABLE', item)
            this.Dp('game/GOODS_RELOAD', {id: item.id, nav}).then(res => {
                this.$set(this, 'navLoading', false)
                this.$store.state.base.Event.emit('NavTable/GOODS_RELOAD')
            })
        },

        // 期数初始化
        ExpectInit(nav) {
            this.Cm('game/EXPECT_COUNT', {sync: false, sales: false})
            let startDate = new Date()
            // console.log('ExpectInit Running:', this.Running)
            return this.Dp('game/LIVE_GAME', {games_id: this.Running.id}).then(res => {
                let countDown = res.now.seal_time - res.serve_time
                let countDownH = parseInt(countDown/3600)
                let countDownM = parseInt((countDown - countDownH * 3600) / 60)
                let countDownS = parseInt(countDown - countDownH * 3600 - countDownM * 60)
                if (this.User.is_admin && this.Opt.isDev) {
                    console.info(`理论倒计时 ${countDownH}:${countDownM}:${countDownS}`, {
                        '发起时间': this.Time(startDate, 'hh:mm:ssSS'),
                        '响应时间': this.Time(new Date(), 'hh:mm:ssSS'),
                        '响应耗时': new Date() - startDate + 'ms',
                        '数据': {
                            '服务器时间': this.Time(res.serve_time, 'hh:mm:ss'),
                            '封盘时间': this.Time(res.now.seal_time, 'hh:mm:ss'),
                            '距离封盘': res.now.seal_time - res.serve_time,
                            '封盘倒计时': `${countDownH}:${countDownM}:${countDownS}`
                        },
                        '原始数据': res
                    })
                }
                let obj = this.ExpectLoad(res)
                if (nav && res.now.lottery_id) {
                    this.NavInit(obj)
                }
                return obj
            })
        },
        ExpectLoad(expect) {
            let obj = {
                sync: true,
                sales: true, //res.is_sales == 1 ? true : false,
                time: expect.serve_time,
                now: {
                    id: expect.now.lottery_id,
                    expect: expect.now.number,
                    start: expect.now.start_time,
                    close: expect.now.seal_time,
                    end: expect.now.end_time,
                    status: expect.now.status,
                    result: expect.now.code ? expect.now.code.split('+')[0] ? expect.now.code.split('+')[0].split(',') : [] : [],
                    special: expect.now.code ? expect.now.code.split('+')[1] ? expect.now.code.split('+')[1].split(',') : [] : [],
                    colors: expect.now.colors,
                    zodiac: expect.now.zodiac
                },
                next: {
                    id: expect.next.lottery_id,
                    expect: expect.next.number,
                    start: expect.next.start_time,
                    close: expect.next.seal_time,
                    end: expect.next.end_time
                },
                last: {
                    expect: expect.last.number ? expect.last.number : '-',
                    result: expect.last.code ? expect.last.code.split('+')[0] ? expect.last.code.split('+')[0].split(',') : [] : [],
                    special: expect.last.code ? expect.last.code.split('+')[1] ? expect.last.code.split('+')[1].split(',') : [] : [],
                    end: expect.last.end_time ? expect.last.end_time : 0,
                    colors: expect.last.colors,
                    zodiac: expect.last.zodiac
                }
            }
            this.Cm('game/CHANGE_EXPECT', obj)
            this.ExpectCount()
            return obj
        },
        ExpectCount() {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                if (this.Running.expect.sync) {
                    this.Cm('game/EXPECT_COUNT')
                }
            }, 1000)
        }
    },
    mounted() {
        this.init()
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.LoginCheck()
        })
    }
}