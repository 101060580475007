import dgx from '~/dgx_z/index.js';
import ajax from '@lottery.2.0/library/ajax.js';

const store = 'myTeam';
const STORE = store.toUpperCase();

export default dgx({
    store,
    state: {
        	output: {
        		title: '开奖历史',
        		_options: {
        			url: 'manage/lhc/lottery'
        		}
        	}
    },
    actions: {

    },
    mutations: {

    }
},
ajax,
);
