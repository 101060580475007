import dgx from '~/dgx_z/index.js';
import ajax from '@lottery.2.0/library/ajax.js';

const store = 'myAccount';
const STORE = store.toUpperCase();

export default dgx({
    store,
    state: {
	        overview: {
	        	title: '个人总览',
	            _options: {
	                url: 'user/browse'
	            }
	        },
	        safety: {
	        	title: '安全信息',
	            _options: {
	                url: 'user/safety'
	            }
	        },
	        bank: {
	        	title: '银行卡',
	            _options: {
	                url: 'banks'
	            }
	        },
	        colorlimit: {
	        	title: '彩种限额',
	            _options: {
	                url: 'climit'
	            }
	        },
	        gamesgroup: {
	        	title: '彩种限额',
	            _options: {
	                url: 'user/options'
	            }
	        },
	        question: {
	        	title: '密保问题',
	            _options: {
	                url: 'safety/question'
	            }
	        }
    },
    actions: {

    },
    mutations: {

    }
},
ajax
);
