import Vue from 'vue'
import {deepCopy, isObject, isArray} from '../../helper.js'

// 带有焦点属性
// 传参 ：query or [query,dataBase]
// query :
//  undefined or null 重置焦点
//  {key:val} 检索第一个匹配的字段
//  -1 0 x 查找指定索引（-1代表最后一个索引）
// 焦点变化 mutations 方法

export default function(options, ajax) {

    let store = options.store.toUpperCase()
    //let MODEL = model.toUpperCase()

    options.actions[`_${store}_AJAX`] = ({state, dispatch, commit}, config={}) => {
        // 检测是否重复，如果重复则取消之前相同的方法
        if (config.only) {
            //console.warn('dgx 请求重复，自动取消前一个请求。',config)
            commit(`_${store}_AJAX_CANCEL`, {
                model: config.model || 'common',
                only: config.only
            })
        }
        // 返回 ajax 方法
        return new Promise((resolve, reject) => {
            ajax({
                getCancel: (id, cancel) => {
                    commit(`_${store}_ADD`, {
                        base: config.model || 'common',
                        key: '_ajax',
                        value: {
                            id: id,
                            model: config.model || 'common',
                            only: config.only || null,
                            cancel: cancel,
                            method: config.method,
                            type: config.method === 'GET' ? 'loading' : 'editing'
                        }
                    })
                    commit(`_${store}_AJAX_UPDATE`)
                },
                ...config
            }).then( res => {
                let data = res.data.data
                if (data && data.page == 1 && data.list && data.list.length === 0) {
                    commit(`_${store}_UPDATE`, {
                        base: config.model || 'common',
                        key: '_empty',
                        value: true
                    })
                }
                commit(`_${store}_UPDATE`, {
                    base: config.model || 'common',
                    key: '_init',
                    value: true
                })
                commit(`_${store}_UPDATE`, {
                    base: config.model || 'common',
                    key: '_error',
                    value: false
                })
                dispatch(`_${store}_AJAX_FINISH`, {
                    id: res.config.id,
                    model: config.model || 'common'
                })
                resolve(res.data)
            }, err => {
                console.log(err)
                if (err && err.config && err.config.id) {
                    dispatch(`_${store}_AJAX_FINISH`, {
                        id: err.config.id,
                        model: config.model || 'common'
                    })
                }
                commit(`_${store}_UPDATE`, {
                    base: config.model || 'common',
                    key: '_error',
                    value: true
                })
                reject(err.data)
            })
        })
    }

    // Ajax 结束请求
    options.actions[`_${store}_AJAX_FINISH`] = ({state, dispatch, commit}, config={}) => {
        let options = Object.assign({
            id: 0
        }, isObject(config) ? config : {id: config})
        // 查询符合条件的 ajax 请求
        finish:
        for (let m of state._models) {
            for (let i=0; i<state[m]._ajax.length; i++) {
                if (state[m]._ajax[i].id == options.id) {
                    //if(state[m]._ajax[i].type == 'loading'){
                    commit(`_${store}_REMOVE`, {index: i, base: m, key: '_ajax'})
                    //}
                    break finish
                }
            }
        }
        commit(`_${store}_AJAX_UPDATE`)
    }

    // Ajax 取消请求
    options.mutations[`_${store}_AJAX_CANCEL`] = (state, config={}) => {
        let options = Object.assign({
            model: undefined,
            only: undefined,
            id: undefined
        }, config)

        // 计算需要查询的 models
        let models = options.model ? options.model : state._models
        if (typeof models != 'object') {
            models = [models]
        }
        //
        finish:
        for (let m of models) {
            //console.log(112,m,state)
            for (let i=0; i<state[m]._ajax.length; i++) {
                // 当存在 only 条件时且不满足 only 条件时候进行 break 操作
                if (options.only !== undefined && options.only != state[m]._ajax[i].only) {
                    break
                }
                // 当存在 id 条件时且不满足 id 条件时候进行 break 操作
                if (options.id !== undefined && options.id != state[m]._ajax[i].id) {
                    break
                }

                // 剩余为满足取消条件
                state[m]._ajax[i].cancel()
                state[m]._ajax.splice(i, 1)
                break finish
            }
        }
    }

    // Ajax 数据更新
    options.mutations[`_${store}_AJAX_UPDATE`] = (state) => {
        let models = state._models
        state._ajax = []
        for (let i=0;i<models.length;i++) {

            // 每个模块的请求队列长度
            let length = state[models[i]]._ajax.length

            // 每个模块的请求列表
            state._ajax.push(...state[models[i]]._ajax)

            // 每个模块是否在请求中
            let statis = {
                loading: 0,
                editing: 0
            }
            for (let item of state[models[i]]._ajax) {
                statis[item.type] ++
            }
            state[models[i]]._loading = statis.loading > 0
            state[models[i]]._editing = statis.editing > 0
            // state._loading = !!state[models[i]]._loading
            // state._editing = !!state[models[i]]._editing
        }
    }
}
