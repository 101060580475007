<style lang="scss">
    .ImageUpload{
        display:inline-block;
        .image {
            display:inline-block; padding:.2rem; border-radius:.2rem; border:1px dashed #ccc;
            cursor:pointer; position:relative; text-align:center;
            img {
                vertical-align:middle; max-width:100%; max-height:100%;
            }
            margin-left:.15rem;
            &:first-child {
                margin-left:0;
            }
        }
        input[type=file] {
            display:none;
        }
    }
</style>
<template>
    <div class="ImageUpload">
        <slot>
            <label v-for="(item,index) in list" v-if="list.length" class="image" :style="sizeStyle">
                <img :src="Img(item)">
                <input type="file" :accept="accepts" :multiple="false" @change="upload(index,$event)">
            </label>
            <label v-if="list.length < maxLength" class="image" :style="sizeStyle">
                <i class="el-icon-plus" />
                <input type="file" :accept="accepts" :multiple="false" @change="upload(null,$event)">
            </label>
            <!-- <el-button type="primary">上传图片{{ length }}</el-button> -->
        </slot>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'ImageUpload',
    data() {
        return {
            uploading: -1,
            success: 0,
            files: {}
        }
    },
    props: {
        value: {
            default: ''
        },
        type: {
            default: 'box'
        },
        valueType: {
            default: 'string'
        },
        accepts: {
            type: String,
            default: 'image/jpeg,image/jpg,image/png'
        },
        maxSize: {
            type: Number,
            default: 3 * 1024 * 1024
        },
        maxLength: {
            type: Number,
            default: 1
        },
        width: {
            default: '4.6rem'
        },
        height: {
            default: '4.6rem'
        },
        // path : {
        //     type : String,
        //     default : ''
        // },
        tokenUrl: {
            type: String,
            default: ''
        }
    },
    computed: {
        list() {
            if (this.valueType === 'string') {
                return this.value ? this.value.split(',') : []
            }
            if (this.valueType === 'array') {
                return this.value ? this.value : []
            }
        },
        sizeStyle() {
            return `width:${this.width};height:${this.height};line-height:${this.height};`
        }
    },
    watch: {
        // uploading(val){
        //     if(val==0){
        //         this.$emit('complete',this.queue.length,this.success,this.queue)
        //         this.$refs.box.files.value = null
        //         this.uploading = -1
        //         this.success = 0
        //         this.queue = []
        //     }
        // }
    },
    methods: {
        pick() {
            this.$refs.box.click()
        },
        upload(index='', e) {

            let file = e.target.files[0]
            e.target.outerHTML=e.target.outerHTML;
            if (this.maxSize && this.maxSize < file.size) {
                return this.$notify.error({title: '操作失败', message: '单个文件容量不得超过3M。'})
            }

            // 获取上传 token
            this.Ajax({
                url: this.tokenUrl,
                params: {
                    location: this.path
                }
            }).then(res => {

                if (res.data && !res.data.err) {
                    let {key, token} = res.data.data
                    let form = new FormData()
                    form.append('file', file)
                    form.append('key', key)
                    form.append('token', token)
                    axios.post('https://up-z2.qiniup.com/', form, {
                        // onUploadProgress : (event)=>{
                        //     let progress = parseFloat(event.loaded / event.total * 100)
                        //     this.$set(this.queue[i],'progress',progress)
                        //     this.$emit('progress',this.queue)
                        // }
                    }).then(res => {
                        if (res.status == 200) {
                            console.log(res)
                            let key = res.data.key
                            let arr = this.value ? this.valueType === 'string' ? this.value.split(',') : this.value : []
                            if (index || index==0) {
                                arr.splice(index, 1, key)
                            } else {
                                arr.push(key)
                            }
                            this.$emit('input', this.valueType === 'string' ? arr.join(',') : arr.value)
                        }
                    })
                } else {
                    this.Err('操作失败');
                }
            })
        }
    }
}
</script>