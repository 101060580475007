<style lang="scss">
    .Loading {
        &[full] {
            position:absolute; top:0; bottom:0; right:0; left:0;
        }
        .core {
            width:100%; flex:1;
        }
        .mint-spinner-snake {
            margin:0 auto;
        }
        .more {
            font-size:12.5px;
        }
    }
</style>
<template>
    <div class="Loading">
        <div class="core o-ptb-l">
            <mt-spinner v-if="Main._loading" :color="color" />
            <div v-else class="more c-text-c" :style="'color:' + more_color">
                <div v-if="Main._empty" class="o-pt o-pb-l">
                    <i class="icon icon-vipcard" style="font-size:42px; color:#ddd;" />
                    <p style="margin-top:2px;">暂无数据</p>
                </div>
                <p v-else-if="!main._more">{{ more_text }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loading',
    data() {
        return {

        }
    },
    props: {
        main: {},
        loading: {
            default: false
        },
        color: {
            type: String,
            default: '#4477ad'
        },
        more: {
            default: true
        },
        empty: {
            default: false
        },
        more_text: {
            type: String,
            default: '没有更多了'
        },
        more_color: {
            default: '#c5c5c5;'
        }
    },
    computed: {
        Main() {
            if (this.main) {
                return {
                    ...this.main
                }
            }
            return {
                _loading: this.loading,
                _empty: this.empty,
                _more: this.more
            }
        },
        More() {
            if (this.main) {
                return this.main._more
            } else {
                return this.more
            }
        }
    },
    methods: {

    }
}
</script>