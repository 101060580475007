<style lang="scss">
.OpeningMaticinfoMudul {

}
</style>
<template>
    <el-dialog class="OpeningMaticinfoMudul" :title="title" :visible.sync="view" size="" top="10vh" :modal-append-to-body="false">
        <div class="c-line-12">
            <h5 class="c-color-t">※尊敬的各级代理※</h5>
            <p>当您选择手动和自动走飞时就会默认以下说明：</p>
            <p>1. 特码走飞赔率都是以特码A为主，手动或自动走飞的完整性取决于双向网络品质，当您发现线路不稳定时，请及时向上级反应或改用手动走飞，以免照造成不必要的争议，对于走飞不成功的公司不承担任何损失。或者代理可以利用直属会员不占成方式补货。</p>
            <p>2. 注意事项：自动走飞设定值可以随时更改，如当前开盘后修改自动走飞值，自动走飞要有新注单生成系统将会累加自动走飞，如没有新注单生成系统自动走飞无法完成。</p>
            <p>备注：要保证自动走飞的完整性，请各级代理最好能在开盘前设定好自动走飞功能</p>
        </div>
        <div class="o-pt c-text-r">
            <Button @click="view = false">确认</Button>
        </div>
    </el-dialog>
</template>

<script>
import StoreMix from '@lottery.admin.2.0/mixin/store.modul.js'
export default {
    name: 'OpeningAutomaticMudul',
    mixins: [StoreMix],
    data() {
        return {

        }
    },
    computed: {

    },
    methods: {
        init() {

        }
    },
    mounted() {
        // this.init()
    }
}
</script>
