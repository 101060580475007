<style lang="scss">
    .OperateLhc-integration {

    }
</style>
<template>
    <div class="OperateLhc-integration">
        <group :client="client" :list="Table[1]" :length="5" show-total="end" :integration="1" />
        <p class="c-text-c o-ptb">注意：特整合包含，特单、特双、特大、特小、合单、合双、合大、合小、尾大、尾小、合尾大、合尾小、红波、蓝波、绿波、大单、大双、小单、小双、家禽、野兽、特半波、特肖、合肖</p>
        <BoardFast :client="client" :group="[1]" type="lhc" :step="true" transmit="integration" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateLhcIntegration',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group
    }
}
</script>
