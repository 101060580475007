<style lang="scss">
    @import "../style/config.scss";
    .Avatar {
        display:inline-block; border-radius:999px; overflow:hidden;
        img {
            width:100%; height:100%; border-radius:999px; overflow:hidden;
        }
    }
</style>
<template>
    <div class="Avatar" :style="style">
        <img v-if="src" :src="Img(src)"></div>
    </div>
</template>

<script>
export default {
    name: 'Avatar',
    data() {
        return {
            next: null,
            page: 1
        }
    },
    props: {
        src: {},
        size: {
            default: '2.4'
        }
    },
    computed: {
        style() {
            return `width:${this.size}rem; height:${this.size}rem;`
        }
    },
    methods: {

    }
}
</script>