<style lang="scss">
    .OperateDpc-11 {
        .OperateGroup.mini table.table .row .ball {
            width:1.4rem;
        }
    }
</style>
<template>
    <div class="OperateNavTable OperateDpc-11">
        <!--         <el-radio-group class="o-mb" v-model="client.navMap['2_476b38e90e1a4d8e']" size="small">
            <el-radio-button v-for="(item,index) in [0,1,2,3,4,5,6,7,8,9]" :key="item" :label="item">{{ item }}</el-radio-button>
        </el-radio-group>
        <Group :client="client" :list="Table[groupActive]" :length="5"></Group>
        <BoardFast :client="client" :group="[groupActive]" :step="true" :defaultStep="0.1"></BoardFast> -->
        <BoardFast :client="client" :group="[1]" :step="true" :default-step="0.1" />
        <group :client="client" :list="Table[1]" :length="5" />
        <!-- <BoardFast :client="client" :group="[1]" :step="true" :defaultStep="0.1"></BoardFast> -->
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group.vue'
export default {
    name: 'OperateDpc11',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    computed: {
        // groupActive(){
        //     return this.client.navMap['2_4c3168d11848053d']
        // },
    },
    watch: {
        // 'client.navMap.2_4c3168d11848053d'(val,oldval){
        //     this.$parent.LoadPlay(undefined,this.client.nav.active)
        // },
    },
    components: {
        Group
    }
}
</script>
